import { BLoCBase } from 'types/BLoCBase';
import * as hubspotService from 'services/hubspot';
import { getUserStore } from './user.store';
import { combineLatest, first, switchMap } from 'rxjs';
import TagManager from 'react-gtm-module';
import { getCustomerStore } from './customer.store';
import { Segment } from 'services/segment';
import { getBillingPlanStore } from './billingPlan.store';

export enum GtmEvent {
  activityPageFilters = 'activity-page-filters',
  activityPageSort = 'activity-page-sort',
  activityPageClickEllipsis = 'activity-page-click-ellipsis',
  activityPageClickRename = 'activity-page-click-rename',
  activityPageClickRenameButton = 'activity-page-click-rename-button',
  activityPageClickDelete = 'activity-page-click-delete',
  activityPageClickDeleteButton = 'activity-page-click-delete-button',
  activityPageClickViewLink = 'activity-page-click-view-link',
  activityPageClickPagination = 'activity-page-click-pagination',
  newActivityPageClickCreateButton = 'new-activity-page-click-create-button',
  newActivityPageFromExploreToExplore = 'new-activity-page-from-explore-to-explore',
  newActivityPageFromExploreToQuery = 'new-activity-page-from-explore-to-query',
  taskPageClickCheckButton = 'task-page-click-check-button',
  taskPageClickApplyFiltersButton = 'task-page-click-apply-filters-button',
  taskPageClickSliderValue = 'task-page-click-slider-value',
  taskCompletedPageClickCopyButton = 'task-completed-page-click-copy-button',
  taskCompletedPageClickDetailsButton = 'task-completed-page-click-details-button',
  taskCompletedPageSearchCommentsValue = 'task-completed-page-search-comments-value',
  taskCompletedPageCommentsDateSort = 'task-completed-page-comments-date-sort',
  taskCompletedPageCommentsSentimentFilter = 'task-completed-page-comments-sentiment-filter',
  taskCompletedPageClickThemePaginationPage = 'task-completed-page-click-theme-pagination-page',
  taskCompletedPageSearchThemeValue = 'task-completed-page-search-theme-value',
  taskCompletedPageClickSubThemePaginationPage = 'task-completed-page-click-sub-theme-pagination-page',
  taskCompletedPageSearchSubThemeValue = 'task-completed-page-search-sub-theme-value',
  taskCompletedPageClickThemeRow = 'task-completed-page-click-theme-row',
  taskCompletedPageClickSubThemeRow = 'task-completed-page-click-sub-theme-row',
  taskCompletedPageClickThemeCountDropdown = 'task-completed-page-click-theme-count-dropdown',
  taskCompletedPageClickSubThemeCountDropdown = 'task-completed-page-click-sub-theme-count-dropdown',
  explorePageClickThemeCountDropdown = 'explore-page-click-theme-count-dropdown',
  explorePageClickSubThemeCountDropdown = 'explore-page-click-sub-theme-count-dropdown',
  explorePageClickThemeRow = 'explore-page-click-theme-row',
  explorePageClickSubThemeRow = 'explore-page-click-sub-theme-row',
  explorePageClickSubThemePaginationPage = 'explore-page-click-sub-theme-pagination-page',
  explorePageSearchSubThemeValue = 'explore-page-search-sub-theme-value',
  explorePageSearchThemeValue = 'explore-page-search-theme-value',
  explorePageClickThemePaginationPage = 'explore-page-click-theme-pagination-page',
  explorePageSearchCommentsValue = 'explore-page-search-comments-value',
  explorePageClickApplyFiltersButton = 'explore-page-click-apply-filters-button',
  explorePageCommentsSentimentFilter = 'explore-page-comments-sentiment-filter',
  explorePageCommentsDateSort = 'explore-page-comments-date-sort',
  projectsPageClickManageFiltersGlobalButton = 'projects-page-click-manage-filters-global-button',
  projectsPageClickManageFiltersProjectButton = 'projects-page-click-manage-filters-project-button',
  summaryDetailsPageFilters = 'summary-details-page-filters',
  summaryDetailsPageClickHighlightActionButton = 'summary-details-page-click-highlight-action-button',
  summaryDetailsPageClickCopyButton = 'summary-details-page-click-copy-button',
  storyEditPageWidgetsData = 'story-edit-page-widgets-data',
  storyEditPageClickDeletePageModalButton = 'story-edit-page-click-delete-page-modal-button',
  storyEditPageClickDeleteWidgetModalButton = 'story-edit-page-click-delete-widget-modal-button',
  storyViewPageClickPresentButton = 'story-view-page-click-present-button',
  storyEditPageInvalidPositionWidgets = 'story-edit-page-invalid-position-widgets',
  storyPagesChartWidgetClickDotsItemButton = 'story-pages-chart-widget-click-dots-item-button',
  storyPagesWidgetClickPaletteButton = 'story-pages-widget-click-palette-button',
  storyPagesWidgetClickImportProjectOption = 'story-pages-widget-click-import-project-option',
  storyPagesWidgetClickSurveyProjectOption = 'story-pages-widget-click-survey-project-option',
  storyPagesWidgetClickAugmentedProjectOption = 'story-pages-widget-click-augmented-project-option',
  chatClickTopbarButton = 'chat-click-topbar-button',
  onboardingUserRegistered = 'onboarding-user-registered',
  onboradingVerifyPageClickResendButton = 'onborading-verify-page-click-resend-button',
  onboardingUserVerified = 'onboarding-user-verified',
  onboardingUserCompleted = 'onboarding-user-completed',
  onboardingUserInviteExpired = 'onboarding-user-invite-expired',
}

type GTMEvent = {
  event?: string;
  filters?: string;
  form?: string;
  sort?: string;
  path?: string;
  customerId?: string;
  customerName?: string;
  userId?: string;
  perPageNumber?: number;
  pages?: number;
  pageNumber?: number;
  activityType?: string;
  activityId?: string | number | null;
  value?: string | number;
  feedback?: string;
  total?: number;
  projectId?: string | null;
  projectType?: string;
  data?: string;
};

const tagManagerArgs = {
  gtmId: import.meta.env.REACT_APP_GTM_ID!,
};

class AnalyticsStore extends BLoCBase<{ enabled: boolean }> {
  private readonly $enabled = this.$getState('enabled');

  private pageViewTrack = () => {
    this.addSub(
      getUserStore().$onboardingStatus.subscribe((onboardingStatus) => {
        const trackingEnabled =
          onboardingStatus === 'email-verified' ||
          ['onboarded', 'profile-complete'].includes(onboardingStatus || '');
        if (trackingEnabled) {
          this.pageView();
          this.setState('enabled', true);
        }
      }),
      'page-event-tracker'
    );
  };

  constructor() {
    super({ enabled: false });
    if (this.currentState('enabled')) return;
    setTimeout(() => {
      this.pageViewTrack();
    });
    window.addEventListener('locationchange', (e) => {
      this.pageViewTrack();
      return e;
    });
    setTimeout(() => {
      import.meta.env.PROD &&
        TagManager.initialize({
          ...tagManagerArgs,
          dataLayer: {
            path: window.location.pathname,
          },
        });
      this.addSub(
        this.$enabled
          .pipe(
            first((enabled) => !!enabled),
            switchMap(() =>
              combineLatest([
                getUserStore().$user.pipe(first((user) => !!user)),
                getCustomerStore().$customer.pipe(first((customer) => !!customer)),
                getBillingPlanStore().$currentPlan.pipe(first()),
              ])
            )
          )
          .subscribe(([user, customer, plan]) => {
            if (!user?.isYabbleOwner) {
              hubspotService.initAndIdentify({
                email: user?.email,
                id: user?.id,
              });
              Segment.init();
              user &&
                window.analytics.identify(user.id, {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                  billingStatus: customer?.billingStatus,
                  created_at: user.createdAt
                    ? Math.floor(new Date(user.createdAt).getTime() / 1000)
                    : undefined,
                  company: {
                    id: customer?.id,
                    name: customer?.name,
                    country: customer?.countryName,
                    plan: plan?.name,
                    planId: plan?.id,
                    tier: plan?.metadata?.planTier,
                    createdAt: customer?.createdAt,
                  },
                } as any);

              setTimeout(() => {
                (window as any).Intercom?.('boot', {
                  name: `${user?.firstName} ${user?.lastName}`,
                  email: user?.email,
                  created_at: user?.createdAt
                    ? Math.floor(new Date(user.createdAt).getTime() / 1000)
                    : undefined,
                  user_id: user?.id,
                });
              }, 3000);
            }
            this.pageView();
          }),
        'initialBoot'
      );
    });
  }

  public sendEvent = (eventName: string, meta: Record<string, unknown> = {}) => {
    if (!this.currentState('enabled')) return;

    // Format {eventName} to send with the {meta} to analytics providers
    window.analytics.track(eventName, meta);
  };

  public pageView = (pathname?: string) => {
    if (!this.currentState('enabled')) return;

    window?.analytics?.page && window.analytics.page();
    // Track pageView with the different analytics providers
    hubspotService.pageView(pathname);
  };

  public sendGTMEvent = (event: GTMEvent) => {
    this.addSub(
      combineLatest([
        getUserStore().$user.pipe(first()),
        getCustomerStore().$customer.pipe(first()),
      ]).subscribe(([user, customer]) => {
        const eventTosend: GTMEvent = {
          path: window.location.pathname,
          ...(customer?.id && { customerId: customer?.id }),
          ...(customer?.name && { customerName: customer?.name }),
          ...(user?.id && { userId: user?.id as any }),
          ...event,
        };
        TagManager.dataLayer({
          dataLayer: eventTosend,
        });
      })
    );
  };
}

const store: Readonly<AnalyticsStore> = Object.freeze(new AnalyticsStore());
export const getAnalyticsStore = () => {
  return store;
};
