import { useClassNames } from 'hooks/useClassNames';
import React, { useMemo } from 'react';
import { LoaderStyle as S } from './Loader.style';

let firstOffset = 0;

export const Loader: React.FC<{
  size?: 'regular' | 'small' | 'medium' | 'large';
  inverted?: boolean;
  className?: string;
}> = ({ size, inverted, className }) => {
  const classNames = useClassNames(
    {
      inverted: inverted,
    },
    className
  );

  const loader = useMemo(() => {
    if (!firstOffset) {
      firstOffset = new Date().getTime();
    }
    return (
      <S.Container
        $delay={-((new Date().getTime() - firstOffset) % 1800)}
        className={`loader-main ${size ?? 'regular'} ${classNames}`}
      >
        <S.LoaderSvg />
      </S.Container>
    );
  }, [size, classNames]);
  return <>{loader}</>;
};
