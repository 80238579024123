import React, { useMemo } from 'react';
import { useMapStepBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/components/ImportModalCsvContent/components/MapStep/MapStep.bloc';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { ColumnTypeDropdownItemDataStyle as S } from './ColumnTypeDropdownItemData.style';

export const ColumnTypeDropdownItemData: React.FC<{
  column: ImportModalCsvTypes.IngestionColumn;
  name: string;
}> = ({ column, name }) => {
  const { selectIcon } = useMapStepBLoC();
  const getIcon = useMemo(() => {
    return selectIcon(column.id);
  }, [column, selectIcon]);
  return (
    <S.Row>
      <S.IconContainer>{getIcon}</S.IconContainer>
      {name}
    </S.Row>
  );
};
