import React from 'react';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import { PInputNumberStyle as S } from './PInputNumber.style';
import { useClassNames } from 'hooks/useClassNames';

export type PInputNumberProps = Omit<InputNumberProps, 'size'> & {
  invalid?: boolean | null;
  size?: 'small' | 'large' | 'default';
};

export const PInputNumber = React.forwardRef<InputNumber, PInputNumberProps>(
  ({ invalid, size, ...props }, ref) => {
    const inputClasses = useClassNames(
      {
        'p-invalid': invalid,
        'p-inputnumber-sm': size === 'small',
        'p-inputnumber-lg': size === 'large',
      },
      props.className
    );

    return <S.InputNumber {...props} className={inputClasses} id={props.id ?? props.name} ref={ref} />;
  }
);
