import React from 'react';
import { UploadStepErrorStyle as S } from './UploadStepError.style';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { PButton } from 'components/prime/PButton/PButton';
import { Icon } from 'components/Icon/Icon';
import { LinkText } from 'components/LinkText/LinkText';
import { useUploadStepBLoC } from '../../UploadStep.bloc';
import { useObservableState } from 'observable-hooks';

export const UploadStepError: React.FC = () => {
  const { $showError, closeError } = useUploadStepBLoC();
  const showError = useObservableState($showError);
  return showError ? (
    <S.Container>
      <VStack className="h-full" align="center" justify="center">
        <S.Content>
          <VStack gap="gap6" align="center">
            <Icon icon="alertOctagonSolid" color="rose500" size={64} />
            <VStack gap="gap4" align="center">
              <Text size="fontSizeXL" weight="medium" color="neutral900" lineHeight="lineHeightMD">
                Import failed
              </Text>
              <S.Description>We found errors in your file. Review your file and try again</S.Description>
            </VStack>
            <VStack className="w-full" gap="gap4" fit="space">
              <PButton className="w-full" severity="soft" label="Go to error summary" onClick={closeError} />
            </VStack>
            <S.Text>
              <LinkText
                href="https://yabblezone.net/contact"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Yabble
              </LinkText>{' '}
              for support
            </S.Text>
          </VStack>
        </S.Content>
      </VStack>
    </S.Container>
  ) : null;
};
