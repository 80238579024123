import React, { useMemo } from 'react';
import { Message, MessageProps } from 'primereact/message';
import { PMessageStyle as S } from './PMessage.style';
import { Icon } from 'components/Icon/Icon';

export const PMessage = React.forwardRef<Message, MessageProps>((props, ref) => {
  const icon = useMemo(() => {
    switch (props.severity) {
      case 'error':
        return <Icon icon="alertOctagonSolid" size={24} color="rose600" />;
      case 'warn':
        return <S.WarnIcon />;
      case 'success':
        return <S.CheckActiveIcon />;
      default:
        return <S.InfoIcon />;
    }
  }, [props.severity]);
  return (
    <S.Message
      {...props}
      content={
        <>
          <S.IconWrapper>{icon}</S.IconWrapper>
          {props.content || props.text}
        </>
      }
      ref={ref}
    />
  );
});
