import { ImportModalContent } from 'components/modals/ImportModal/components/ImportModalContent/ImportModalContent';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalLoading } from 'components/modals/ImportModal/components/ImportModalLoading/ImportModalLoading';
import { ImportModalMainSection } from 'components/modals/ImportModal/components/ImportModalMainSection/ImportModalMainSection';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { MapStepColumns } from '../MapStepColumns/MapStepColumns';
import { LinkText } from 'components/LinkText/LinkText';

export const MapStepContent: React.FC = () => {
  const { $loading } = useMapStepBLoC();
  const { isEmptyProject } = useImportModalCsvBLoC();
  const loading = useObservableState($loading, false);
  return (
    <ImportModalContent isLoading={loading} loadingComponent={<ImportModalLoading />}>
      <ImportModalMainSection
        title="Map columns"
        subtitle={
          isEmptyProject ? (
            <>
              Review the following columns to confirm how you would like to proceed with your upload.{' '}
              <LinkText
                href="https://intercom.help/yabblezone/en/articles/8030508-preparing-your-short-form-data-for-import"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out our tips
              </LinkText>{' '}
              for getting the best output with our data.
            </>
          ) : (
            'To proceed with your upload, review any columns that require attention. Then, click "Next" to continue.'
          )
        }
      />
      <MapStepColumns />
    </ImportModalContent>
  );
};
