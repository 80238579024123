import styled from 'styled-components';
import { colours } from 'styles/styles';

export const TopBarWidgetStyle = {
  Container: styled.div`
    position: relative;
    padding-right: 24px;
    height: 56px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    z-index: 10;
    border-bottom: 1px solid ${colours.neutralGrey02};
    margin-bottom: -1px;
    display: flex;
    align-items: center;
    background-color: white;

    justify-content: space-between;
  `,
  Column: styled.div`
    &:not(:last-child) {
      flex: 1 1 auto;
      overflow: hidden;
    }

    &:last-child {
      flex-basis: fit-content;
      background-color: white;
      z-index: 1;
      min-width: fit-content;
    }

    & > div {
      width: 100%;
    }
  `,
};
