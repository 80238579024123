import React from 'react';
import { DetailsPanelProjects } from './components/DetailsPanelProjects/DetailsPanelProjects';
import { DetailsPanelFooter } from './components/DetailsPanelFooter/DetailsPanelFooter';
import { DetailsPanelHeader } from './components/DetailsPanelHeader/DetailsPanelHeader';
import { Box } from 'components/layouts/primitives/Box/Box';
import { DetailsPanelPersonas } from './components/DetailsPanelPersonas/DetailsPanelPersonas';
import { usePersonaLibraryModalBLoC } from '../../PersonaLibraryModal.bloc';
import { useObservableState } from 'observable-hooks';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { DetailsPanelLoadingState } from './components/DetailsPanelLoadingState/DetailsPanelLoadingState';
import { DetailsPanelEmptyState } from './components/DetailsPanelEmptyState/DetailsPanelEmptyState';
import { Text } from 'components/Text/Text';

export const DetailsPanel: React.FC = () => {
  const { $selectedSection, $groups, $selectedGroup, $projects } = usePersonaLibraryModalBLoC();
  const selectedSection = useObservableState($selectedSection);
  const groups = useObservableState($groups);
  const selectedGroup = useObservableState($selectedGroup);
  const projects = useObservableState($projects);

  if (!groups || !projects) return <DetailsPanelLoadingState />;

  if (!groups.length) return <DetailsPanelEmptyState />;

  if (!selectedGroup)
    return (
      <VStack stretch align="center" justify="center">
        <Text color="neutral400" size="fontSizeSM" weight="medium" lineHeight="lineHeightMD">
          Select a persona group to see persona details
        </Text>
      </VStack>
    );

  return (
    <VStack stretch splitAfterIdx={1}>
      <DetailsPanelHeader />
      <Box padding={['paddingNone', 'padding2XL']}>
        {selectedSection === 'projects' ? <DetailsPanelProjects /> : <DetailsPanelPersonas />}
      </Box>
      <DetailsPanelFooter />
    </VStack>
  );
};
