import styled, { css } from 'styled-components';
import { Calendar } from 'primereact/calendar';
import { boxShadows, colourPalette, colours, fontSize, gap, lineHeight } from 'styles/styles';
import ChevronIcon from 'assets/svg/chevron-down.svg';
import { inputTextBaseStyles } from '../PInputText/PInputText.style';

export const PCalendarGlobalStyle = css`
  .p-datepicker.p-component {
    padding: 16px;
    width: 340px;
    box-shadow: 0 0px 4px -5px rgb(0 0 0 / 8%), 0 8px 10px 1px rgb(0 0 0 / 2%), 0 1px 6px 2px rgb(0 0 0 / 10%);
    .p-datepicker-calendar {
      margin: 0;
      th {
        padding: 0;
        padding-bottom: 4px;
        span {
          font-size: ${fontSize.fontSizeSM};
          line-height: ${lineHeight.lineHeightMD};
          color: ${colours.neutralCharcoal};
          font-weight: 500;
          height: 36px !important;
          width: 36px !important;
        }
      }
      td {
        padding: 0;
        padding-bottom: 4px;
        span {
          font-size: ${fontSize.fontSizeSM};
          line-height: ${lineHeight.lineHeightMD};
          color: ${colours.neutralLightCharcoal};
          border-color: transparent;
          box-shadow: none;
          height: 36px !important;
          width: 36px !important;
          font-weight: 400;
          &:hover {
            background: ${colours.neutralGrey02} !important;
          }
        }
        span.p-highlight {
          background-color: ${colours.primaryBlue};
          &:hover {
            background: ${colours.primaryBlue} !important;
          }
          color: white;
        }
        span.p-disabled {
          color: ${colours.neutralGrey05} !important;
        }
      }
    }
    .p-datepicker-header {
      padding: 0 0 16px 0;
      margin-top: 4px;
      & > .p-datepicker-prev {
        order: 2;
        border-radius: 4px;
        color: ${colours.neutralLightCharcoal};
        &:hover {
          background-color: ${colours.neutralGrey02};
        }
      }
      & > .p-datepicker-title {
        order: 1;
        margin: auto;
        & > select {
          height: 36px;
          border-radius: 4px;
          background-color: ${colours.neutralWhite};
          border: 1px solid ${colours.neutralGrey04};
          color: ${colours.neutralLightCharcoal} !important;
          &:hover {
            border: 1px solid ${colours.neutralGrey06};
          }
          appearance: none;
          background-image: url(${ChevronIcon});
          background-position: calc(100% - 10px) calc(1em - 2.5px), calc(100% - 15px) calc(1em - 2.5px),
            calc(100% - 2.5em) calc(0.5em - 2.5px);
          background-size: 12px 12px, 12px 12px, 1px 1.5em;
          background-repeat: no-repeat;
          &:focus {
            box-shadow: ${boxShadows.focused};
            border-color: ${colours.primaryBlue};
          }
        }
      }
      & > .p-datepicker-next {
        order: 3;
        border-radius: 4px;
        color: ${colours.neutralLightCharcoal};
        &:hover {
          background-color: ${colours.neutralGrey02};
        }
      }
    }
  }
`;

export const PCalendarStyle = {
  CalendarContainer: styled.div`
    width: 100%;
    position: relative;
    .input-calendar-icon-wrapper {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      color: ${colourPalette.neutral400};
    }
    &.has-value > .input-calendar-icon-wrapper {
      color: ${colourPalette.neutral600};
    }
    &.is-disabled > .input-calendar-icon-wrapper {
      color: ${colourPalette.neutral300};
    }
    &:focus-within > .input-calendar-icon-wrapper {
      color: ${colourPalette.neutral600};
    }
  `,
  Calendar: styled(Calendar)`
    width: 100%;
    .p-inputtext {
      ${inputTextBaseStyles}
      padding-right: ${gap.gap6};
    }
  `,
};
