import React from 'react';
import { FiltersDetailsContentSkeletonsStyle as S } from './FiltersDetailsContentSkeletons.style';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';

export const FiltersDetailsContentSkeletons: React.FC = () => {
  return (
    <S.Container>
      <PSkeleton height="20px" width="20px" />
      <S.Column>
        <S.Title>
          <PSkeleton width="100px" height="18px" />
        </S.Title>
        <PSkeleton width="100%" height="16px" />
      </S.Column>
    </S.Container>
  );
};
