import { MainModuleTypes } from 'modules/MainModule/MainModule.types';

export const auDataTemplatePersonaMapper = (
  personas?: MainModuleTypes.AugmentedDataPersonaApiResp[] | null
): MainModuleTypes.AugmentedDataPersona[] => {
  if (!personas) return [];
  return personas.map((p) => {
    return {
      id: p.id,
      name: p.name,
      gender: p.gender,
      ageRange: p.ageRange,
      avatarUrl: p.avatarUrl,
      location: p.location,
      distributionWeight: p.projectSpecific.distributionWeight,
      goals: p.projectSpecific.goals,
      personalityTraits: p.projectSpecific.personalityTraits,
      painPoints: p.projectSpecific.painPoints,
      behaviourAndTasks: p.projectSpecific.behaviourAndTasks,
      summary: p.projectSpecific.summary,
    };
  });
};
