import { PMessage } from 'components/prime/PMessage/PMessage';
import styled from 'styled-components';
import { colours, cssScreens, fontSize, lineHeight } from 'styles/styles';

export const MapStepColumnMessagesStyle = {
  Container: styled.div`
    width: 35%;
    padding-left: 16px;
    @media screen and (max-width: ${cssScreens.tablet}) {
      width: 100%;
      padding: 0;
      margin-top: -12px;
    }
  `,
  Content: styled.div``,
  MessageContent: styled.div`
    display: flex;
    flex-direction: column;
  `,
  MessageTitle: styled.p`
    margin: 0;
    color: ${colours.neutralCharcoal};
    margin-bottom: 4px;
    font-weight: 600;
  `,
  MessageText: styled.span`
    color: ${colours.neutralLightCharcoal};
  `,
  Message: styled(PMessage)`
    margin-bottom: 24px;
  `,
  SuccessBox: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  `,
  SuccessText: styled.span`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 600;
    margin-bottom: 4px;
  `,
  IconContainer: styled.div`
    margin-right: 10px;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${colours.primaryGreen};
      }
    }
  `,
};
