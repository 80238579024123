import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { MapStepChangeResponseModalStyle as S } from './MapStepChangeResponseModal.style';
import { PButton } from 'components/prime/PButton/PButton';

export const MapStepChangeResponseModal: React.FC = () => {
  const { $getModal, closeResponseDateModal, changeResponseDates } = useMapStepBLoC();
  const modal = useObservableState($getModal('changeResponseDates'));
  return modal ? (
    <S.Dialog visible={true} onHide={closeResponseDateModal} header="Change response date?" closable={false}>
      <S.Container>
        <S.Text>
          You can only have one response date in your import. Changing response date will automatically set
          your current response date to a Custom Variable. Are you sure you want to continue?
        </S.Text>
        <S.Footer>
          <PButton
            severity="secondary"
            onClick={closeResponseDateModal}
            label="Cancel"
            data-testid="button-map-step-cancel-response-date"
          />
          <PButton
            label="Yes, change response dates"
            onClick={() => changeResponseDates(modal.id)}
            data-testid="button-map-step-confirm-response-date"
          />
        </S.Footer>
      </S.Container>
    </S.Dialog>
  ) : null;
};
