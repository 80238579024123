import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import 'styles/fonts.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primeflex/primeflex.min.css';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '/node_modules/quill/dist/quill.core.css';
import '/node_modules/quill/dist/quill.bubble.css';
import 'services/globals';
import App from './App';
import { BaseStyle } from 'styles/base.style';
import { tokens } from 'services/tokens';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { getFFStore } from 'stores/ff.store';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/react';
import GlobalLoader from 'modules/widgets/GlobalLoader/GlobalLoader';
import { Widget, widgetList } from 'types/Widget';
import { isReactPage } from 'utils/isReactPage';
import { ErrorBoundaryPage } from 'components/ErrorBoundaryPage/ErrorBoundaryPage';

console.log(
  `>>>>>>>>>>>>>
WELCOME TO YABBLE! 👋
Environment: ${import.meta.env.REACT_APP_ENVIRONMENT}
>>>>>>>>>>>>>`
);

if (import.meta.env.REACT_APP_PRODUCTION === 'true' && isReactPage()) {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
    release: import.meta.env.REACT_APP_SENTRY_RELEASE || '1',
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
  });
}

(window as any).setupReactParams = (params?: {
  accessToken: string;
  csrfToken: string;
  customerSlug?: string | null;
}) => {
  tokens.setTokens({
    accessToken: params?.accessToken,
    csrfToken: params?.csrfToken,
  });
  if (params?.accessToken) {
    const jwt: any = jwtDecode(params?.accessToken);
    getFFStore().init(jwt.scopes);
  }
};

(window as any).initReact = (target: HTMLElement) => {
  const root = createRoot(target || document.body);
  root.render(
    <React.StrictMode>
      <BaseStyle />
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryPage />}>
        <HelmetProvider context={{}}>
          <BrowserRouter>
            <GlobalLoader />
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};

(window as any).initReactWidget = (target: HTMLElement, widget?: Widget) => {
  const root = createRoot(target || document.body);
  const WidgetEl = widget && widgetList[widget];
  root.render(
    <React.StrictMode>
      <BaseStyle />
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryPage />}>
        <BrowserRouter>
          {WidgetEl && (
            <Suspense fallback={<div />}>
              <WidgetEl />
            </Suspense>
          )}
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};
