import React from 'react';
import { ImportModalSyntheticBLoC as BLoC } from './ImportModalSynthetic.bloc';
import { ImportModalSyntheticForm } from './components/ImportModalSyntheticForm/ImportModalSyntheticForm';
import { FromObservable } from 'components/FromObservable/FromObservable';
import { ImportModalSyntheticLoading } from './components/ImportModalSyntheticLoading/ImportModalSyntheticLoading';
import { ImportModalSyntheticCTA } from './components/ImportModalSyntheticCTA/ImportModalSyntheticCTA';

export const ImportModalSynthetic: React.FC = () => {
  return (
    <BLoC>
      {(bloc) => (
        <>
          <FromObservable $={bloc.$survey}>
            {(survey) =>
              survey === 'not-found' || !['imported', 'importing'].includes(survey?.status || '') ? (
                <ImportModalSyntheticForm />
              ) : survey?.status !== 'imported' ? (
                <ImportModalSyntheticLoading />
              ) : (
                <ImportModalSyntheticCTA />
              )
            }
          </FromObservable>
        </>
      )}
    </BLoC>
  );
};
