import React from 'react';
import { Text } from 'components/Text/Text';
import { DetailsPanelProjectsStyle as S } from './DetailsPanelProjects.style';
import { usePersonaLibraryModalBLoC } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.bloc';
import { useObservableState } from 'observable-hooks';

export const DetailsPanelProjects: React.FC = () => {
  const { $projects } = usePersonaLibraryModalBLoC();
  const projects = useObservableState($projects);

  return (
    <S.List>
      {projects?.map((p) => (
        <li key={p.id}>
          <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
            {p.name}
          </Text>
        </li>
      ))}
    </S.List>
  );
};
