import styled from 'styled-components';
import { ReactComponent as FileSvg } from 'assets/svg/file.svg';
import { ReactComponent as WarnIcon } from 'assets/svg/triangle-warning.svg';
import { colours } from 'styles/styles';

export const FileUploadItemStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Icon: styled(FileSvg)``,
  Text: styled.p`
    font-size: 20px;
    line-height: 24px;
    color: ${colours.neutralCharcoal};
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 16px;
    text-align: center;
  `,
  Subtext: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colours.neutralBody};
    text-align: center;
    margin-top: -4px;
    margin-bottom: 4px;
  `,
  WarnIcon: styled(WarnIcon)`
    margin-bottom: -6px;
  `,
};
