import styled from 'styled-components';
import { colours } from 'styles/styles';

export const CreditsModalTopupFormStyle = {
  Container: styled.div`
    padding: 24px;
    border: 1px solid ${colours.neutralGrey03};
    border-radius: 8px;
    margin: 0;
  `,
  Title: styled.p`
    margin: 0;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    color: ${colours.neutralCharcoal};
  `,
  ButtonsBox: styled.div`
    margin-top: 32px;
  `,
  FormButtonsBox: styled.div`
    margin-top: 8px;
  `,
};
