import React, { useEffect } from 'react';

export const ImportModalExitPrompt: React.FC = () => {
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    const message =
      'You are about to exit your request to import data. Leaving this page will result in any action you took to be repeated. Are you sure you want to leave?';
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
};
