import React from 'react';
import { useUploadStepBLoC } from '../../UploadStep.bloc';
import { useFormState } from 'react-hook-form';
import { useObservableState } from 'observable-hooks';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalFooter } from 'components/modals/ImportModal/components/ImportModalFooter/ImportModalFooter';

export const UploadStepFooter: React.FC = () => {
  const { closeModal } = useImportModalCsvBLoC();
  const { uploadForm, $disableNextButton } = useUploadStepBLoC();
  const { isValid } = useFormState(uploadForm);
  const disableNextButton = useObservableState($disableNextButton);
  const disabled = !isValid || disableNextButton;

  return <ImportModalFooter cancelFn={() => closeModal()} disabled={disabled} />;
};
