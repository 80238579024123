import React from 'react';
import { useCreditsModalBLoC } from '../../CreditsModal.bloc';
import { CreditsModalSummary } from './components/CreditsModalSummary/CreditsModalSummary';
import { CreditsModalTopupForm } from './components/CreditsModalTopupForm/CreditsModalTopupForm';
import { CreditsModalRightStyle as S } from './CreditsModalRight.style';

export const CreditsModalRight: React.FC = () => {
  const { handleSubmit } = useCreditsModalBLoC();
  return (
    <S.Form onSubmit={handleSubmit}>
      <CreditsModalSummary />
      <CreditsModalTopupForm />
    </S.Form>
  );
};
