import { Box } from 'components/layouts/primitives/Box/Box';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const DetailsPanelPersonasPersonaStyle = {
  Container: styled(Box)`
    .expand-button {
      visibility: hidden;
      pointer-events: none;
    }
    &:hover {
      background-color: ${colourPalette.neutral100};
      .expand-button {
        visibility: visible;
        pointer-events: all;
      }
    }
  `,
  ImageContainer: styled(Box)`
    overflow: hidden;
    height: 40px;
    width: 40px;
    img {
      height: 40px;
      object-fit: cover;
    }
  `,
};
