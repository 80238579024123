import React, { PropsWithChildren, useMemo } from 'react';
import { Tooltip, TooltipProps } from 'primereact/tooltip';
import { PTooltipStyle as S } from './PTooltip.style';
import { PButton } from '../PButton/PButton';

type PTooltipCustomProps = {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  primaryAction?: {
    label: string;
    onClick?: () => void;
  };
  secondaryAction?: {
    label: string;
    onClick?: () => void;
  };
  contentMaxWidth?: number;
};

export const PTooltip = React.forwardRef<
  Tooltip,
  PropsWithChildren<Omit<TooltipProps, 'content'> & PTooltipCustomProps>
>((props, ref) => {
  const cleanProps = useMemo(
    () => (({ title, content, primaryAction, secondaryAction, contentMaxWidth, children, ...o }) => o)(props),
    [props]
  );

  return (
    <S.Tooltip
      showDelay={300}
      hideDelay={100}
      {...cleanProps}
      autoHide={
        typeof props.autoHide === 'boolean' ? props.autoHide : !props.primaryAction && !props.secondaryAction
      }
      ref={ref}
    >
      {props.children || (
        <S.TooltipContent $maxWidth={props.contentMaxWidth || 240}>
          {!!props.title && <div className="tooltip-custom-title">{props.title}</div>}
          <div className="tooltip-custom-text">{props.content}</div>
          {!!(props.primaryAction || props.secondaryAction) && (
            <div className="tooltip-custom-actions">
              {!!props.secondaryAction && (
                <PButton
                  severity="ghost"
                  size="sm"
                  label={props.secondaryAction.label}
                  onClick={props.secondaryAction.onClick}
                />
              )}
              {!!props.primaryAction && (
                <PButton size="sm" label={props.primaryAction.label} onClick={props.primaryAction.onClick} />
              )}
            </div>
          )}
        </S.TooltipContent>
      )}
    </S.Tooltip>
  );
});
