import React from 'react';
import { DetailsPanelContentCopyToActivityStyle as S } from './DetailsPanelContentCopyToActivity.style';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { PButton } from 'components/prime/PButton/PButton';

export const DetailsPanelContentCopyToActivity: React.FC = () => {
  const { copyToActivity, $loading } = useSavedFiltersModalBLoC();
  const loading = useObservableState($loading.pipe(map((l) => l === 'copying')));

  return (
    <S.Container>
      <S.Message>Copying this filter will override any current conditions selected already.</S.Message>
      <PButton label="Apply" onClick={copyToActivity} loading={loading} className="w-full" />
    </S.Container>
  );
};
