import styled from 'styled-components';
import { cssScreens } from 'styles/styles';

export const UploadStepSelecFileBoxStyle = {
  Container: styled.div<{ hasProjectId?: boolean }>`
    flex: 1;
    ${({ hasProjectId }) => !hasProjectId && `margin-right: 20px;`}
    @media screen and (max-width: ${cssScreens.tablet}) {
      margin: 0;
    }
  `,
};
