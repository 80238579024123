import styled from 'styled-components';

import { colours, fontSize, lineHeight } from 'styles/styles';

export const FiltersTableFilterItemStyle = {
  Container: styled.div`
    height: 38px;
    padding: 0 8px;
    background-color: ${colours.neutralWhite};
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: ${colours.neutralLightCharcoal};
    cursor: pointer;
    & > p {
      margin: 0;
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.is-selected {
      background-color: #eaf0fd;
      color: ${colours.primaryBlue} !important;
    }
    &:hover {
      background-color: ${colours.neutralGrey02};
      color: ${colours.neutralLightCharcoal};
    }
  `,
};
