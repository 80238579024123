import styled from 'styled-components';

import { colours } from 'styles/styles';
import { ReactComponent as Svg } from 'assets/svg/loader.svg';
import { LoaderAnimations } from './Loader.animations';

export const LoaderStyle = {
  Container: styled.div<{ $delay: number }>`
    display: flex;

    &.loader-main {
      width: 250px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > svg {
      width: 130px;
      height: 130px;
      overflow: visible;
    }
    .loader-main__circle-wrapper {
      animation: ${LoaderAnimations.loaderMainCircleAnimationYAxis} 1.8s ease-in-out infinite normal;
      animation-timing-function: steps(4);
      animation-delay: ${({ $delay }) => $delay}ms;
    }
    .loader-main__circle {
      animation: ${LoaderAnimations.loaderMainCircleAnimationXAxis} 1.8s ease-in-out infinite normal;
      animation-timing-function: steps(4);
      animation-delay: ${({ $delay }) => $delay}ms;

      fill: ${colours.neutralCharcoal};
    }
    .loader-main__rectangle {
      animation: ${LoaderAnimations.loaderMainRectangleAnimation} 1.8s ease-in-out infinite normal;
      animation-timing-function: steps(4);
      animation-delay: ${({ $delay }) => $delay}ms;

      transform-origin: 49% 49%;
      fill: ${colours.neutralCharcoal};
    }
    &.inverted {
      svg,
      path {
        fill: white;
      }
    }
    &.small {
      transform: scale(0.2);
    }
    &.regular {
      transform: scale(0.4);
    }
    &.medium {
      transform: scale(0.6);
    }
    &.large {
      transform: scale(0.8);
    }
  `,
  LoaderSvg: styled(Svg)`
    min-height: 52px;
    min-width: 52px;
    overflow: visible;
    min-height: 130px;
    min-width: 130px;
    max-height: 130px;
    max-width: 130px;
    width: 130px;
    height: 130px;
    display: flex;
    position: relative;
  `,
};
