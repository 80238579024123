import styled from 'styled-components';

import { colours } from 'styles/styles';

export const SavedFiltersModalListPanelStyle = {
  Container: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    & > .list-panel-search {
      flex: 0 0 fit-content;
      padding: 24px;
      background-color: ${colours.neutralWhite};
    }
    & > .list-panel-filters {
      flex: 1 1 auto;
      padding: 0 24px;
      overflow-y: overlay;
    }
    &.panel-blocked {
      pointer-events: none;
      opacity: 0.5;
    }
  `,
};
