import styled from 'styled-components';

import { colours, cssScreens } from 'styles/styles';

export const TopBarCreditsStyle = {
  Credits: styled.p`
    min-width: fit-content;
    padding-left: 13px;
    color: ${colours.neutralBody};
    margin: 0;

    @media screen and (max-width: ${cssScreens.tabletLarge}) {
      display: none;
    }
  `,
  CreditsLink: styled.span`
    color: ${colours.neutralCharcoal};
    font-weight: 400;
    &.link-to-credits-enabled {
      cursor: pointer;
    }
  `,
};
