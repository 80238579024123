import React from 'react';
import { ListPanelGroupItemStyle as S } from './ListPanelGroupItem.style';
import { Text } from 'components/Text/Text';
import { PersonaLibraryModalTypes } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.types';
import { usePersonaLibraryModalBLoC } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.bloc';
import { useObservableState } from 'observable-hooks';
import { useClassNames } from 'hooks/useClassNames';

export const ListPanelGroupItem: React.FC<{
  group: PersonaLibraryModalTypes.PersonaGroup;
}> = ({ group }) => {
  const { $selectedGroup, setSelectedGroup } = usePersonaLibraryModalBLoC();
  const selectedGroup = useObservableState($selectedGroup);
  const classNames = useClassNames({
    'is-selected': !!group && selectedGroup?.id === group?.id,
    'is-loading': !group,
  });

  return (
    <S.GroupItem
      padding="paddingSM"
      backgroundColor="neutral0"
      radius="roundedSM"
      onClick={() => setSelectedGroup(group)}
      role="button"
      className={classNames}
    >
      <Text
        as="p"
        size="fontSizeSM"
        lineHeight="lineHeightMD"
        color="neutral600"
        ellipsis
        transform="upper-first-letter"
      >
        {group.name}
      </Text>
    </S.GroupItem>
  );
};
