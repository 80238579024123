import styled from 'styled-components';
import { colourPalette, gap } from 'styles/styles';

export const PFormFieldError = {
  Container: styled.small`
    margin-top: ${gap.gap2};
    font-size: 12px;
    color: ${colourPalette.rose600};
    opacity: 0;
    overflow: hidden;
    min-height: 18px;
    line-height: 18px;
    margin-bottom: ${gap.gap1};
    height: fit-content;
    &.is-visible {
      opacity: 1;
    }
  `,
};
