import React from 'react';
import { ImportModalCsvBLoC as BLoC } from './ImportModalCsv.bloc';
import { ImportModalCsvContent } from './components/ImportModalCsvContent/ImportModalCsvContent';
import { ImportModalCsvHeader } from './components/ImportModalCsvHeader/ImportModalCsvHeader';
import { ImportModalInvalidDevice } from '../ImportModalInvalidDevice/ImportModalInvalidDevice';

export const ImportModalCsv: React.FC = () => {
  return (
    <BLoC>
      <ImportModalInvalidDevice />
      <ImportModalCsvHeader />
      <ImportModalCsvContent />
    </BLoC>
  );
};
