import React from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';
import { ListPanelFiltersEmptyState } from './components/ListPanelFiltersEmptyState/ListPanelFiltersEmptyState';
import { ListPanelFiltersTable } from './components/ListPanelFiltersTable/ListPanelFiltersTable';
import { ListPanelFiltersStyle as S } from './ListPanelFilters.style';

export const ListPanelFilters: React.FC = () => {
  const { $filters } = useSavedFiltersModalBLoC();
  const filters = useObservableState($filters);

  return (
    <S.Container>
      <S.Title>Filters</S.Title>
      {!filters || filters?.length ? <ListPanelFiltersTable /> : <ListPanelFiltersEmptyState />}
    </S.Container>
  );
};
