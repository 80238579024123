import { ImportModalContent } from 'components/modals/ImportModal/components/ImportModalContent/ImportModalContent';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalLoading } from 'components/modals/ImportModal/components/ImportModalLoading/ImportModalLoading';
import { ImportModalMainSection } from 'components/modals/ImportModal/components/ImportModalMainSection/ImportModalMainSection';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useHeyYabbleCountStepBLoC } from '../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepSummary } from '../HeyYabbleCountStepSummary/HeyYabbleCountStepSummary';
import { HeyYabbleCountStepTable } from '../HeyYabbleCountStepTable/HeyYabbleCountStepTable';
import { HeyYabbleCountStepContentStyle as S } from './HeyYabbleCountStepContent.style';
import { HeyYabbleCountStepBanner } from '../HeyYabbleCountStepBanner/HeyYabbleCountStepBanner';

export const HeyYabbleCountStepContent: React.FC = () => {
  const { isEmptyProject } = useImportModalCsvBLoC();
  const { $loading, $ingestionCostsDetails } = useHeyYabbleCountStepBLoC();
  const loading = useObservableState($loading, false);
  useObservableState($ingestionCostsDetails);
  return (
    <>
      <ImportModalContent isLoading={loading} loadingComponent={<ImportModalLoading />}>
        <S.Row>
          <div>
            <ImportModalMainSection
              title={isEmptyProject ? 'Hey Yabble Count' : 'Do you want to make changes to Hey Yabble Count?'}
              subtitle={
                !isEmptyProject
                  ? 'Columns with counting applied in previous imports have been pre-selected for counting. We only apply counting to valid datapoints where no errors or null values have been detected.'
                  : 'Selected columns will be processed for Hey Yabble Count. Deselect any columns you don’t wish to count. If data is not counted Hey Yabble Query with your data. We only apply counting to valid datapoints where no errors or null values have been detected.'
              }
            />
            <HeyYabbleCountStepBanner />
            <HeyYabbleCountStepTable />
          </div>
          <HeyYabbleCountStepSummary />
        </S.Row>
      </ImportModalContent>
    </>
  );
};
