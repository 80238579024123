import React from 'react';
import { useObservableState } from 'observable-hooks';
import { TopBarCreditsStyle as S } from './TopBarCredits.style';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { getUserStore } from 'stores/user.store';
import { getCustomerStore } from 'stores/customer.store';
import { useClassNames } from 'hooks/useClassNames';
import { useNavigate } from 'react-router-dom';

export const TopBarCredits: React.FC = () => {
  const navigate = useNavigate();
  const userRole = useObservableState(getUserStore().$userRole);
  const customerSlug = useObservableState(getCustomerStore().$customerSlug);
  const credits = useObservableState(getBillingPlanStore().$creditsBalance);
  const linkToCreditsEnabled = userRole !== 'user' && !!customerSlug;
  const linkClassNames = useClassNames({
    'link-to-credits-enabled': linkToCreditsEnabled,
  });

  return credits !== undefined ? (
    <S.Credits>
      You have{' '}
      <S.CreditsLink
        onClick={() => {
          if (linkToCreditsEnabled) navigate('/settings/subscription');
        }}
        className={linkClassNames}
        data-testid="label-top-bar-credits"
      >
        <FormattedNumber num={credits} /> credits
      </S.CreditsLink>
    </S.Credits>
  ) : null;
};
