import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import styled from 'styled-components';

export const HeyYabbleCountStepTableRowPipelinesStyle = {
  Container: styled.div``,
  Dropdown: styled(PDropdown)`
    min-height: 30px !important;
    width: 188px;
  `,
};
