import React from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { useSavedFiltersModalBLoC } from '../../SavedFiltersModal.bloc';
import { ListPanelFilters } from './components/ListPanelFilters/ListPanelFilters';
import { ListPanelSearch } from './components/ListPanelSearch/ListPanelSearch';
import { SavedFiltersModalListPanelStyle as S } from './SavedFiltersModalListPanel.style';

export const SavedFiltersModalListPanel: React.FC = () => {
  const { $loading } = useSavedFiltersModalBLoC();
  const loadingDelete = useObservableState($loading.pipe(map((l) => l === 'deleting')));
  const containerClassName = useClassNames({
    'panel-blocked': loadingDelete,
  });

  return (
    <S.Container className={containerClassName}>
      <div className="list-panel-search">
        <ListPanelSearch />
      </div>
      <div className="list-panel-filters">
        <ListPanelFilters />
      </div>
    </S.Container>
  );
};
