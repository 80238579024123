import { useMapStepBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/components/ImportModalCsvContent/components/MapStep/MapStep.bloc';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { MapStepColumnMapToItem } from '../MapStepColumnMapToItem/MapStepColumnMapToItem';
import { CustomVariablesDropdownStyle as S } from './CustomVariablesDropdown.style';

export const CustomVariablesDropdown: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({
  column,
}) => {
  const { $customVariables, handleChangeCustomVariableId, $selectedVars } = useMapStepBLoC();
  const customVariables = useObservableState($customVariables, []);
  const selectedVars = useObservableState($selectedVars);

  return (
    <S.Container>
      <S.Text>MAP TO</S.Text>
      <PDropdown
        appendTo="self"
        value={column.customVariableId}
        options={customVariables}
        optionValue="id"
        optionLabel="name"
        placeholder="Select a column"
        onChange={(e) =>
          handleChangeCustomVariableId(
            column.id,
            e.target.value,
            customVariables.find((c) => c.id === e.target.value)?.name || ''
          )
        }
        disabled={column.ignoreColumn}
        itemTemplate={(item) => (
          <MapStepColumnMapToItem
            {...{ item }}
            selectedItems={selectedVars?.variables || []}
            currentValue={column.customVariableId}
          />
        )}
      />
    </S.Container>
  );
};
