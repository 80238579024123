import styled from 'styled-components';

export const ButtonMenuStyle = {
  Container: styled.div`
    cursor: pointer;
    &.is-disabled {
      cursor: default;
    }
  `,
};
