/**
 * NOTES:
 * @see https://gist.github.com/ivanbtrujillo/005db52e4a8ef8fd1dc8f2db3b442d45
 */
import * as React from 'react';
import type { ComponentType } from 'react';

export const lazyRefresh = (factory: () => Promise<{ default: ComponentType<any> }>) => {
  return React.lazy(() => factory().catch(importErrorHandler));
};

function importErrorHandler(err: string): { default: ComponentType<any> } {
  const timeStr = sessionStorage.getItem('last-reload');
  const time = timeStr ? Number(timeStr) : null;
  const now = Date.now();

  const isReloading = !time || time + 10_000 < now;

  if (isReloading) {
    console.log('A new version for this lazy loaded module was found. Reloading...');
    sessionStorage.setItem('dynamic-import-last-reload', String(now));
    window.location.reload();
    return { default: () => null };
  }

  throw new Error(err);
}
