import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { useCreditsModalBLoC } from 'components/modals/CreditsModal/CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { getCustomerStore } from 'stores/customer.store';
import { CreditsModalTopupFormTotalToPayStyle as S } from './CreditsModalTopupFormTotalToPay.style';

export const CreditsModalTopupFormTotalToPay: React.FC = () => {
  const { $formBalanceAndTotal } = useCreditsModalBLoC();
  const formBalanceAndTotal = useObservableState($formBalanceAndTotal);
  const currency = useObservableState(getCustomerStore().$currency);
  const pricePerCredit = useObservableState(getBillingPlanStore().$pricePerCredit, 0);

  return pricePerCredit > 0 ? (
    <S.Container>
      <S.Content key={formBalanceAndTotal?.totalToPay || 0}>
        <p>Total to pay</p>
        <p>
          {currency?.short} <FormattedNumber num={formBalanceAndTotal?.totalToPay || 0} fractionDigits={2} />
        </p>
      </S.Content>
      <S.Message>
        Credits will be billed at your agreed contract rate and invoiced at the end of each month.
      </S.Message>
    </S.Container>
  ) : null;
};
