import { Skeleton } from 'primereact/skeleton';
import styled from 'styled-components';

import { colours } from 'styles/styles';

export const PSkeletonStyle = {
  Skeleton: styled(Skeleton)`
    &.p-skeleton.p-component {
      background-color: ${colours.neutralGrey02};
    }
  `,
};
