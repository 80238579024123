import styled from 'styled-components';
import { colours } from 'styles/styles';

export const DetailsPanelContentMetaStyle = {
  Container: styled.ul`
    margin-top: 4px;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    & > span {
      display: inline-block;
      margin: 0 7px;
      width: 2px;
      height: 2px;
      background-color: ${colours.neutralGrey06};
      position: relative;
      top: -3px;
    }
    & > li {
      margin: 4px 7px 0 0;
      font-size: 13px;
      color: ${colours.neutralGrey06};
      line-height: 16px;
      min-height: 16px;
      flex: 0 0 auto;
      &:first-child {
        flex: 1 1 auto;
        max-width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:last-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 250px;
      }
      &:not(:first-of-type)::before {
        content: '';
        display: inline-block;
        margin: 0 7px 0 0;
        width: 2px;
        height: 2px;
        background-color: ${colours.neutralGrey06};
        position: relative;
        top: -3px;
      }
    }
  `,
};
