import styled from 'styled-components';
import { Button } from 'primereact/button';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  hexToRGBA,
  lineHeight,
  padding,
} from 'styles/styles';

export const PButtonStyle = {
  Button: styled(Button)`
    min-width: auto;
    background: none;
    transition: 0.1s ease-out;
    min-width: 64px;
    justify-content: center;
    .button-custom-wrapper {
      display: flex;
      align-items: center;
    }
    .button-custom-icon-wrapper {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
    .button-custom-label {
      flex: 1 1 auto;
      font-weight: 500;
      line-height: 1;
      padding: ${padding.paddingNone} ${padding.paddingSM};
    }
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: ${borders.borderMD} solid ${colourPalette.neutral0};
      box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    }
    &.p-disabled,
    &:disabled {
      opacity: 1;
    }
    &.p-button-loading {
      .button-custom-wrapper {
        display: none;
      }
    }

    &.button-size-sm {
      height: 32px;
      padding: 0 ${padding.paddingXS};
      border-radius: ${borderRadius.roundedSM};
      .button-custom-icon-wrapper {
        width: 20px;
        height: 20px;
      }
      .button-custom-label {
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
      }
      &.p-button-loading {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &.button-size-md {
      height: 40px;
      padding: 0 ${padding.paddingSM};
      border-radius: ${borderRadius.roundedSM};
      .button-custom-icon-wrapper {
        width: 20px;
        height: 20px;
      }
      .button-custom-label {
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
      }
      &.p-button-loading {
        svg {
          width: 36px;
          height: 36px;
        }
      }
    }

    &.button-size-lg {
      height: 48px;
      padding: 0 ${padding.paddingMD};
      border-radius: ${borderRadius.roundedMD};
      .button-custom-icon-wrapper {
        width: 24px;
        height: 24px;
      }
      .button-custom-label {
        font-size: 16px;
      }
      &.p-button-loading {
        svg {
          width: 36px;
          height: 36px;
        }
      }
    }

    &.button-severity-primary {
      background: ${colourPalette.blue500};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral0};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.blue500};
      }
      &:hover {
        background: ${colourPalette.blue600};
      }
      &:active {
        background: ${colourPalette.blue400};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.blue200} !important;
        svg rect {
          fill: ${colourPalette.neutral0};
        }
      }
    }

    &.button-severity-secondary {
      background: ${colourPalette.neutral100};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral900};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral900};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral900};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral100};
      }
      &:hover {
        background: ${colourPalette.neutral200};
      }
      &:active {
        background: ${colourPalette.neutral50};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.neutral50} !important;
        svg rect {
          fill: ${colourPalette.neutral300};
        }
      }
    }

    &.button-severity-tertiary {
      background: ${colourPalette.neutral0};
      border: ${borders.borderSM} solid ${colourPalette.gray200};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral600};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral0};
        border-color: ${colourPalette.gray200};
      }
      &:hover {
        background: ${colourPalette.neutral100};
        border-color: ${colourPalette.gray300};
      }
      &:active {
        background: ${colourPalette.neutral0};
        border-color: ${colourPalette.gray200};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral0} !important;
        border-color: ${colourPalette.gray200};
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.neutral0} !important;
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        svg rect {
          fill: ${colourPalette.neutral300};
        }
      }
    }

    &.button-severity-soft {
      background: ${colourPalette.blue50};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.blue500};
          }
          &[stroke] {
            stroke: ${colourPalette.blue500};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.blue500};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.blue50};
      }
      &:hover {
        background: ${colourPalette.blue200};
      }
      &:active {
        background: ${colourPalette.blue50};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.blue50} !important;
        svg rect {
          fill: ${colourPalette.blue200};
        }
      }
    }

    &.button-severity-highlight {
      background: ${colourPalette.violet500};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral0};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.violet500};
      }
      &:hover {
        background: ${colourPalette.violet600};
      }
      &:active {
        background: ${colourPalette.violet400};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.violet300} !important;
        svg rect {
          fill: ${colourPalette.neutral0};
        }
      }
    }

    &.button-severity-danger {
      background: ${colourPalette.red50};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.rose600};
          }
          &[stroke] {
            stroke: ${colourPalette.rose600};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.rose600};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.red50};
      }
      &:hover {
        background: ${colourPalette.red200};
      }
      &:active {
        background: ${colourPalette.red50};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.red50} !important;
        svg rect {
          fill: ${colourPalette.rose500};
        }
      }
    }

    &.button-severity-invert {
      background: ${colourPalette.neutral800};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral0};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral800};
      }
      &:hover {
        background: ${colourPalette.neutral950};
      }
      &:active {
        background: ${colourPalette.neutral600};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral100} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.neutral300} !important;
        svg rect {
          fill: ${colourPalette.neutral0};
        }
      }
    }

    &.button-severity-ghost {
      background: transparent;
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral600};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: transparent;
      }
      &:hover {
        background: ${colourPalette.neutral100};
      }
      &:active {
        background: ${colourPalette.neutral0};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral0} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-button-loading {
        background: transparent !important;
        svg rect {
          fill: ${colourPalette.neutral300};
        }
      }
    }

    &.button-severity-on-invert {
      background: ${colourPalette.neutral800};
      .button-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      .button-custom-label {
        color: ${colourPalette.neutral0};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral800};
      }
      &:hover {
        background: ${colourPalette.neutral700};
      }
      &:active {
        background: ${colourPalette.neutral900};
      }
      &.p-disabled,
      &:disabled {
        background: ${colourPalette.neutral800} !important;
        .button-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
        .button-custom-label {
          color: ${colourPalette.neutral400};
        }
      }
      &.p-button-loading {
        background: ${colourPalette.neutral600} !important;
        svg rect {
          fill: ${colourPalette.neutral0};
        }
      }
    }
  `,
};
