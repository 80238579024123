import React from 'react';
import { useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { useClassNames } from 'hooks/useClassNames';
import { useSavedFiltersModalBLoC } from '../../SavedFiltersModal.bloc';
import { DetailsPanelContent } from './components/DetailsPanelContent/DetailsPanelContent';
import { DetailsPanelEmptyState } from './components/DetailsPanelEmptyState/DetailsPanelEmptyState';
import { SavedFiltersModalDetailsPanelStyle as S } from './SavedFiltersModalDetailsPanel.style';

export const SavedFiltersModalDetailsPanel: React.FC = () => {
  const { $selectedFilterId, $loading } = useSavedFiltersModalBLoC();
  const loadingDelete = useObservableState($loading.pipe(map((l) => l === 'deleting')));
  const selectedFilterId = useObservableState($selectedFilterId);
  const containerClassName = useClassNames({
    'panel-blocked': loadingDelete,
  });

  return (
    <S.Container className={containerClassName}>
      {selectedFilterId ? <DetailsPanelContent /> : <DetailsPanelEmptyState />}
    </S.Container>
  );
};
