import React, { PropsWithChildren } from 'react';
import { ImportModalContentStyle as S } from './ImportModalContent.style';

export const ImportModalContent: React.FC<
  PropsWithChildren<{
    className?: string;
    loadingComponent?: React.ReactNode;
    isLoading?: boolean;
  }>
> = ({ children, className, loadingComponent, isLoading }) => {
  if (isLoading && loadingComponent) return <>{loadingComponent}</>;

  return (
    <S.Container {...{ className }}>
      <S.Box>
        <S.Scroll>{children}</S.Scroll>
      </S.Box>
    </S.Container>
  );
};
