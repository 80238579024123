import styled from 'styled-components';
import { ReactComponent as YabbleGenAvatarSvg } from 'assets/svg/yabble-gen-avatar.svg';
import { colours } from 'styles/styles';

export const TopBarChatButtonStyle = {
  Container: styled.div`
    max-width: fit-content;
    margin-left: 8px;
    height: 32px;
  `,
  YabbleGenAvatar: styled(YabbleGenAvatarSvg)`
    width: 32px;
    height: 32px;
    cursor: pointer;
    & > rect {
      fill: ${colours.neutralLightCharcoal};
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      & > rect {
        fill: ${colours.neutralCharcoal};
      }
    }
  `,
};
