import React from 'react';
import { useObservableState } from 'observable-hooks';
import { useHeyYabbleCountStepBLoC } from '../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepTableHeader } from './components/HeyYabbleCountStepTableHeader/HeyYabbleCountStepTableHeader';
import { HeyYabbleCountStepTableRow } from './components/HeyYabbleCountStepTableRow/HeyYabbleCountStepTableRow';
import { HeyYabbleCountStepTableStyle as S } from './HeyYabbleCountStepTable.style';

export const HeyYabbleCountStepTable: React.FC = () => {
  const { $selectedColumns, $columns } = useHeyYabbleCountStepBLoC();
  const selectedColumns = useObservableState($selectedColumns, []);
  const columnsWithCostDetails = useObservableState($columns, []);

  return (
    <S.Container>
      <HeyYabbleCountStepTableHeader />
      {columnsWithCostDetails.map((column, i) => (
        <HeyYabbleCountStepTableRow key={i} {...{ column, selectedColumns }} />
      ))}
    </S.Container>
  );
};
