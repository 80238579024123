import React from 'react';
import { NotificationServiceTypes } from '../../NotificationService.types';
import { NotificationContentLayoutStyle as S } from './NotificationContentLayout.style';

export const NotificationContentLayout: React.FC<NotificationServiceTypes.NotificationProps> = ({
  title,
  description,
  actionButtons,
}) => {
  return (
    <S.Container>
      <S.Content>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
        {actionButtons}
      </S.Content>
    </S.Container>
  );
};
