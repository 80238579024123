import styled from 'styled-components';
import { colours } from 'styles/styles';

export const HeyYabbleCountStepTableStyle = {
  Container: styled.div`
    margin-top: 10px;
    margin-bottom: 40px;
    border: 1px solid ${colours.neutralGrey03};
  `,
};
