let isFirstPageView = true;

const initialize = () => {
  if (!document.getElementById('hs-script-loader')) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.id = 'hs-script-loader';
    s.src = `//js.hs-scripts.com/${import.meta.env.REACT_APP_HUBSPOT_TRACKING_ID}.js`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode?.insertBefore(s, x);
  }
};

export const initAndIdentify = (meta: Record<string, unknown> = {}) => {
  window._hsq = window._hsq || [];
  (window as any)?._hsq?.push(['setPath', document.location.pathname]);

  initialize();
  (window as any)?._hsq?.push(['identify', meta]);
};
export const pageView = (pathname = document.location.pathname + document.location.search) => {
  // To avoid duplicate page views the first time (See: https://legacydocs.hubspot.com/docs/methods/tracking_code_api/set_page_path)
  if (isFirstPageView) {
    isFirstPageView = false;
    return;
  }

  (window as any)?._hsq?.push(['setPath', pathname]);
  (window as any)?._hsq?.push(['trackPageView']);
};
