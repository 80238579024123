import React, { useEffect, useRef } from 'react';
import { Snackbar, SnackbarMessageCustom, SnackbarMessageCustomProps } from 'components/Snackbar/Snackbar';

let snackbarRef: React.RefObject<any> | null = null;

/**
 * @deprecated Use snackbarV2 instead
 */
export const SnackbarService: React.FC = () => {
  const ref = useRef<typeof Snackbar>(null);
  useEffect(() => {
    snackbarRef = ref;
  }, []);
  return <Snackbar ref={ref as any} />;
};

/**
 * @deprecated Use snackbarV2 instead
 */
export const snackbar = (
  props: Omit<SnackbarMessageCustomProps, 'onDismiss'> & {
    life?: number;
  }
) => {
  snackbarRef?.current?.replace({
    content: <SnackbarMessageCustom {...props} onDismiss={() => snackbarRef?.current?.clear()} />,
    life: props.life || 4000,
    sticky: props.sticky,
  });
};
