import React from 'react';
import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { PDivider } from 'components/prime/PDivider/PDivider';
import { useObservableState } from 'observable-hooks';
import { useHeyYabbleCountStepBLoC } from '../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepSummaryStyle as S } from './HeyYabbleCountStepSummary.style';

export const HeyYabbleCountStepSummary: React.FC = () => {
  const { isEmptyProject } = useImportModalCsvBLoC();
  const { $credits, $comments, $remaining } = useHeyYabbleCountStepBLoC();
  const credits = useObservableState($credits, 0);
  const comments = useObservableState($comments, 0);
  const remaining = useObservableState($remaining, 0);

  return (
    <S.Container>
      <S.Row>
        <S.Title>SUMMARY</S.Title>
      </S.Row>
      <S.Row>
        <S.Text>Available credits</S.Text>
        <S.Text data-testid="label-count-step-available-credits">
          <FormattedNumber num={credits} />
        </S.Text>
      </S.Row>
      <S.Row>
        <S.Text>Hey Yabble Count</S.Text>
        <S.Text data-testid="label-count-step-comments-count">
          {comments > 0 && '-'}
          <FormattedNumber num={comments} />
        </S.Text>
      </S.Row>
      <S.Row className="sub">
        <S.Text>
          <FormattedNumber num={comments} /> comment{comments !== 1 && 's'}
        </S.Text>
        <S.Text>
          {comments > 0 && '-'}
          <FormattedNumber num={comments} />
        </S.Text>
      </S.Row>
      {!!isEmptyProject && (
        <S.SecondaryRow>
          <S.Description>1 comment = 1 credit</S.Description>
        </S.SecondaryRow>
      )}
      <PDivider className="mt-2 mb-4" />
      <S.Row>
        <S.Total>Remaining balance</S.Total>
        <S.Total data-testid="label-count-step-remaining-balance">
          <FormattedNumber num={remaining} />
        </S.Total>
      </S.Row>
    </S.Container>
  );
};
