import React, { useMemo } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { TopBarDropdownUserStyle as S } from './TopBarDropdownUser.style';
import { DropdownMenuItem } from 'components/DropdownMenuItem/DropdownMenuItem';
import { useObservableState } from 'observable-hooks';
import { getUserStore } from 'stores/user.store';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { DropdownUser } from 'components/DropdownUser/DropdownUser';
import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { getCustomerStore } from 'stores/customer.store';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getItems: (options?: {
  isOwner?: boolean;
  userEmail?: string;
  userFullName?: string;
  credits?: number;
  slug?: string;
  userRole?: 'administrator' | 'user' | undefined;
  navigate?: NavigateFunction;
}) => MenuItem[] = (options = {}) => {
  const { isOwner, userEmail, userFullName, credits, userRole, navigate } = options;

  const defaultItems: MenuItem[] = [
    {
      template: () => (
        <DropdownMenuItem isMuted variableHeight>
          <S.MenuItemUser>
            {userFullName && <p data-testid="label-top-bar-user-fullname">{userFullName}</p>}
            <p data-testid="label-top-bar-user-email" title={userEmail}>
              {userEmail}
            </p>
          </S.MenuItemUser>
        </DropdownMenuItem>
      ),
    },
    { separator: true },
    {
      template: () => (
        <DropdownMenuItem
          variableHeight
          isMuted={userRole === 'user'}
          onClick={() => {
            if (userRole !== 'user') navigate?.('/settings/subscription');
          }}
        >
          <S.MenuItemCredits>
            <p>Available credits</p>
            <span>{credits !== undefined ? <FormattedNumber num={credits} /> : '...'}</span>
          </S.MenuItemCredits>
        </DropdownMenuItem>
      ),
    },
    { separator: true },
    {
      label: 'Administration',
      command: () => (window.location.href = `${window.location.origin}/admin`),
    },
    {
      label: 'Settings',
      command: () => (window.location.href = `${window.location.origin}/settings/profile`),
    },
    {
      label: 'Log out',
      command: () => (window.location.href = `${window.location.origin}/logout`),
    },
  ];

  if (!isOwner) return defaultItems.filter((item) => item.label !== 'Administration');

  return defaultItems;
};

export const TopBarDropdownUser: React.FC = () => {
  const navigate = useNavigate();
  const user = useObservableState(getUserStore().$user);
  const userRole = useObservableState(getUserStore().$userRole);
  const customer = useObservableState(getCustomerStore().$customer);
  const credits = useObservableState(getBillingPlanStore().$creditsBalance);
  const formattedLinks = useMemo(
    () =>
      getItems({
        isOwner: user?.isYabbleOwner,
        userEmail: user?.email,
        userFullName: `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
        credits,
        slug: customer?.slug,
        userRole,
        navigate,
      }),
    [user, userRole, customer, credits, navigate]
  );

  return (
    <S.Container>
      <DropdownUser user={user} links={formattedLinks} />
    </S.Container>
  );
};
