import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const TopBarDropdownUserStyle = {
  Container: styled.div`
    margin-left: 8px;
    max-width: fit-content;
  `,
  MenuItemCredits: styled.div`
    width: 100%;
    padding: 10px 0;
    p {
      color: ${colours.neutralGrey06};
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0 0 2px 0;
    }
    span {
      color: ${colours.neutralLightCharcoal};
    }
  `,
  MenuItemUser: styled.div`
    max-width: 230px;
    padding: 10px 0;
    p {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      &:first-child {
        color: ${colours.neutralLightCharcoal};
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
        font-weight: 500;
        margin: 0 0 3px 0;
      }
      &:last-child {
        color: ${colours.neutralGrey06};
        font-size: 12px;
      }
    }
  `,
};
