import styled from 'styled-components';
import { Checkbox } from 'primereact/checkbox';
import { borderRadius, borders, colourPalette, hexToRGBA } from 'styles/styles';

export const PCheckboxStyle = {
  CheckboxWrapper: styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `,
  Checkbox: styled(Checkbox)`
    width: 16px;
    height: 16px;
    margin-top: 3px;
    & > .p-checkbox-box {
      width: 16px;
      height: 16px;
      border-radius: ${borderRadius.roundedXS};
      border: ${borders.borderSM} solid ${colourPalette.gray200};
      background: ${colourPalette.neutral0};

      &.p-highlight {
        border-color: ${colourPalette.blue500};
        background: ${colourPalette.blue500};

        & > .p-checkbox-icon {
          width: 9px;
          height: 9px;
          path {
            fill: ${colourPalette.neutral0};
            stroke: ${colourPalette.neutral0};
          }
        }
      }

      &:hover {
        border-color: ${colourPalette.gray300} !important;
        background: ${colourPalette.neutral100};
        &.p-highlight {
          border-color: ${colourPalette.blue600} !important;
          background: ${colourPalette.blue600} !important;
        }
      }

      &:focus,
      &:active,
      &:focus-within,
      &:focus-visible {
        outline: ${borders.borderMD} solid ${colourPalette.neutral0};
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
      }

      &.p-disabled {
        border-color: ${colourPalette.gray200};
        background: ${colourPalette.neutral100};
        opacity: 1;
        &.p-highlight {
          background: ${colourPalette.neutral100};
          border-color: ${colourPalette.neutral100};
        }
        & > .p-checkbox-icon {
          path {
            fill: ${colourPalette.neutral400};
            stroke: ${colourPalette.neutral400};
          }
        }
      }
    }

    &.p-invalid > .p-checkbox-box {
      border-color: ${colourPalette.rose500} !important;
      background: ${colourPalette.neutral0} !important;
      &.p-highlight {
        background: ${colourPalette.rose500} !important;
        &:hover {
          border-color: ${colourPalette.rose500} !important;
          background: ${colourPalette.rose500} !important;
        }
      }
    }

    &.p-checkbox {
      box-shadow: none !important;
    }

    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible {
      & > .p-checkbox-box {
        border-color: ${colourPalette.gray200};
        outline: ${borders.borderMD} solid ${colourPalette.neutral0};
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        &.p-highlight {
          border-color: ${colourPalette.blue500};
        }
      }
    }

    &.p-checkbox-disabled {
      pointer-events: none;
    }

    &.indeterminate-check > .p-checkbox-box > svg {
      width: 7px !important;
      height: 9px !important;
      transform: rotate(45deg) !important;
      position: relative;
      top: -0.5px;
    }
    &.indeterminate-check > .p-checkbox-box.p-highlight > svg {
      stroke: ${colourPalette.neutral0};
    }
    &.indeterminate-check > .p-checkbox-box.p-disabled > svg {
      stroke: ${colourPalette.neutral400};
    }

    &.no-label {
      margin-top: 0px;
    }
  `,
  Label: styled.label`
    margin-left: 8px;
    user-select: none;
    line-height: 24px;
    cursor: pointer;
    color: ${colourPalette.neutral600};

    span.description {
      display: block;
      font-size: 13px;
      line-height: 20px;
      color: ${colourPalette.neutral400};
    }

    .p-checkbox-disabled + & {
      color: ${colourPalette.neutral300};
      pointer-events: none;
      span.description {
        color: ${colourPalette.neutral300};
      }
    }

    .p-invalid + & {
      color: ${colourPalette.rose500};
      span.description {
        color: ${colourPalette.rose500};
      }
    }
  `,
};
