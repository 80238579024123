import styled, { css } from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  fontWeight,
  gap,
  hexToRGBA,
  lineHeight,
  padding,
  shadow,
} from 'styles/styles';

export const PMultiSelectGlobalStyle = css`
  .p-multiselect-panel.p-component {
    min-width: 250px;
    box-shadow: ${shadow.shadowMD};

    & > .p-multiselect-header {
      padding: ${padding.paddingSM} ${padding.paddingXXS} ${padding.paddingSM} ${padding.paddingMD};
      border-color: ${colourPalette.neutral300};
      & > .p-multiselect-filter-container {
        margin: 0px ${gap.gap1} 0px ${gap.gap3};
        & > .p-multiselect-filter {
          height: 40px;
          background-color: ${colourPalette.neutral0};
          border: ${borders.borderSM} solid ${colourPalette.gray200};
          padding: 0 ${gap.gap3} 0 32px;
          &::placeholder {
            color: ${colourPalette.neutral400};
          }
          &:hover {
            border-color: ${colourPalette.gray300};
            background-color: ${colourPalette.neutral100};
          }
          &:focus {
            box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
            border-color: ${colourPalette.blue500};
            background-color: ${colourPalette.neutral0};
          }
        }
        & > .p-multiselect-filter-icon {
          right: auto;
          left: 12px;
          font-size: 15px;
          transform: translateY(-50%);
          margin: 0;
        }
      }
      & > .p-multiselect-close {
        height: 32px;
        width: 32px;
        display: none;
      }
    }

    & > .p-multiselect-items-wrapper {
      ::-webkit-scrollbar-track {
        border-left: ${borders.borderSM} solid ${colourPalette.neutral300};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colourPalette.neutral300};
      }

      & > .p-multiselect-items.p-component {
        padding: ${padding.paddingXS};
        .p-multiselect-item {
          height: 40px;
          padding: ${padding.paddingInput} ${padding.paddingMD} ${padding.paddingInput} ${padding.paddingXS};
          border-radius: ${borderRadius.roundedSM};

          span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${colourPalette.neutral600};
            font-weight: ${fontWeight.regular};
          }

          &:focus,
          &:focus-visible,
          &:active {
            background-color: ${colourPalette.neutral0};
            box-shadow: 0px 0px 0px 2px ${hexToRGBA(colourPalette.blue600, 0.3)};
            position: relative;
            z-index: 1;
          }

          &.p-highlight {
            background-color: ${colourPalette.blue50};
            span {
              color: ${colourPalette.blue500};
            }
          }

          &:hover {
            background-color: ${colourPalette.neutral100};
            span {
              color: ${colourPalette.neutral600};
            }
          }
        }
        & > .p-multiselect-item-group {
          padding: ${padding.paddingXS};
        }
      }
    }

    .p-checkbox {
      width: 16px;
      height: 16px;
      box-shadow: none !important;
      & > .p-checkbox-box {
        width: 16px;
        height: 16px;
        border-radius: ${borderRadius.roundedXS};
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        background: ${colourPalette.neutral0};

        &.p-highlight {
          border-color: ${colourPalette.blue500};
          background: ${colourPalette.blue500};

          & > .p-checkbox-icon {
            width: 9px;
            height: 9px;
            path {
              fill: ${colourPalette.neutral0};
              stroke: ${colourPalette.neutral0};
            }
          }
        }

        &:hover {
          border-color: ${colourPalette.gray300} !important;
          background: ${colourPalette.neutral100};
          &.p-highlight {
            border-color: ${colourPalette.blue600} !important;
            background: ${colourPalette.blue600} !important;
          }
        }

        &:focus,
        &:active,
        &:focus-within,
        &:focus-visible {
          outline: ${borders.borderMD} solid ${colourPalette.neutral0};
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        }

        &.p-disabled {
          border-color: ${colourPalette.gray200};
          background: ${colourPalette.neutral100};
          opacity: 1;
          &.p-highlight {
            background: ${colourPalette.neutral100};
            border-color: ${colourPalette.neutral100};
          }
          & > .p-checkbox-icon {
            path {
              fill: ${colourPalette.neutral400};
              stroke: ${colourPalette.neutral400};
            }
          }
        }
      }
    }
  }
`;

export const PMultiSelectStyle = {
  MultiSelect: styled(MultiSelect)`
    &.p-multiselect {
      border-radius: ${borderRadius.roundedSM};
      border: ${borders.borderSM} solid ${colourPalette.gray200};
      background-color: ${colourPalette.neutral0};
      height: 40px;
      display: flex;

      .p-multiselect-label-container {
        display: flex;
        align-items: center;
        .p-multiselect-label {
          padding: 0 ${gap.gap4} 0 ${gap.gap3};
          max-height: fit-content;
          line-height: ${lineHeight.lineHeightMD};
          font-size: ${fontSize.fontSizeSM};
          font-family: 'SuisseIntl', sans-serif;
          color: ${colourPalette.neutral600} !important;
          &.p-placeholder {
            color: ${colourPalette.neutral400} !important;
          }
          &::placeholder {
            color: ${colourPalette.neutral400} !important;
          }
        }
      }

      .p-multiselect-clear-icon {
        width: 16px;
        height: 16px;
        path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }

      .p-multiselect-trigger {
        width: fit-content;
        padding: 0 ${gap.gap3} 0 ${gap.gap2};
        & > svg {
          width: 16px;
          height: 16px;
          path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }

      &:hover {
        border-color: ${colourPalette.gray300};
        background-color: ${colourPalette.neutral100};
      }
      &:focus,
      &.p-inputwrapper-focus,
      &:focus-within,
      &.p-invalid:focus-within {
        border-color: ${colourPalette.blue500};
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        background-color: ${colourPalette.neutral0};
      }

      &.p-invalid {
        border-color: ${colourPalette.rose500};
        .p-multiselect-label {
          color: ${colourPalette.rose600};
          &.p-placeholder,
          &::placeholder {
            color: ${colourPalette.rose600};
          }
        }
      }

      &.p-disabled {
        opacity: 1;
        background-color: ${colourPalette.neutral100};
        border-color: ${colourPalette.gray200};
        .p-multiselect-label {
          color: ${colourPalette.neutral300} !important;
          &.p-placeholder,
          &::placeholder {
            color: ${colourPalette.neutral300} !important;
          }
        }
        .p-multiselect-trigger > svg > path {
          &[fill] {
            fill: ${colourPalette.neutral300};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral300};
          }
        }
      }

      &.overlay-panel-visible {
        .p-multiselect-trigger {
          & > svg {
            transform: rotate(180deg);
          }
        }
      }

      &.has-value {
        .p-multiselect-label-container {
          padding-right: ${gap.gap2};
        }
      }
    }
  `,
};
