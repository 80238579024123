import styled, { css } from 'styled-components';
import { borders, colourPalette } from 'styles/styles';

export const DividerStyle = {
  Divider: styled.div<{ $props: any }>`
    ${({ $props }) => css`
      ${$props.orientation === 'vertical'
        ? css`
            width: ${$props.borderWidth ? (borders as any)[$props.borderWidth] : borders.borderSM};
            height: 100%;
            align-self: stretch;
          `
        : css`
            height: ${$props.borderWidth ? (borders as any)[$props.borderWidth] : borders.borderSM};
            width: 100%;
            align-self: stretch;
          `}
      background: ${$props.color ? (colourPalette as any)[$props.color] : colourPalette.gray200};
    `}
  `,
};
