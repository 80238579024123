import React from 'react';
import { FileUploadEmptyStateStyle as S } from './FileUploadEmptyState.style';
import { LinkText } from 'components/LinkText/LinkText';

export const FileUploadEmptyState: React.FC<{
  openFn: () => void;
  description: React.ReactNode;
  hideIcon?: boolean;
  multiple?: boolean;
}> = ({ openFn, description = '', hideIcon = false, multiple = false }) => {
  return (
    <S.Container className="file-upload-empty-state-container">
      {!hideIcon && <S.Icon />}
      <S.Title className="file-upload-empty-state-title">
        Drag and drop your file{multiple ? 's' : ''} here, or{' '}
        <LinkText onClick={openFn}>browse your computer</LinkText>
      </S.Title>
      <S.Description>{description}</S.Description>
    </S.Container>
  );
};
