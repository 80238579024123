import React, { useMemo } from 'react';
import { useObservableState } from 'observable-hooks';
import { HeyYabbleCountStep } from './components/HeyYabbleCountStep/HeyYabbleCountStep';
import { MapStep } from './components/MapStep/MapStep';
import { UploadStep } from './components/UploadStep/UploadStep';
import { ImportModalCsvContentStyle as S } from './ImportModalCsvContent.style';
import { useImportModalCsvBLoC } from '../../ImportModalCsv.bloc';
import { Loader } from 'components/Loader/Loader';

const stepComponents = {
  csv_received: MapStep,
  columns_mapped: HeyYabbleCountStep,
  themes_enabled: HeyYabbleCountStep,
  created: UploadStep,
};

export const ImportModalCsvContent: React.FC = () => {
  const { $step, $loading } = useImportModalCsvBLoC();
  const step = useObservableState($step);
  const loading = useObservableState($loading);
  const Component = useMemo(() => (step ? stepComponents[step] : UploadStep), [step]);

  return (
    <S.Container>
      {loading ? (
        <S.LoadingBox>
          <Loader />
        </S.LoadingBox>
      ) : (
        <Component />
      )}
    </S.Container>
  );
};
