import React, { useMemo } from 'react';
import { AmountInputStyle as S } from './AmountInput.style';
import { PInputNumberProps } from 'components/prime/PInputNumber/PInputNumber';
import { InputNumber } from 'primereact/inputnumber';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { ReactComponent as AlertCircleSolid } from 'assets/svg/alert-circle-solid.svg';

const getNewValue = (prev: number, step: number, operation: 'decrease' | 'increase' = 'decrease') => {
  if (operation === 'decrease') {
    return prev % step > 0 ? prev - (prev % step) : prev - step;
  } else {
    return prev % step > 0 ? prev + (step - (prev % step)) : prev + step;
  }
};

export const AmountInput = React.forwardRef<InputNumber, PInputNumberProps>((props, ref) => {
  const cleanProps = useMemo(() => (({ className, ...o }) => o)(props), [props]);

  return (
    <S.Container className={props.className}>
      <S.Input
        {...cleanProps}
        ref={ref}
        onValueChange={(e: any) => {
          props.onChange &&
            props.onChange({
              originalEvent: e.originalEvent as any,
              value: e.value as any,
            });
        }}
        onKeyDown={(e: any) => {
          if (e.code === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />
      {props.invalid && <AlertCircleSolid className="amount-input-invalid-icon" />}
      <S.ButtonGroup>
        <PButtonIcon
          size="xs"
          shape="squircle"
          icon="minusSolid"
          onClick={(e: any) => {
            const newValue = getNewValue(props.value || 0, props.step || 1);
            props.onChange &&
              props.onChange({
                originalEvent: e as any,
                value: props.min && newValue < props.min ? props.min : newValue,
              });
          }}
          disabled={props.disabled || (props.value || 0) <= (props.min || 0)}
        />
        <PButtonIcon
          size="xs"
          shape="squircle"
          icon="plusLine"
          onClick={(e: any) => {
            const newValue = getNewValue(props.value || 0, props.step || 1, 'increase');
            props.onChange &&
              props.onChange({
                originalEvent: e as any,
                value: props.max && newValue > props.max ? props.max : newValue,
              });
          }}
          disabled={props.disabled || (props.max ? (props.value || 0) >= props.max : false)}
        />
      </S.ButtonGroup>
    </S.Container>
  );
});
