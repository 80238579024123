import React, { PropsWithChildren } from 'react';
import { FullScreenModalStyle as S } from './FullScreenModal.style';

export const FullScreenModal: React.FC<
  PropsWithChildren<{
    onHide?: () => void;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    visible?: boolean;
    id?: string;
  }>
> = ({ onHide = () => {}, children, header, footer, visible, id }) => {
  return (
    <S.Dialog visible={visible === undefined ? true : visible} noHeader onHide={onHide} size="full" id={id}>
      <S.Wrapper>
        {header && <S.Header>{header}</S.Header>}
        <S.Content>{children}</S.Content>
        {footer && <S.Footer>{footer}</S.Footer>}
      </S.Wrapper>
    </S.Dialog>
  );
};
