import styled from 'styled-components';
import { colours, lineHeight } from 'styles/styles';

export const FullWidthTipStyle = {
  Container: styled.div`
    padding: 16px 24px;
    background-color: ${colours.neutralGrey01};
    border-radius: 6px;
    margin-bottom: 24px;
    header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      p {
        flex: 1 1 auto;
        margin: 0;
        padding: 0 8px;
        line-height: ${lineHeight.lineHeightMD};
        color: ${colours.neutralLightCharcoal};
      }
      svg {
        flex: 0 0 24px;
        width: 24px;
        &.close {
          cursor: pointer;
          path {
            fill: ${colours.neutralGrey05};
          }
          &:hover path {
            fill: ${colours.neutralLightCharcoal};
          }
        }
      }
    }
    section {
      p {
        margin: 0;
        color: ${colours.neutralBody};
        line-height: ${lineHeight.lineHeightMD};
      }
    }
  `,
};
