import React, { useEffect, useRef } from 'react';
import {
  SnackbarV2,
  SnackbarV2MessageCustom,
  SnackbarV2MessageCustomProps,
} from 'components/SnackbarV2/SnackbarV2';

let snackbarV2Ref: React.RefObject<any> | null = null;

export const SnackbarV2Service: React.FC = () => {
  const ref = useRef<typeof SnackbarV2>(null);
  useEffect(() => {
    snackbarV2Ref = ref;
  }, []);
  return <SnackbarV2 ref={ref as any} />;
};

export const snackbarV2 = (
  props: Omit<SnackbarV2MessageCustomProps, 'onDismiss'> & {
    life?: number;
  }
) => {
  snackbarV2Ref?.current?.replace({
    content: <SnackbarV2MessageCustom {...props} onDismiss={() => snackbarV2Ref?.current?.clear()} />,
    life: props.life || 4000,
  });
};
