import styled from 'styled-components';
import { colourPalette, fontSize, gap, lineHeight } from 'styles/styles';

const directionToFlex = (direction: string) => {
  switch (direction) {
    case 'bottom':
      return 'column-reverse';
    case 'left':
      return 'row';
    case 'right':
      return 'row-reverse';
    default:
      return 'column';
  }
};

/**
 * @deprecated Use FormField instead
 */
export const PFormFieldStyle = {
  Label: styled.label<{ disabled?: boolean }>`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 500;
    color: ${colourPalette.neutral400};
    margin-bottom: ${gap.gap2};
    display: flex;
    align-items: center;
    gap: ${gap.gap0};
    .is-disabled & {
      color: ${colourPalette.neutral300};
    }
    svg[class^='tooltip-icon'] {
      margin-left: auto;
    }
  `,
  Container: styled.div<{ direction: string }>`
    display: flex;
    flex-direction: ${({ direction }) => directionToFlex(direction)};
    &.hidden {
      visibility: none;
      max-height: 0px !important;
      max-width: 0px !important;
      overflow: hidden;
      padding: 0px;
      margin: 0px;
    }
  `,
  Required: styled.span`
    color: ${colourPalette.neutral400};
    font-weight: 600;
    .is-disabled & {
      color: ${colourPalette.neutral300};
    }
  `,
  Optional: styled.span`
    color: ${colourPalette.neutral400};
    .is-disabled & {
      color: ${colourPalette.neutral300};
    }
  `,
  Value: styled.div`
    min-height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: italic;
  `,
  SubLabel: styled.div`
    color: ${colourPalette.neutral400};
    font-size: ${fontSize.fontSizeSM};
    margin-bottom: 8px;
    line-height: ${lineHeight.lineHeightMD};
    .is-disabled & {
      color: ${colourPalette.neutral300};
    }
  `,
  HelpText: styled.div`
    margin-top: ${gap.gap2};
    display: flex;
    justify-content: space-between;
    gap: ${gap.gap5};
    p {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: ${colourPalette.neutral400};
      &.help-text {
        flex: 1 1 auto;
      }
      &.char-limit-counter {
        flex: 0 0 fit-content;
      }
    }
    .is-disabled & > p {
      color: ${colourPalette.neutral300};
    }
  `,
};

export const PFormFieldLabel = PFormFieldStyle.Label;
