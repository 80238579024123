import React from 'react';
import { NotificationServiceBLoC as BLoC } from './NotificationService.bloc';
import { NotificationPopup } from './components/NotificationPopup/NotificationPopup';

export const NotificationService: React.FC = () => {
  return (
    <BLoC>
      <NotificationPopup />
    </BLoC>
  );
};
