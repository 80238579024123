import React from 'react';
import { HeyYabbleCountStepStyle as S } from './HeyYabbleCountStep.style';
import { HeyYabbleCountStepBLoC as BLoC } from './HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepFooter } from './components/HeyYabbleCountStepFooter/HeyYabbleCountStepFooter';
import { HeyYabbleCountStepContent } from './components/HeyYabbleCountStepContent/HeyYabbleCountStepContent';
import { ImportModalExitPrompt } from 'components/modals/ImportModal/components/ImportModalExitPrompt/ImportModalExitPrompt';
import { useIntercomHidden } from 'hooks/useIntercomHide';

export const HeyYabbleCountStep: React.FC = () => {
  useIntercomHidden();
  return (
    <BLoC>
      <ImportModalExitPrompt />
      <S.Container>
        <HeyYabbleCountStepContent />
        <HeyYabbleCountStepFooter />
      </S.Container>
    </BLoC>
  );
};
