import React, { useMemo } from 'react';
import { PaginatorProps, PaginatorRowsPerPageDropdownOptions } from 'primereact/paginator';
import { PPaginatorStyle as S } from './PPaginator.style';
import { PDropdown } from '../PDropdown/PDropdown';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { Box } from 'components/layouts/primitives/Box/Box';
import { classNames } from 'primereact/utils';

export type PPaginatorProps = {
  first: PaginatorProps['first'];
  rows: PaginatorProps['first'];
  totalRecords: PaginatorProps['totalRecords'];
  onPageChange: PaginatorProps['onPageChange'];
  type?: 'compact' | 'default';
  size?: 'lg' | 'md';
  alignment?: 'start' | 'center' | 'end';
  className?: string;
};

export const PAGINATOR_PAGE_REPORT_TEMPLATE = '{first} - {last} of {totalRecords}';
export const PAGINATOR_PAGE_LINK_SIZE = 5;
export const PAGINATOR_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const paginatorPageSizeDropdownGetter = (opts: PaginatorRowsPerPageDropdownOptions) => {
  return (
    <Box
      padding={['paddingNone', 'paddingNone', 'paddingNone', 'paddingSM']}
      className="ppaginator-page-size-dropdown-container"
    >
      <HStack gap="gap2" align="center">
        <Text size="fontSizeXS" lineHeight="lineHeightSM" color="neutral500">
          ·{' '}
        </Text>
        <Text size="fontSizeXS" lineHeight="lineHeightSM" color="neutral500">
          Items per page
        </Text>
        <PDropdown
          value={opts.value}
          options={opts.options}
          disabled={opts.disabled}
          onChange={opts.onChange}
        />
      </HStack>
    </Box>
  );
};

export const paginatorTemplateGetter = (type?: PPaginatorProps['type']) =>
  `${
    type !== 'compact' ? 'CurrentPageReport RowsPerPageDropdown ' : ''
  }FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink`;

export const paginatorClassNameGetter = (
  opts: Pick<PPaginatorProps, 'size' | 'alignment'>,
  className: string = ''
) => {
  return (
    className +
    ' ' +
    classNames({
      [`ppaginator-size-${opts.size || 'md'}`]: true,
      [`ppaginator-alignment-${opts.alignment || 'end'}`]: true,
    })
  );
};

export const PPaginator: React.FC<PPaginatorProps> = (props) => {
  const cleanProps = useMemo(() => (({ type, size, ...o }) => o)(props), [props]);
  const className = useMemo(
    () => paginatorClassNameGetter({ size: props.size, alignment: props.alignment }, props.className),
    [props]
  );
  return (
    <S.Paginator
      pageLinkSize={PAGINATOR_PAGE_LINK_SIZE}
      rowsPerPageOptions={PAGINATOR_PAGE_SIZE_OPTIONS}
      currentPageReportTemplate={PAGINATOR_PAGE_REPORT_TEMPLATE}
      className={className}
      template={{
        layout: paginatorTemplateGetter(props.type),
        RowsPerPageDropdown: paginatorPageSizeDropdownGetter,
      }}
      {...cleanProps}
    />
  );
};
