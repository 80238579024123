import React, { Suspense } from 'react';
import { useObservableState } from 'observable-hooks';
import { getUserStore } from 'stores/user.store';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getAnalyticsStore } from 'stores/analytics.store';
import { tokens } from 'services/tokens';
import { lazyRefresh } from 'utils/lazyRefresh';

// Lazy loaded modules
const PublicModule = lazyRefresh(() => import('./modules/PublicModule/PublicModule'));
const Onboarding = lazyRefresh(() => import('./modules/OnboardingModule/OnboardingModule'));
const MainModule = lazyRefresh(() => import('./modules/MainModule/MainModule'));
const HeyYabbleModule = lazyRefresh(() => import('./modules/HeyYabbleModule/HeyYabbleModule'));
const AdminModule = lazyRefresh(() => import('./modules/AdminModule/AdminModule'));
const WidgetTestModule = lazyRefresh(() => import('./modules/widgets/WidgetTestModule'));
const AdminWidgetModule = lazyRefresh(() => import('./modules/admin-widgets/AdminWidgetModule'));
const SettingsModule = lazyRefresh(() => import('./modules/SettingsModule/SettingsModule'));

// init analytics
getAnalyticsStore();

const App: React.FC = () => {
  const token = useObservableState(tokens.$accessToken, 'loading');
  const user = useObservableState(getUserStore().$user);
  const userState = useObservableState(getUserStore().$userState, 'loading');
  const onboardingStatus = useObservableState(getUserStore().$onboardingStatus);

  if (userState === 'loading' || token === 'loading') {
    return (
      <div className="App">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="App">
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {import.meta.env.DEV && <Route path="/widget-test/*" element={<WidgetTestModule />} />}
          {import.meta.env.DEV && <Route path="/admin-widget-test/*" element={<AdminWidgetModule />} />}
          {/* no access past public module if unauthed */}
          {userState === 'unauthorized' && <Route path="/*" element={<PublicModule />} />}
          {userState === 'authorized' && <Route path="/onboarding/*" element={<Onboarding />} />}
          {userState === 'authorized' &&
            onboardingStatus &&
            ['profile-complete', 'onboarded'].includes(onboardingStatus || '') && [
              <Route path="/settings/*" key="settings" element={<SettingsModule />} />,
              <Route path="/hey-yabble/*" key="hy" element={<HeyYabbleModule />} />,
              // <Route path="/*" key="main" element={<MainModule />} />,
            ]}
          {userState === 'authorized' && (!user || user?.isYabbleOwner) && (
            <Route path="/admin/v2/*" key="admin" element={<AdminModule />} />
          )}
          {userState === 'authorized' &&
            onboardingStatus &&
            !['profile-complete', 'onboarded'].includes(onboardingStatus || '') && (
              <Route path="/*" key="redirect" element={<Navigate to="/onboarding" />} />
            )}
          <Route path="/*" key={'mm'} element={<MainModule />} />,
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
