import React from 'react';
import { ImportModalInvalidDeviceStyle as S } from './ImportModalInvalidDevice.style';

export const ImportModalInvalidDevice: React.FC = () => {
  return (
    <S.Container>
      <S.Text>Please use a device with a larger screen.</S.Text>
    </S.Container>
  );
};
