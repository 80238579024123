import { useCreditsModalBLoC } from 'components/modals/CreditsModal/CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { CreditsModalActionButtons } from '../../../CreditsModalActionButtons/CreditsModalActionButtons';
import { CreditsModalTopupFormAmountInput } from './components/CreditsModalTopupFormAmountInput/CreditsModalTopupFormAmountInput';
import { CreditsModalTopupFormBalanceAfter } from './components/CreditsModalTopupFormBalanceAfter/CreditsModalTopupFormBalanceAfter';
import { CreditsModalTopupFormTerms } from './components/CreditsModalTopupFormTerms/CreditsModalTopupFormTerms';
import { CreditsModalTopupFormTotalToPay } from './components/CreditsModalTopupFormTotalToPay/CreditsModalTopupFormTotalToPay';
import { CreditsModalTopupFormStyle as S } from './CreditsModalTopupForm.style';

export const CreditsModalTopupForm: React.FC = () => {
  const { $mode } = useCreditsModalBLoC();
  const mode = useObservableState($mode, '');
  return mode === 'credit-request' || mode === 'credit-spend' || mode === 'credit-request-approval' ? (
    <S.ButtonsBox>
      <CreditsModalActionButtons />
    </S.ButtonsBox>
  ) : (
    <>
      <S.Title>Top-up credits</S.Title>
      <S.Container>
        <CreditsModalTopupFormAmountInput />
        <CreditsModalTopupFormBalanceAfter />
        <CreditsModalTopupFormTotalToPay />
      </S.Container>
      <CreditsModalTopupFormTerms />
      <S.FormButtonsBox>
        <CreditsModalActionButtons />
      </S.FormButtonsBox>
    </>
  );
};
