import React from 'react';

export const useUserInactivity = (
  onIdle: () => void,
  onActive: () => void,
  options: { idleTime: number } = { idleTime: 20 * 1000 }
) => {
  const idle = useIdle(options.idleTime);

  React.useEffect(() => {
    if (idle) onIdle();
    else onActive();
  }, [idle, onIdle, onActive]);
};

function throttle(cb: () => void, ms: number) {
  let lastTime = 0;
  return () => {
    const now = Date.now();
    if (now - lastTime >= ms) {
      cb();
      lastTime = now;
    }
  };
}

/**
 * @name useIdle
 * @author https://usehooks.com/useidle
 */
function useIdle(ms: number) {
  const [idle, setIdle] = React.useState(false);

  React.useEffect(() => {
    let timeoutId: number | undefined;

    const handleTimeout = () => {
      setIdle(true);
    };

    const handleEvent = throttle(() => {
      setIdle(false);

      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(handleTimeout, ms);
    }, 500);

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleEvent();
      }
    };

    timeoutId = window.setTimeout(handleTimeout, ms);

    window.addEventListener('mousemove', handleEvent);
    window.addEventListener('mousedown', handleEvent);
    window.addEventListener('resize', handleEvent);
    window.addEventListener('keydown', handleEvent);
    window.addEventListener('touchstart', handleEvent);
    window.addEventListener('wheel', handleEvent);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('mousemove', handleEvent);
      window.removeEventListener('mousedown', handleEvent);
      window.removeEventListener('resize', handleEvent);
      window.removeEventListener('keydown', handleEvent);
      window.removeEventListener('touchstart', handleEvent);
      window.removeEventListener('wheel', handleEvent);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.clearTimeout(timeoutId);
    };
  }, [ms]);

  return idle;
}
