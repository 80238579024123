import React from 'react';
import { HeyYabbleCountStepBannerStyle as S } from './HeyYabbleCountStepBanner.style';

export const HeyYabbleCountStepBanner: React.FC = () => {
  return (
    <S.Container>
      <h4>Important: Understand how Count works</h4>
      <ul>
        <li>
          We will run Count on all data (existing and new) for selected columns. Credits will be consumed for
          new data only.
        </li>
        <li>Columns with fewer than 10 responses cannot be counted.</li>
      </ul>
    </S.Container>
  );
};
