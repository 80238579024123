import styled from 'styled-components';
import { colours } from 'styles/styles';

export const ImportModalHeaderStyle = {
  Container: styled.div`
    display: flex;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 72px;
    border-bottom: 1px solid ${colours.neutralGrey03};
  `,
  Box: styled.div`
    display: flex;
    width: 246px;
    justify-content: flex-end;
    align-items: center;
  `,
  BreadCrumbContainer: styled.div`
    /* margin-top: 10px; */
    .p-menuitem-link {
      pointer-events: none;
    }
    .active .p-menuitem-text {
      font-weight: 500;
    }
  `,
  Title: styled.span`
    font-size: 20px !important;
    font-weight: 500;
  `,
  Subtitle: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 26px;
    display: flex;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 12px;
      color: ${colours.neutralBody};
    }
  `,
  Divider: styled.div`
    height: 40px;
    border-left: 1px solid ${colours.neutralGrey03};
    margin: 0 16px;
  `,
  NameBox: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    &.has-subtitle {
      margin-top: -16px;
    }
  `,
};
