import React from 'react';
import { usePersonaLibraryModalBLoC } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.bloc';
import { useObservableState } from 'observable-hooks';
import { VStack } from '../../../../../../layouts/primitives/Stack/Stack';
import { DetailsPanelPersonasPersona } from './components/DetailsPanelPersonasPersona/DetailsPanelPersonasPersona';

export const DetailsPanelPersonas: React.FC = () => {
  const { $selectedGroup } = usePersonaLibraryModalBLoC();
  const selectedGroup = useObservableState($selectedGroup);
  return (
    <VStack gap="gap4">
      {selectedGroup?.personas?.map((p) => <DetailsPanelPersonasPersona key={p.id} persona={p} />)}
    </VStack>
  );
};
