import React from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { TitleRenameFormStyle as S } from './TitleRenameForm.style';
import { useFormState } from 'react-hook-form';
import { useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { PButton } from 'components/prime/PButton/PButton';

export const TitleRenameForm: React.FC = () => {
  const { $loading, renameForm, setRenameMode, renameFilter } = useSavedFiltersModalBLoC();
  const loading = useObservableState($loading.pipe(map((l) => l === 'renaming')));
  const formState = useFormState(renameForm);

  return (
    <S.Form onSubmit={renameForm.handleSubmit(renameFilter)}>
      <S.FormField
        form={renameForm}
        controler={{
          name: 'name',
          rules: {
            required: 'Name is required',
            maxLength: 250,
          },
        }}
        plainLabel
      >
        {({ field, fieldState }) => (
          <S.Input {...field} invalid={fieldState.invalid} maxLength={250} autoFocus disabled={loading} />
        )}
      </S.FormField>
      <S.Actions>
        <PButton
          label="Cancel"
          severity="secondary"
          size="sm"
          onClick={() => setRenameMode(false)}
          disabled={loading}
        />
        <PButton size="sm" label="Save" type="submit" loading={loading} disabled={!formState.isValid} />
      </S.Actions>
    </S.Form>
  );
};
