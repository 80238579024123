import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useClassNames } from 'hooks/useClassNames';
import React from 'react';
import { MapStepColumnData } from './components/MapStepColumnData/MapStepColumnData';
import { MapStepColumnHeader } from './components/MapStepColumnHeader/MapStepColumnHeader';
import { MapStepColumnMapTo } from './components/MapStepColumnMapTo/MapStepColumnMapTo';
import { MapStepColumnMessages } from './components/MapStepColumnMessages/MapStepColumnMessages';
import { MapStepColumnMonthFirst } from './components/MapStepColumnMonthFirst/MapStepColumnMonthFirst';
import { MapStepColumnStyle as S } from './MapStepColumn.style';

export const MapStepColumn: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({ column }) => {
  const containerClassNames = useClassNames({
    'column-ignored': column.ignoreColumn,
  });
  return (
    <S.Container className={containerClassNames} id={`column-${column.id}`}>
      <S.Box>
        <MapStepColumnHeader {...{ column }} />
        <MapStepColumnMonthFirst {...{ column }} />
        <MapStepColumnMapTo {...{ column }} />
        <MapStepColumnData {...{ column }} />
      </S.Box>
      <MapStepColumnMessages {...{ column }} />
    </S.Container>
  );
};
