import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { $post } from './api';
import { differenceInMinutes } from 'date-fns';
import { getUserStore } from 'stores/user.store';
import { useUserInactivity } from 'hooks/useUserInactivity';

class Tokens {
  private readonly AUTO_LOGOUT_TARGET_MINUTES = 120; // 2 hours
  private isTokenRefreshTimerInitialized = false;
  private _accessToken = new BehaviorSubject<string | 'loading' | null>('loading');
  public $accessToken = this._accessToken.asObservable();
  public get accessToken(): string | null | undefined {
    return this._accessToken.value;
  }
  private _csrfToken = new BehaviorSubject<string | null>(
    import.meta.env.DEV ? import.meta.env.REACT_APP_ACCESS_TOKEN || null : null
  );
  public $csrfToken = this._csrfToken.asObservable();
  public get csrfToken(): string | null {
    return this._csrfToken.value;
  }
  private $userInactivityTimerStart = new Subject();
  private $userInactivityTimerCancel = new Subject();
  private $userInactivityTimer = this.$userInactivityTimerStart.pipe(
    switchMap(() =>
      timer(0, 1000 * 60).pipe(
        tap((minutes) => {
          if (minutes >= this.AUTO_LOGOUT_TARGET_MINUTES) {
            getUserStore().logout();
          }
        }),
        takeUntil(this.$userInactivityTimerCancel)
      )
    )
  );

  public setTokens = ({ accessToken, csrfToken }: { accessToken?: string; csrfToken?: string }) => {
    this._accessToken.next(accessToken || null);
    csrfToken && this._csrfToken.next(csrfToken || null);
    if (process.env.NODE_ENV === 'production' && accessToken && !this.isTokenRefreshTimerInitialized) {
      this.initTokenRefreshTimer();
    }
  };

  public cancelUserInactivityTimer = () => {
    if (this.isTokenRefreshTimerInitialized) this.$userInactivityTimerCancel.next(0);
  };

  public startUserInactivityTimer = () => {
    if (this.isTokenRefreshTimerInitialized) this.$userInactivityTimerStart.next(0);
  };

  /**
   * IMPORTANT:
   * Each 5 minutes checks expiry of current access token
   * If less than or equal to 10 minutes then get a refreshed token
   * To avoid interrupting user session.
   * @see https://thethinkingstudio.atlassian.net/browse/CE-9534
   */
  private initTokenRefreshTimer = () => {
    // Check if token is the temporary for 2fa flow (before user validates 2fa code)
    if (this.isTemporaryToken()) return;

    this.isTokenRefreshTimerInitialized = true;
    timer(0, 1000 * 60 * 5).subscribe(() => {
      const tokenExpiry = this.getAccessTokenExpiry();
      if (differenceInMinutes(tokenExpiry || 0, Date.now()) <= 10) {
        $post<{ accessToken: string }>('fresh-access-token', {
          _token: this.csrfToken,
        }).subscribe((d) => {
          this.setTokens({ accessToken: d.accessToken });
        });
      }
    });

    /**
     * Subscribe to user inactivity timer to start
     * listening to "start" and "cancel" events
     */
    this.$userInactivityTimer.subscribe();
  };

  private getAccessTokenExpiry = () => {
    if (!this.accessToken) return null;
    const jwt: any = jwtDecode(this.accessToken || '');
    return Math.floor(jwt.exp * 1000);
  };

  private isTemporaryToken = () => {
    const tokenExpiry = this.getAccessTokenExpiry();
    return differenceInMinutes(tokenExpiry || 0, Date.now()) <= 10;
  };

  constructor() {
    if (import.meta.env.DEV) {
      setTimeout(() => this._accessToken.next(import.meta.env.REACT_APP_ACCESS_TOKEN || null));
    }
  }
}

export const tokens = new Tokens();

export const UserInactivityTimer: React.FC = () => {
  useUserInactivity(tokens.startUserInactivityTimer, tokens.cancelUserInactivityTimer);
  return null;
};
