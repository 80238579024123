import React from 'react';
import { StackStyle as S } from './Stack.style';
import { gap } from 'styles/styles';

export type StackProps = {
  gap?: keyof typeof gap;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  inline?: boolean;
  wrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
  splitAfterIdx?: number;
  stretch?: boolean;
  fit?: 'content' | 'space';
  noAutoMinSize?:
    | 'width'
    | 'height'
    | 'both' /** @see https://www.bigbinary.com/blog/understanding-the-automatic-minimum-size-of-flex-items */;
  testId?: string;
  children?: React.ReactNode;
  className?: string;
};

export const Stack: React.FC<StackProps> = ({ children, className, testId, ...props }) => {
  return (
    <S.Stack $props={props} className={className} data-testid={testId}>
      {children}
    </S.Stack>
  );
};

export const VStack: React.FC<Omit<StackProps, 'direction'>> = (props) => {
  return <Stack {...props} direction="column" />;
};

export const HStack: React.FC<Omit<StackProps, 'direction'>> = (props) => {
  return <Stack {...props} direction="row" />;
};
