import React from 'react';
import { useObservableState } from 'observable-hooks';
import { Search } from 'components/Search/Search';
import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { PPaginator } from 'components/prime/PPaginator/PPaginator';
import { usePersonaLibraryModalBLoC } from '../../PersonaLibraryModal.bloc';
import { ListPanelGroupItem } from './components/ListPanelGroupItem/ListPanelGroupItem';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';
import { Text } from 'components/Text/Text';
import { ListPanelEmptyState } from './components/ListPanelEmptyState/ListPanelEmptyState';

export const ListPanel: React.FC = () => {
  const { $groups, $meta, paginate, search } = usePersonaLibraryModalBLoC();
  const groups = useObservableState($groups);
  const meta = useObservableState($meta);

  return (
    <VStack stretch {...(groups ? { splitAfterIdx: 1 } : {})}>
      <Box backgroundColor="neutral0" padding={['paddingLG', 'paddingXL']} className="sticky top-0 z-1">
        <Search onChange={(e) => search(e.target.value)} debounceTime={300} />
      </Box>
      <Box stretch padding={['paddingNone', 'paddingXL', 'paddingNone']}>
        <VStack stretch gap="gap2">
          <Box padding="paddingSM">
            <Text as="p" weight="medium" size="fontSizeXS" lineHeight="lineHeightMD" color="neutral400">
              Persona groups
            </Text>
          </Box>
          {!groups ? (
            Array.from({ length: 8 }).map((_v, idx) => (
              <Box key={idx} padding={['paddingSM']}>
                <PSkeleton height="16px" width="100%" borderRadius="12px" />
              </Box>
            ))
          ) : groups?.length ? (
            <VStack>
              {groups.map((g, idx) => (
                <ListPanelGroupItem group={g} key={g?.id || idx} />
              ))}
            </VStack>
          ) : (
            <ListPanelEmptyState />
          )}
        </VStack>
      </Box>
      <Box
        backgroundColor="neutral0"
        padding={['paddingMD', 'paddingXL', 'padding2XL']}
        className="sticky bottom-0 z-1"
      >
        {!!meta?.total && (
          <HStack justify="center">
            <PPaginator
              totalRecords={meta?.total || 0}
              first={meta?.from || 1}
              rows={meta?.perPage || 10}
              onPageChange={(e) => paginate(e.page + 1)}
              alignment="center"
              type="compact"
            />
          </HStack>
        )}
      </Box>
    </VStack>
  );
};
