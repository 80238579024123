import React, { useCallback, useRef, useState } from 'react';
import { DropdownUserStyle as S } from './DropdownUser.style';
import { User } from 'types/User';
import { MenuItem } from 'primereact/menuitem';
import { UserIcon } from 'components/UserIcon/UserIcon';
import { ReactComponent as ChevronDownSvg } from 'assets/svg/chevron-down.svg';
import { Menu } from 'primereact/menu';

export const DropdownUser: React.FC<{ user?: User | null; links: MenuItem[] }> = ({ user, links }) => {
  const ref = useRef<Menu>(null);
  const [togglerIsActive, setTogglerIsActive] = useState(false);

  const handleMenuToggle = useCallback(() => setTogglerIsActive((prevState) => !prevState), []);

  return (
    <S.Container>
      <S.Toggler
        data-testid="button-top-bar-user"
        onClick={(event: any) => ref?.current?.toggle(event)}
        $isActive={togglerIsActive}
      >
        <UserIcon user={{ firstName: user?.firstName, lastName: user?.lastName } as User} />
        <ChevronDownSvg />
      </S.Toggler>
      <S.Menu model={links} ref={ref as any} onShow={handleMenuToggle} onHide={handleMenuToggle} popup />
    </S.Container>
  );
};
