import React, { PropsWithChildren } from 'react';
import { DropdownMenuItemStyle as S } from './DropdownMenuItem.style';
import { ReactComponent as CheckSvg } from 'assets/svg/checkmark.svg';

export const DropdownMenuItem: React.FC<
  PropsWithChildren<{
    label?: string;
    isMuted?: boolean;
    withTitleAttr?: boolean;
    variableHeight?: boolean;
    showSelectedIcon?: boolean;
    onClick?: (event?: any) => void;
    href?: string;
    className?: string;
  }>
> = ({
  label = '',
  onClick = () => {},
  href,
  isMuted,
  withTitleAttr,
  variableHeight,
  showSelectedIcon,
  className,
  children,
}) => {
  const titleAttr = withTitleAttr ? { title: label } : {};

  return (
    <S.Container
      className={className}
      onClick={onClick}
      $isMuted={isMuted}
      $variableHeight={variableHeight}
      href={href}
      {...titleAttr}
    >
      {children ? children : <span>{label}</span>}
      {showSelectedIcon && <CheckSvg />}
    </S.Container>
  );
};
