import styled, { css } from 'styled-components';
import { Paginator } from 'primereact/paginator';
import { borderRadius, colourPalette, fontSize, hexToRGBA, lineHeight, padding } from 'styles/styles';
import { PDropdownBaseStyle } from '../PDropdown/PDropdown.style';

export const PaginatorBaseStyle = css`
  padding: 0;
  border: none;
  min-height: 40px;

  .p-paginator-current {
    height: auto;
    padding: 0;
    margin: 0;
    color: ${colourPalette.neutral500};
    font-size: ${fontSize.fontSizeXS};
    line-height: ${lineHeight.lineHeightSM};
  }

  .ppaginator-page-size-dropdown-container {
    margin-right: auto;
  }

  .p-paginator-element {
    font-family: 'SuisseIntl', sans-serif;
    min-width: 30px;
    height: 32px;
    background-color: transparent;
    color: ${colourPalette.neutral600} !important;
    font-size: ${fontSize.fontSizeXS};
    line-height: ${lineHeight.lineHeightSM};
    padding: ${padding.paddingNone} ${padding.paddingSM};
    margin: 0;
    border-radius: ${borderRadius.roundedXS};

    .p-paginator-icon {
      width: 14px !important;
      height: 14px !important;
      path {
        fill: ${colourPalette.neutral600};
      }
    }

    &:hover {
      background-color: ${colourPalette.neutral100};
    }

    &:active {
      background-color: transparent !important;
    }

    &:focus {
      background-color: ${colourPalette.neutral0} !important;
      box-shadow: 0px 0px 0px 2px ${hexToRGBA(colourPalette.blue600, 0.3)};
    }

    &.p-highlight {
      background-color: ${colourPalette.blue50};
      color: ${colourPalette.blue500} !important;

      &:hover {
        background-color: ${colourPalette.blue200} !important;
      }

      &:active {
        background-color: ${colourPalette.blue50} !important;
        color: ${colourPalette.neutral600} !important;
      }

      &:focus {
        background-color: ${colourPalette.blue50} !important;
        color: ${colourPalette.blue500} !important;
        box-shadow: 0px 0px 0px 2px ${hexToRGBA(colourPalette.blue600, 0.3)};
      }
    }

    &.p-disabled {
      opacity: 1;
      color: ${colourPalette.neutral300} !important;
      background-color: transparent !important;
      .p-paginator-icon {
        path {
          fill: ${colourPalette.neutral300} !important;
        }
      }
    }
  }

  .p-dropdown {
    height: auto;
    ${PDropdownBaseStyle}
  }

  &.ppaginator-size-lg {
    min-height: 48px;
  }

  &.ppaginator-alignment-start {
    justify-content: flex-start;
  }
  &.ppaginator-alignment-center {
    justify-content: center;
  }
`;

export const PPaginatorStyle = {
  Paginator: styled(Paginator)`
    &.p-paginator {
      ${PaginatorBaseStyle}
    }
  `,
};
