import React from 'react';
import { MapStepStyle as S } from './MapStep.style';
import { MapStepBLoC as BLoC } from './MapStep.bloc';
import { MapStepFooter } from './components/MapStepFooter/MapStepFooter';
import { MapStepContent } from './components/MapStepContent/MapStepContent';
import { MapStepChangeResponseModal } from './components/MapStepChangeResponseModal/MapStepChangeResponseModal';
import { ImportModalExitPrompt } from 'components/modals/ImportModal/components/ImportModalExitPrompt/ImportModalExitPrompt';
import { MapStepDataTypesModal } from './components/MapStepDataTypesModal/MapStepDataTypesModal';
import { MapStepBar } from './components/MapStepBar/MapStepBar';

export const MapStep: React.FC = () => {
  return (
    <BLoC>
      <S.Container>
        <ImportModalExitPrompt />
        <MapStepBar />
        <MapStepContent />
        <MapStepFooter />
        <MapStepDataTypesModal />
        <MapStepChangeResponseModal />
      </S.Container>
    </BLoC>
  );
};
