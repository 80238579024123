import { keyframes } from 'styled-components';

export const LoaderAnimations = {
  loaderMainCircleAnimationYAxis: keyframes`
    0% {
    transform: translateY(0);
    animation-timing-function: linear;
    }
    15%, 31% {
    transform: translateY(-15px);
    animation-timing-function: linear;
    }
    35%, 45% {
    transform: translateY(-9px);
    animation-timing-function: cubic-bezier(0.86, 0.01, 0.63, 0.62);
    }
    60% {
    transform: translateY(100px);
    animation-timing-function: cubic-bezier(0.58, 0.58, 0.61, 1);
    }
    75% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.4, 0, 0.42, 0.42);
    }
    100% {
    animation-timing-function: cubic-bezier(0.38, 0.37, 0.53, 1);
    }
`,

  loaderMainRectangleAnimation: keyframes`
      0% {
        transform: rotate(0deg);
        animation-timing-function: linear;
      }
      15%,
      20% {
        transform: rotate(45deg);
        animation-timing-function: linear;
      }
      35%,
      45% {
        transform: rotate(190deg);
        animation-timing-function: cubic-bezier(0.38, 0.37, 0.53, 1);
      }
      55%,
      56% {
        transform: rotate(115deg);
        animation-timing-function: ease-in-out;
      }
      70%,
      100% {
        transform: rotate(180deg);
        animation-timing-function: ease-in-out;
      }
`,

  loaderMainCircleAnimationXAxis: keyframes`
      0% {
        transform: translateX(0);
        animation-timing-function: linear;
      }
      15%,
      31% {
        transform: translateX(43px);
        animation-timing-function: linear;
      }
      35%,
      45% {
        transform: translateX(78px);
        animation-timing-function: cubic-bezier(0.38, 0.37, 0.53, 1);
      }
      60% {
        transform: translateX(-60px);
        animation-timing-function: cubic-bezier(0.4, 0, 0.42, 0.42);
      }
      75% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.58, 0.58, 0.61, 1);
      }
      100% {
        animation-timing-function: cubic-bezier(0.38, 0.37, 0.53, 1);
      }
`,
};
