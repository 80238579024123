import React, { useRef, useState } from 'react';
import { MapStepBarDataTypesButtonStyle as S } from './MapStepBarDataTypesButton.style';
import { useClickOutside } from 'hooks/useClickOutside';
import { MapStepDataTypeData } from '../../../MapStepDataTypeData/MapStepDataTypeData';
import { ReactComponent as QuestionSvg } from 'assets/svg/help.svg';

export const MapStepBarDataTypesButton: React.FC = () => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  useClickOutside(containerRef, () => setOpen(false));

  return (
    <S.Container ref={containerRef}>
      <S.Button onClick={() => setOpen(!open)} open={open}>
        <QuestionSvg />
        <S.ButtonTex>What are data types?</S.ButtonTex>
      </S.Button>
      {open && (
        <S.Box>
          <MapStepDataTypeData />
        </S.Box>
      )}
    </S.Container>
  );
};
