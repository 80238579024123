import React from 'react';
import { ListPanelFiltersEmptyStateStyle as S } from './ListPanelFiltersEmptyState.style';
import { useObservableState } from 'observable-hooks';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';

export const ListPanelFiltersEmptyState: React.FC = () => {
  const { $projectHasNoFilters } = useSavedFiltersModalBLoC();
  const projectHasNoFilters = useObservableState($projectHasNoFilters);

  return (
    <S.Container data-testid="box-saved-filters-empty">
      <S.Title>
        {projectHasNoFilters
          ? `You haven't saved any filters yet. Create your custom filters and save them for quicker access`
          : 'Select a project from the dropdown to see all saved filters'}
      </S.Title>
    </S.Container>
  );
};
