import styled from 'styled-components';
import { colours } from 'styles/styles';

export const DetailsPanelContentConditionsListStyle = {
  Container: styled.div`
    margin-top: 16px;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid ${colours.neutralGrey03};
  `,
  Title: styled.p`
    margin: 0;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    color: ${colours.neutralBody};
    text-transform: uppercase;
    margin-bottom: 16px;
  `,
};
