import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, IBLoCInitialisable, renderBlocChild } from 'types/BLoCBase';
import { ImportModalTypes } from './ImportModal.types';

type State = {
  importFlowSelected?: ImportModalTypes.ImportModalFlow;
  preSelectedIngestionDatatype?: number;
};

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $importFlowSelected = this.$getState('importFlowSelected');
  public $preSelectedIngestionDatatype = this.$getState('preSelectedIngestionDatatype');

  constructor(
    public onClose: ImportModalTypes.ImportModalProps['onClose'],
    public ingestionId: ImportModalTypes.ImportModalProps['ingestionId'],
    public project: ImportModalTypes.ImportModalProps['project'],
    public preselectType: ImportModalTypes.ImportModalProps['preselectType'],
    public surveyCode?: string
  ) {
    super({ importFlowSelected: 'csv' });
  }

  public onInit = () => {
    if (this.preselectType) return this.selectImportFlow(this.preselectType);
  };

  public selectImportFlow = (
    flow: ImportModalTypes.ImportModalFlow,
    preSelectedIngestionDatatype?: number
  ) => {
    if (preSelectedIngestionDatatype)
      this.setState('preSelectedIngestionDatatype', preSelectedIngestionDatatype);
    this.setState('importFlowSelected', flow);
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useImportModalBLoC = () => useBLoC<BLoC>(Context);

export const ImportModalBLoC: React.FC<BLoCParams<BLoC, State> & ImportModalTypes.ImportModalProps> = ({
  children,
  onClose,
  project,
  surveyCode,
  ingestionId,
  preselectType,
}) => {
  const bloc = useInitBloc(
    () => new BLoC(onClose, ingestionId, project, preselectType, surveyCode),
    [ingestionId, project]
  );
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
