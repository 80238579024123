import styled from 'styled-components';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { colourPalette, fontSize, fontWeight, lineHeight } from 'styles/styles';

export const FormFieldStyle = {
  Container: styled(VStack)`
    .form-field-label {
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      font-weight: ${fontWeight.medium};
      color: ${colourPalette.neutral400};
    }
    &.form-field-disabled {
      .form-field-label,
      .form-field-help-text,
      .form-field-char-limit,
      [class^='form-field-tooltip-icon'] {
        pointer-events: none;
        user-select: none;
        color: ${colourPalette.neutral300};
      }
    }
  `,
};
