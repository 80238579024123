import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { MapStepColumn } from './components/MapStepColumn/MapStepColumn';
import { MapStepColumnsStyle as S } from './MapStepColumns.style';

export const MapStepColumns: React.FC = () => {
  const { $columns } = useMapStepBLoC();
  const columns = useObservableState($columns, []);

  return (
    <S.Container>
      {columns.map((column, i) => (
        <MapStepColumn key={i} {...{ column }} />
      ))}
    </S.Container>
  );
};
