import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { PButton } from 'components/prime/PButton/PButton';
import { Text } from 'components/Text/Text';
import React from 'react';
import { Link } from 'react-router-dom';

export const DetailsPanelEmptyState: React.FC = () => {
  return (
    <VStack stretch gap="gap4" align="center" justify="center">
      <VStack gap="gap2" align="center">
        <Text color="neutral900" size="fontSizeMD" weight="medium" lineHeight="lineHeightLG">
          No personas available
        </Text>
        <Text color="neutral400" size="fontSizeSM" lineHeight="lineHeightMD">
          Create your persona group first from your projects
        </Text>
      </VStack>
      <Link to={'/projects?type=va'}>
        <PButton severity="soft" label="Go to Projects" />
      </Link>
    </VStack>
  );
};
