import React, { ReactNode } from 'react';
import { TopBarWidgetStyle as S } from './TopBarWidget.style';
import { TopBarDropdownSupport } from './components/TopBarDropdownSupport/TopBarDropdownSupport';
import { TopBarDropdownUser } from './components/TopBarDropdownUser/TopBarDropdownUser';
import { TopBarTitle } from './components/TopBarTitle/TopBarTitle';
import { TopBarBurger } from './components/TopBarBurger/TopBarBurger';
import { TopBarCredits } from './components/TopBarCredits/TopBarCredits';
import { TopBarCompanyList } from './components/TopBarCompanyList/TopBarCompanyList';
import { TopBarBanner } from './components/TopBarBanner/TopBarBanner';
import { TopBarChatButton } from './components/TopBarChatButton/TopBarChatButton';

export const TopBarWidget: React.FC<{ topBarContent?: ReactNode }> = ({ topBarContent }) => {
  return (
    <>
      <S.Container>
        <TopBarBanner />
        <S.Column className="flex align-items-center">
          <TopBarBurger />
          {topBarContent ? <div className="overflow-hidden">{topBarContent}</div> : <TopBarTitle />}
        </S.Column>
        <S.Column className="flex justify-content-end align-items-center">
          <TopBarCredits />
          <TopBarChatButton />
          <TopBarDropdownSupport />
          <TopBarCompanyList />
          <TopBarDropdownUser />
        </S.Column>
      </S.Container>
    </>
  );
};
