import React, { useMemo } from 'react';
import { colourPalette } from 'styles/styles';
import * as iconsLibrary from './icons.library';
import { IconStyle as S } from './Icon.style';
import { useClassNames } from 'hooks/useClassNames';

export type IconProps = {
  icon: keyof typeof iconsLibrary;
  size?: number;
  color?: keyof typeof colourPalette | 'currentColor';
  boxType?: 'circle' | 'rect';
  boxSize?: number;
  boxColor?: keyof typeof colourPalette;
  boxColorAlpha?: number;
  boxClassName?: string;
  className?: string;
};

export const Icon: React.FC<IconProps> = ({
  icon,
  color,
  size,
  boxType,
  boxSize,
  boxColor,
  boxColorAlpha,
  boxClassName,
  ...props
}) => {
  const IconComponent = useMemo(() => iconsLibrary[icon], [icon]);
  const boxClassNames = useClassNames(
    {
      [`icon-box-type-${boxType}`]: !!boxType,
    },
    boxClassName
  );

  if (boxType) {
    return (
      <S.Box className={boxClassNames} $boxSize={boxSize} $boxColor={boxColor} $boxColorAlpha={boxColorAlpha}>
        <IconComponent
          {...props}
          width={size}
          height={size}
          color={!!color && color !== 'currentColor' ? colourPalette[color] : 'currentColor'}
        />
      </S.Box>
    );
  }

  return (
    <IconComponent
      {...props}
      width={size}
      height={size}
      color={!!color && color !== 'currentColor' ? colourPalette[color] : 'currentColor'}
    />
  );
};
