import React from 'react';
import { ModalServiceBLoC as BLoC, useModalServiceBLoC } from './ModalService.bloc';
import { useObservableEagerState } from 'observable-hooks';
import { useLocation } from 'react-router-dom';

const ModalServiceInner: React.FC = () => {
  const { $modals } = useModalServiceBLoC();
  const modals = useObservableEagerState($modals);

  return modals.map((m) => m && m.modal);
};

export const ModalService: React.FC = () => {
  const location = useLocation();
  return (
    <BLoC key={location.pathname}>
      <ModalServiceInner />
    </BLoC>
  );
};
