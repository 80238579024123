import { useClassNames } from 'hooks/useClassNames';
import { Column, ColumnProps } from 'primereact/column';
import React from 'react';
import { PColumnStyle as S } from './PColumn.style';

/**
 * @deprecated Use PDataTableV2 instead
 */
export const PColumn = React.forwardRef<Column, ColumnProps & { boder?: 'left' | 'right' | 'both' }>(
  (props, ref) => {
    const className = useClassNames(
      {
        'border-left': props.boder && props.boder !== 'right',
        'border-right': props.boder && props.boder !== 'left',
      },
      props.className
    );

    // field="id" is added as a hack as it seems new prime (9+) version borked columns. Must have field set to work
    return <S.Column field="id" {...props} className={className} ref={ref} />;
  }
);
