import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';
import { ReactComponent as InformationIcon } from 'assets/svg/information.svg';

export const CreditsModalTopupFormBalanceAfterStyle = {
  Container: styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
    }
  `,
  InformationIcon: styled(InformationIcon)`
    margin-right: 8px;
    path {
      fill: ${colours.neutralBody};
    }
  `,
  Text: styled.span`
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
    color: ${colours.neutralBody};
    margin: 0;
  `,
};
