import styled from 'styled-components';
import { colours } from 'styles/styles';

export const CreditsModalTopupFormTermsStyle = {
  Container: styled.div`
    margin: 16px 0 0 0;
  `,
  Label: styled.span`
    margin-left: 4px;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${colours.neutralBody};
  `,
};
