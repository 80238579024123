import styled from 'styled-components';
import { PInputNumber } from 'components/prime/PInputNumber/PInputNumber';
import { colourPalette, gap } from 'styles/styles';

export const AmountInputStyle = {
  Container: styled.div`
    position: relative;
    .amount-input-invalid-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 70px;
      path {
        &[fill] {
          fill: ${colourPalette.rose500} !important;
        }
        &[stroke] {
          stroke: ${colourPalette.rose500} !important;
        }
      }
    }
  `,
  Input: styled(PInputNumber)`
    .p-inputnumber-input {
      padding-right: 74px !important;
    }
    &.p-invalid {
      .p-inputnumber-input {
        padding-right: 98px !important;
      }
    }
  `,
  ButtonGroup: styled.div`
    position: absolute;
    top: 50%;
    right: ${gap.gap3};
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: ${gap.gap1};
  `,
};
