import React, { useState } from 'react';
import { DetailsPanelPersonasPersonaStyle as S } from './DetailsPanelPersonasPersona.style';
import { PersonaLibraryModalTypes } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.types';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { DetailsPanelPersonasPersonaExpanded } from './components/DetailsPanelPersonasPersonaExpanded/DetailsPanelPersonasPersonaExpanded';

export const DetailsPanelPersonasPersona: React.FC<{
  persona: PersonaLibraryModalTypes.Persona;
}> = ({ persona }) => {
  const [expanded, setExpanded] = useState(false);
  return !expanded ? (
    <S.Container padding="paddingSM" radius="roundedMD">
      <HStack align="center" splitAfterIdx={0}>
        <HStack gap="gap4" align="center">
          <S.ImageContainer radius="roundedLG">
            <HStack align="center" justify="center">
              <img src={persona.avatarUrl} alt="persona-image" />
            </HStack>
          </S.ImageContainer>
          <Text color="neutral600" size="fontSizeSM" weight="medium" lineHeight="lineHeightSM">
            {persona.name}
          </Text>
        </HStack>
        <HStack gap="gap4" align="center">
          <PButtonIcon
            severity="primary"
            size="md"
            shape="squircle"
            icon="expand01Solid"
            onClick={() => setExpanded(true)}
            className="expand-button"
          />
          <Text color="neutral400" size="fontSizeXS" lineHeight="lineHeightSM">
            {persona.ageRange} / {persona.gender} / {persona.location}
          </Text>
        </HStack>
      </HStack>
    </S.Container>
  ) : (
    <DetailsPanelPersonasPersonaExpanded {...{ persona }} onCollapse={() => setExpanded(false)} />
  );
};
