import React from 'react';
import { ListPanelSearchStyle as S } from './ListPanelSearch.style';
import { Search } from 'components/Search/Search';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';

export const ListPanelSearch: React.FC = () => {
  const {
    $projectId,
    $projects,
    $search,
    $projectHasNoFilters,
    setSearch,
    setProject,
    activityId,
    modalMode,
  } = useSavedFiltersModalBLoC();
  const projects = useObservableState($projects);
  const projectId = useObservableState($projectId);
  const search = useObservableState($search);
  const projectHasNoFilters = useObservableState($projectHasNoFilters);

  return (
    <S.Container>
      {(activityId && !projectId) || !projects ? (
        <S.ProjectDropdownSkeleton height="36px" />
      ) : (
        !projectHasNoFilters && (
          <>
            <S.Dropdown
              options={projects ? [{ id: null, name: 'All' }, ...projects] : []}
              value={projectId || null}
              optionLabel="name"
              optionValue="id"
              onChange={(e) => setProject(e.target.value)}
              placeholder="All"
              disabled={modalMode !== 'global'}
              itemTemplate={(item) => <span>{item?.name}</span>}
              panelClassName="hy-filters-modal-projects-dropdown-panel"
              data-testid="dropdown-saved-filters-project"
            />
            <S.ProjectDropdownGlobal />
          </>
        )
      )}
      <Search
        value={search || ''}
        onChange={(e) => setSearch(e.target.value)}
        disabled={(modalMode !== 'global' && !projectId) || projectHasNoFilters}
        data-testid="input-saved-filters-search"
      />
    </S.Container>
  );
};
