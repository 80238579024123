import styled from 'styled-components';

export const TopBarTitleStyle = {
  Title: styled.h6<{ $inReact?: boolean }>`
    font-family: 'SuisseIntl', sans-serif;
    font-weight: 400;
    font-family: 'SuisseIntl', sans-serif;
    font-weight: 400;
    transition: 0.2s;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    body:not(.side-menu-hidden) & {
      margin-left: ${({ $inReact }) => ($inReact ? '24px' : '0px')};
    }
  `,
};
