import React, { useMemo } from 'react';
import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import { PFormField } from 'components/prime/PFormField/PFormField';
import { CreditsModalTopupFormTermsStyle as S } from './CreditsModalTopupFormTerms.style';
import { useCreditsModalBLoC } from 'components/modals/CreditsModal/CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import { getCustomerStore } from 'stores/customer.store';
import { LinkText } from 'components/LinkText/LinkText';

export const CreditsModalTopupFormTerms: React.FC = () => {
  const { form } = useCreditsModalBLoC();
  const customer = useObservableState(getCustomerStore()?.$customer);
  const tcLink = useMemo(
    () =>
      customer?.countryName === 'New Zealand'
        ? 'https://content.yabble.com/terms-and-conditions-1'
        : 'https://content.yabble.com/terms-and-conditions-0',
    [customer?.countryName]
  );
  return (
    <S.Container>
      <PFormField
        form={form}
        controler={{
          name: 'agree',
          rules: {
            required: 'You must accept terms and conditions',
          },
        }}
      >
        {({ field, fieldState }) => (
          <PCheckbox
            {...field}
            onChange={(e) => field.onChange(e.checked)}
            checked={field.value}
            invalid={fieldState.invalid}
            label={
              <S.Label>
                I accept the{' '}
                <LinkText href={tcLink} target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </LinkText>{' '}
                and agree to have these costs billed according to my preferred payment method.
              </S.Label>
            }
            data-testid="checkbox-complete-transaction-terms"
          />
        )}
      </PFormField>
    </S.Container>
  );
};
