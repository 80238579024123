import styled from 'styled-components';

export const ImportModalCsvContentStyle = {
  Container: styled.div`
    background-color: transparent;
    height: calc(100vh - 72px);
  `,
  LoadingBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 72px);
  `,
};
