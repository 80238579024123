import { PFormField } from 'components/prime/PFormField/PFormField';
import { PInputText } from 'components/prime/PInputText/PInputText';
import React from 'react';
import { useUploadStepBLoC } from '../../../../UploadStep.bloc';
import { TypeOfDataImportNameInputStyle as S } from './TypeOfDataImportNameInput.style';

export const TypeOfDataImportNameInput: React.FC = () => {
  const { uploadForm } = useUploadStepBLoC();
  return (
    <S.Container>
      <PFormField
        form={uploadForm}
        label="Import name"
        controler={{
          name: 'name',
          rules: {
            required: 'Import name is required',
            maxLength: 255,
          },
        }}
        subLabel="Use a name that you can keep track of multiple updates"
      >
        {({ field, fieldState }) => (
          <PInputText
            {...field}
            invalid={fieldState.invalid}
            placeholder="E.g. Employee NPS March"
            maxLength={255}
            onBlur={() => field.onChange(field.value.trim())}
          />
        )}
      </PFormField>
    </S.Container>
  );
};
