import styled from 'styled-components';
import { PFormField } from 'components/prime/PFormField/PFormField';
import { PInputText } from 'components/prime/PInputText/PInputText';

export const TitleRenameFormStyle = {
  Form: styled.form`
    display: flex;
    align-items: flex-start;
    gap: 9px;
  `,
  FormField: styled(PFormField)`
    flex: 1 1 auto;
  `,
  Input: styled(PInputText)`
    .p-inputtext {
      min-height: initial !important;
      height: 32px;
    }
  `,
  Actions: styled.div`
    flex: 0 0 fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
  `,
};
