import React from 'react';
import { UploadStepStyle as S } from './UploadStep.style';
import { UploadStepBLoC as BLoC } from './UploadStep.bloc';
import { UploadStepFooter } from './components/UploadStepFooter/UploadStepFooter';
import { UploadStepContent } from './components/UploadStepContent/UploadStepContent';
import { useIntercomHidden } from 'hooks/useIntercomHide';

export const UploadStep: React.FC = () => {
  useIntercomHidden();
  return (
    <BLoC>
      {({ next }) => (
        <S.Container onSubmit={next()}>
          <UploadStepContent />
          <UploadStepFooter />
        </S.Container>
      )}
    </BLoC>
  );
};
