import React, { PropsWithChildren, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { PDialogV2Style as S } from './PDialogV2.style';
import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { PButtonIcon } from '../PButton/PButtonIcon';
import { useClassNames } from 'hooks/useClassNames';
import { Scroller } from 'components/layouts/primitives/Scroller/Scroller';

export type PDialogV2Props = {
  title: string;
  description?: string;
  onHide: () => void;
  visible?: boolean;
  maxWidth?: number;
  minHeight?: number;
  fullscreen?: boolean;
  noBodyScroller?: boolean;
  withHeaderDivider?: boolean;
  footer?: React.ReactNode;
  contentBlocker?: React.ReactNode;
  className?: string;
};

export const PDialogV2 = React.forwardRef<Dialog, PropsWithChildren<PDialogV2Props>>((props, ref) => {
  const dialogClassNames = useClassNames(
    {
      'p-dialog-fullscreen': props.fullscreen,
    },
    props.className
  );
  useEffect(() => {
    return () => {
      setTimeout(() => {
        const openedModals = document.getElementsByClassName('p-dialog-mask').length;
        if (openedModals) {
          document.body.classList.add('p-overflow-hidden');
        } else {
          document.body.classList.remove('p-overflow-hidden');
        }
      });
    };
  }, []);

  return (
    <S.Dialog
      onHide={props.onHide}
      className={dialogClassNames}
      visible={props.visible}
      $maxWidth={props.maxWidth}
      $minHeight={props.minHeight}
      baseZIndex={1000}
      blockScroll
      closeOnEscape={false}
      dismissableMask
      draggable={false}
      focusOnShow={false}
      resizable={false}
      showHeader={false}
      ref={ref}
    >
      <VStack noAutoMinSize="height" stretch className="p-dialog-content-wrapper">
        <VStack noAutoMinSize="height" fit="space" className="relative">
          {!!props.contentBlocker && (
            <Box
              stretch
              padding="padding2XL"
              backgroundColor="neutral0"
              backgroundColorAlpha={0.92}
              className="p-dialog-content-blocker"
            >
              {props.contentBlocker}
            </Box>
          )}
          <Box
            fit="content"
            padding={[
              'padding2XL',
              'padding2XL',
              props.withHeaderDivider ? 'paddingLG' : props.children ? 'paddingNone' : 'padding2XL',
            ]}
            borderColor="neutral200"
            borderWidth={props.withHeaderDivider ? ['borderNone', 'borderNone', 'borderSM'] : undefined}
            className="z-2"
          >
            <VStack gap="gap4">
              <HStack gap="gap4" justify="space-between" align="flex-start">
                <Text as="h1" size="fontSizeXL" lineHeight="lineHeight2XL" color="neutral900" weight="medium">
                  {props.title}
                </Text>
                <Box fit="content">
                  <PButtonIcon
                    icon="xLine"
                    size="md"
                    shape="squircle"
                    severity="primary"
                    onClick={props.onHide}
                  />
                </Box>
              </HStack>
              {!!props.description && (
                <Text as="h2" size="fontSizeSM" lineHeight="lineHeightMD" color="neutral500">
                  {props.description}
                </Text>
              )}
            </VStack>
          </Box>
          {!!props.children && (
            <HStack fit="space" noAutoMinSize="height">
              <Box fit="space">
                {props.noBodyScroller ? (
                  props.children
                ) : (
                  <Scroller className="p-dialog-body-scroller">
                    <Box padding={['paddingNone', 'padding2XL']}>{props.children}</Box>
                  </Scroller>
                )}
              </Box>
            </HStack>
          )}
        </VStack>
        {!!props.footer && (
          <Box fit="content" padding={['paddingNone', 'padding2XL', 'padding2XL']} className="z-3">
            {props.footer}
          </Box>
        )}
      </VStack>
    </S.Dialog>
  );
});
