import React from 'react';
import { ImportModalSyntheticFormStyle as S } from './ImportModalSyntheticForm.style';
import { PInputText } from 'components/prime/PInputText/PInputText';
import { PFormField } from 'components/prime/PFormField/PFormField';
import { useImportModalSyntheticBLoC } from '../../ImportModalSynthetic.bloc';
import { useObservableState } from 'observable-hooks';
import { useFormState } from 'react-hook-form';
import { useImportModalBLoC } from 'components/modals/ImportModal/ImportModal.bloc';
import { PInputMask } from 'components/prime/PInputMask/PInputMask';
import { ImportModalFooter } from '../../../ImportModalFooter/ImportModalFooter';
import { ImportModalContent } from '../../../ImportModalContent/ImportModalContent';
import { ImportModalHeader } from '../../../ImportModalHeader/ImportModalHeader';
import { ImportModalSyntheticFormError } from './components/ImportModalSyntheticFormError/ImportModalSyntheticFormError';
import { LinkText } from 'components/LinkText/LinkText';

export const ImportModalSyntheticForm: React.FC = () => {
  const { form, $survey, handleSubmit } = useImportModalSyntheticBLoC();
  const survey = useObservableState($survey);
  const state = useFormState(form);
  const { onClose } = useImportModalBLoC();

  return (
    <S.Form onSubmit={handleSubmit}>
      <ImportModalHeader title="New import" />;
      <ImportModalContent>
        <S.Content>
          <h6>
            Enter the 8-digit survey code generated for you within ChatGPT. Your data will automatically begin
            counting when your import is complete.{' '}
            <LinkText
              href="https://intercom.help/yabblezone/en/articles/5971300-hey-yabble-count-faqs"
              target="_blank"
            >
              Learn more about counting
            </LinkText>
          </h6>
          <PFormField
            form={form}
            controler={{
              name: 'surveyCode',
              rules: {
                required: true,
              },
            }}
            label="Survey code"
            showError="never"
          >
            {({ field }) => (
              <PInputMask
                {...field}
                autoFocus
                onPasteCapture={(e) => form.setValue('surveyCode', e.clipboardData.getData('Text'))}
                onBlur={(e: any) => {
                  form.resetField('surveyCode', { defaultValue: e.target.value });
                  field.onBlur();
                }}
                mask="****-****"
                placeholder="IPWG-7LGS"
              />
            )}
          </PFormField>
          {survey && survey !== 'not-found' && survey?.status === 'done' && (
            <S.Name>
              <PFormField
                form={form}
                controler={{
                  name: 'projectTitle',
                }}
                label="Project name"
              >
                {({ field }) => <PInputText placeholder={survey?.surveyTopic} maxLength={255} {...field} />}
              </PFormField>
            </S.Name>
          )}
          <ImportModalSyntheticFormError />
        </S.Content>
      </ImportModalContent>
      <ImportModalFooter
        cancelFn={onClose}
        successLabel="Import"
        disabled={!state.isValid || survey === 'not-found' || survey?.status !== 'done'}
      />
    </S.Form>
  );
};
