import styled from 'styled-components';

export const MapStepColumnHeaderStyle = {
  Container: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
  `,
  FirstSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  `,
};
