import styled from 'styled-components';
import { borderRadius, colourPalette, hexToRGBA } from 'styles/styles';

export const IconStyle = {
  Box: styled.span<{ $boxSize?: number; $boxColor?: keyof typeof colourPalette; $boxColorAlpha?: number }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ $boxSize }) => `${$boxSize || 32}px`};
    height: ${({ $boxSize }) => `${$boxSize || 32}px`};
    background-color: ${({ $boxColor, $boxColorAlpha }) =>
      $boxColor
        ? typeof $boxColorAlpha === 'number'
          ? hexToRGBA(colourPalette[$boxColor], $boxColorAlpha)
          : colourPalette[$boxColor]
        : 'transparent'};
    &.icon-box-type-circle {
      border-radius: ${borderRadius.roundedFull};
    }
    &.icon-box-type-rect {
      border-radius: ${borderRadius.roundedSM};
    }
  `,
};
