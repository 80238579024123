import styled from 'styled-components';

/**
 * @deprecated Use PDataTableV2 instead
 */
export const PColumnBodyStyle = {
  Container: styled.div``,
  SkeletonWrapper: styled.div`
    .no-padding & {
      padding: 0 12px;
    }
  `,
};
