import React from 'react';
import { DividerStyle as S } from './Divider.style';
import { borders, colourPalette } from 'styles/styles';

type DividerProps = {
  orientation?: 'horizontal' | 'vertical';
  borderWidth?: keyof typeof borders;
  color?: keyof typeof colourPalette;
  className?: string;
};

export const Divider: React.FC<DividerProps> = ({ className, ...props }) => {
  return <S.Divider className={className} $props={props} />;
};
