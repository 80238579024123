export const Currency = {
  AUD: { short: 'AUD', symbol: '$' },
  NZD: { short: 'NZD', symbol: '$' },
  USD: { short: 'USD', symbol: '$' },
  EUR: { short: 'EUR', symbol: '€' },
  GBP: { short: 'GBP', symbol: '£' },
  JPY: { short: 'JPY', symbol: '¥' },
  CAD: { short: 'CAD', symbol: '$' },
  CHF: { short: 'CHF', symbol: 'CHF' },
  CNY: { short: 'CNY', symbol: '¥' },
  HKD: { short: 'HKD', symbol: '$' },
  INR: { short: 'INR', symbol: '₹' },
  KRW: { short: 'KRW', symbol: '₩' },
  MXN: { short: 'MXN', symbol: '$' },
  RUB: { short: 'RUB', symbol: '₽' },
  SEK: { short: 'SEK', symbol: 'kr' },
  SGD: { short: 'SGD', symbol: '$' },
  THB: { short: 'THB', symbol: '฿' },
  TRY: { short: 'TRY', symbol: '₺' },
  ZAR: { short: 'ZAR', symbol: 'R' },
};
