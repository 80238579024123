import React, { useState } from 'react';
import { FiltersDetailsContentItemStyle as S } from './FiltersDetailsContentItem.style';
import { useClassNames } from 'hooks/useClassNames';
import { getFilterIcon, getFilterTitle, getFilterValue } from 'utils/filters';
import { FiltersTypes } from 'components/Filters/Filters.types';

export const FiltersDetailsContentItem: React.FC<{
  filter: FiltersTypes.Filter;
  availableCategories: FiltersTypes.AvailableFilterCategory[];
}> = ({ filter, availableCategories }) => {
  const [expanded, setExpanded] = useState([false, false]);
  const contentClassNames = useClassNames({
    'extended-title': expanded[0],
    'extended-conditions': expanded[1],
  });

  return (
    <S.Container>
      <S.Icon>{getFilterIcon(filter.subjectTypeId)}</S.Icon>
      <S.Content className={contentClassNames}>
        <h4
          onClick={() => setExpanded((prev) => [!prev[0], prev[1]])}
          data-testid="label-saved-filters-condition-title"
        >
          {getFilterTitle({
            availableCategories,
            subjectId: filter.subjectId,
            subjectTypeId: filter.subjectTypeId,
          })}
        </h4>
        <p
          onClick={() => setExpanded((prev) => [prev[0], !prev[1]])}
          data-testid="label-saved-filters-condition-value"
        >
          {getFilterValue(filter)}
        </p>
      </S.Content>
    </S.Container>
  );
};
