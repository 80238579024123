import React from 'react';
import { TypeOfDataIndustryDropdown } from './components/TypeOfDataIndustryDropdown/TypeOfDataIndustryDropdown';
import { UploadStepTypeOfDataStyle as S } from './UploadStepTypeOfData.style';
import { TypeOfDataNameInput } from './components/TypeOfDataNameInput/TypeOfDataNameInput';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { ReactComponent as InfoSvg } from 'assets/svg/information-filled.svg';
import { TypeOfDataImportNameInput } from './components/TypeOfDataImportNameInput/TypeOfDataImportNameInput';

export const UploadStepTypeOfData: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>
          What type of data are you importing?
          <span className="type-of-data-header-info">
            <InfoSvg />
          </span>
          <PTooltip
            target=".type-of-data-header-info"
            position="bottom"
            content="This contextualizes your data, allowing us to ensure we use the models that will generate the most
            accurate output from your dataset."
          />
        </S.Title>
        <S.Fields>
          <TypeOfDataIndustryDropdown />
          <TypeOfDataNameInput />
          <TypeOfDataImportNameInput />
        </S.Fields>
      </S.Content>
    </S.Container>
  );
};
