import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild } from 'types/BLoCBase';
import { useForm, UseFormReturn } from 'react-hook-form';
import { SavePersonaGroupModalTypes } from './SavePersonaGroupModal.types';
import { $post } from 'services/api';
import { finalize } from 'rxjs';
import { snackbarV2 } from 'services/snackbarV2';
import { Icon } from 'components/Icon/Icon';

type State = {
  loading?: boolean;
};

class FormValues {
  public name: string = '';
}

class BLoC extends BLoCBase<State> {
  public $loading = this.$getState('loading');

  constructor(
    public readonly form: UseFormReturn<FormValues>,
    public readonly projectId: number,
    public readonly closeModal: SavePersonaGroupModalTypes.SavePersonaGroupModalProps['onClose']
  ) {
    super({});
  }

  public savePersonaGroup = this.form.handleSubmit((data) => {
    this.setState('loading', true);
    this.addSub(
      $post(`persona-library/ad-project-templates/${this.projectId}/ad-groups`, {
        name: data.name,
      })
        .pipe(finalize(() => this.setState('loading', false)))
        .subscribe({
          next: () => {
            snackbarV2({
              title: 'Persona group created.',
              dismissable: true,
              leftItem: <Icon icon="checkCircleSolid" color="green500" />,
            });
            this.closeModal();
          },
          error: (err) =>
            snackbarV2({
              title: err?.message || 'An error ocurred. Try again later.',
              dismissable: true,
              leftItem: <Icon icon="alertOctagonSolid" color="rose500" />,
            }),
        }),
      'savePersonaGroup'
    );
  });
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useSavePersonaGroupModalBLoC = () => useBLoC<BLoC>(Context);

export const SavePersonaGroupModalBLoC: React.FC<
  BLoCParams<BLoC, State> & SavePersonaGroupModalTypes.SavePersonaGroupModalProps
> = ({ children, onClose, projectId }) => {
  const form = useForm({
    defaultValues: new FormValues(),
    mode: 'all',
  });
  const bloc = useInitBloc(() => new BLoC(form, projectId, onClose));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
