import { useId, useMemo } from 'react';

/**
 * React's useId hook but removing any special characters from the result string.
 * Useful for tooltips targets and element class names.
 * @returns {string}
 */
export const useCleanId = () => {
  const id = useId();
  return useMemo(() => id.replace(/[^a-zA-Z0-9]/g, ''), [id]);
};
