import { PDialog } from 'components/prime/PDialog/PDialog';
import styled from 'styled-components';
import { colours } from 'styles/styles';

export const FullScreenModalStyle = {
  Container: styled.div``,
  Dialog: styled(PDialog)`
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    .p-dialog-content {
      border-radius: 0px !important;
      overflow: hidden;
      padding: 0 !important;
      & > div:first-child {
        display: none;
      }
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `,
  Content: styled.div`
    flex: 1;
  `,
  Header: styled.div`
    height: 72px;
    border-bottom: 1px solid ${colours.neutralGrey03};
  `,
  Footer: styled.div`
    height: 72px;
    border-top: 1px solid ${colours.neutralGrey03};
  `,
};
