import styled from 'styled-components';
import { colours, cssScreens } from 'styles/styles';

export const MapStepColumnStyle = {
  Container: styled.div`
    display: flex;
    @media screen and (max-width: ${cssScreens.tablet}) {
      flex-direction: column;
    }
  `,
  Box: styled.div`
    width: 65%;
    background: ${colours.neutralWhite};
    border: 1px solid ${colours.neutralGrey03};
    border-radius: 8px;
    margin-bottom: 24px;
    @media screen and (max-width: ${cssScreens.tablet}) {
      width: 100%;
    }
  `,
};
