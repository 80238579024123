import { Divider } from 'primereact/divider';
import styled from 'styled-components';
import { colours } from 'styles/styles';

export const PDividerStyle = {
  Divider: styled(Divider)`
    &::before {
      min-height: 1px;
      min-width: 1px;
      background-color: ${colours.neutralGrey03};
    }
    & > div {
      color: ${colours.neutralLightCharcoal};
    }
    &.p-divider-vertical {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  `,
};
