import Pusher from 'pusher-js';
import Echo, { Channel } from 'laravel-echo';
import { Observable, ReplaySubject, combineLatest, distinctUntilChanged, first, map } from 'rxjs';
import { getCustomerStore } from 'stores/customer.store';
import { tokens } from './tokens';

(window as any).Pusher = Pusher;

const CUSTOMER_CHANNEL_PREFIX = 'ConnectionEngine.Modules.Core.Entities.Customer.';

// let customerChannel = Channel | null = null;

class PusherService {
  private $customerChannel = new ReplaySubject<Channel>();

  private subjects: {
    [key in string]: ReplaySubject<any>;
  } = {};

  constructor() {
    combineLatest([
      getCustomerStore().$customer.pipe(
        map((c) => c?.id),
        distinctUntilChanged()
      ),
      tokens.$accessToken,
    ]).subscribe(([customerId, token]) => {
      if (customerId && token) {
        this.$customerChannel.next(
          new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.REACT_APP_PUSHER_KEY,
            cluster: import.meta.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: true,
            auth: {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            },
          }).private(`${CUSTOMER_CHANNEL_PREFIX}${customerId}`)
        );
      }
    });
  }

  public $listen = <T extends object = {}>(event: string): Observable<T> => {
    if (this.subjects[event]) return this.subjects[event].asObservable();
    const $s = new ReplaySubject<T>();
    this.subjects[event] = $s;
    this.$customerChannel.pipe(first()).subscribe((customerChannel) => {
      customerChannel.listen(event, (data: T) => {
        $s.next(data);
      });
    });
    return $s.asObservable() as Observable<T>;
  };
  public stopListening = (event: string) => {
    if (this.subjects[event]) {
      this.subjects[event].unsubscribe();
      this.subjects[event].complete();
      delete this.subjects[event];
    }
    this.$customerChannel.pipe(first()).subscribe((customerChannel) => customerChannel.stopListening(event));
  };
}

const pusher = new PusherService();

export { pusher };
