import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { BreadCrumb, BreadCrumbProps } from 'primereact/breadcrumb';
import { PBreadCrumbStyle as S } from './PBreadCrumb.style';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

export const PBreadCrumb = React.forwardRef<BreadCrumb, BreadCrumbProps & { homeHidden: boolean }>(
  (props, ref) => {
    const cleanProps = useMemo(() => (({ homeHidden, ...o }: any) => o)(props), [props]);
    const classes = useClassNames({ 'breadcrumb-home-hidden': props.homeHidden }, props.className);
    return <S.BreadCrumb {...cleanProps} separatorIcon={<ChevronRight />} className={classes} ref={ref} />;
  }
);
