import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { NotificationPopupStyle as S } from './NotificationPopup.style';

export let popupRef: React.RefObject<any> | null = null;

export const NotificationPopup: React.FC = () => {
  const ref = useRef<typeof Toast>(null);
  useEffect(() => {
    popupRef = ref;
  }, []);
  return (
    <S.PopUp
      appendTo={window.document.body}
      transitionOptions={{ classNames: 'toast-anim' } as any}
      ref={ref as any}
      position="bottom-right"
    />
  );
};
