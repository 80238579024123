import React from 'react';
import { CreditsModalStyle as S } from './CreditsModal.style';
import { CreditsModalBLoC as BLoC, useCreditsModalBLoC } from './CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import { CreditsModalRight } from './components/CreditsModalRight/CreditsModalRight';
import { CreditsModalTypes } from './CreditsModal.types';
import { Loader } from 'components/Loader/Loader';

const CreditsModalInner: React.FC = () => {
  const { $mode } = useCreditsModalBLoC();
  const mode = useObservableState($mode, '');

  return (
    <S.Container
      className={`credits-modal-inner-layout-${
        mode === 'credit-spend' || mode === 'credit-request-approval' || mode === 'credit-request'
          ? 'summary'
          : 'form'
      }`}
    >
      {mode ? (
        <CreditsModalRight />
      ) : (
        <div className="flex align-items-center justify-content-center w-full">
          <Loader />
        </div>
      )}
    </S.Container>
  );
};

export const CreditsModal: React.FC<{
  onClose: (complete?: boolean) => void;
  project?: CreditsModalTypes.ProjectProp;
}> = ({ onClose, project }) => {
  return (
    <BLoC closeModal={onClose} project={project}>
      <S.Dialog
        onHide={onClose}
        visible
        resizable={false}
        header={
          <S.Header>
            <h4>Complete transaction</h4>
          </S.Header>
        }
      >
        <CreditsModalInner />
      </S.Dialog>
    </BLoC>
  );
};
