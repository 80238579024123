import { Path, UseFormReturn } from 'react-hook-form';
import { distinctUntilChanged, Observable, shareReplay } from 'rxjs';

const getDeepValue = (obj: any, path: any) => {
  if (obj[path] !== undefined) {
    return obj[path];
  }

  // eslint-disable-next-line no-var
  for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
    obj = (obj && (obj as any)[path[i]]) || null;
  }
  return obj;
};

export const $fromHookForm = <T extends object, X extends keyof T>(
  form: UseFormReturn<T, object>,
  prop: X | Path<T> | null = null
) => {
  return new Observable<T[X]>((sub) => {
    const initValues = form?.getValues();
    initValues && sub.next(prop ? getDeepValue(initValues, prop) : initValues);
    const hookFormSub = form?.watch((values: any) =>
      sub.next(prop && values ? getDeepValue(values, prop) : values)
    );
    return () => hookFormSub.unsubscribe();
  }).pipe(shareReplay(), distinctUntilChanged());
};
