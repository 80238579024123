import { Loader } from 'components/Loader/Loader';
import React from 'react';
import { LoadingPageStyle as S } from './LoadingPage.style';

export const LoadingPage: React.FC = () => {
  return (
    <S.Container>
      <Loader />
    </S.Container>
  );
};
