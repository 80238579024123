import React, { useEffect } from 'react';
import { ImportModalSyntheticLoadingStyle as S } from './ImportModalSyntheticLoading.style';
import { Loader } from 'components/Loader/Loader';
import { useImportModalSyntheticBLoC } from '../../ImportModalSynthetic.bloc';
import { ImportModalHeader } from '../../../ImportModalHeader/ImportModalHeader';

export const ImportModalSyntheticLoading: React.FC = () => {
  const { initPolling } = useImportModalSyntheticBLoC();
  useEffect(() => initPolling(), [initPolling]);
  return (
    <>
      <ImportModalHeader title="New import" />
      <S.Container>
        <Loader />
      </S.Container>
    </>
  );
};
