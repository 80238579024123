import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { Button, ButtonProps } from 'primereact/button';
import { PButtonIconStyle as S } from './PButtonIcon.style';
import * as iconsLibrary from '../../Icon/icons.library';
import { Icon } from 'components/Icon/Icon';

export type PButtonIconProps = Omit<ButtonProps, 'severity' | 'size' | 'icon' | 'label' | 'loading'> & {
  severity?: 'primary' | 'secondary' | 'tertiary' | 'soft' | 'strong';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  shape?: 'squircle' | 'round';
  icon?: keyof typeof iconsLibrary;
};

export const PButtonIcon = React.forwardRef<Button, PButtonIconProps>((props, ref) => {
  const classNames = useClassNames(
    {
      [`button-icon-size-${props.size || 'md'}`]: true,
      [`button-icon-severity-${props.severity || 'primary'}`]: true,
      [`button-icon-shape-${props.shape || 'squircle'}`]: true,
    },
    props.className
  );
  const cleanProps = useMemo(() => (({ severity, size, icon, ...p }) => p)(props), [props]);
  return (
    <S.Button type="button" {...cleanProps} className={classNames} ref={ref}>
      <div className="button-icon-custom-wrapper">
        {props.children || (!!props.icon && <Icon icon={props.icon} />)}
      </div>
    </S.Button>
  );
});
