import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { TC_IMPORT_DATE_RESPONSE_TYPE_ID } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import React from 'react';
import { useMapStepBLoC } from '../../../../../../MapStep.bloc';
import { MapStepColumnMonthFirstStyle as S } from './MapStepColumnMonthFirst.style';

export const MapStepColumnMonthFirst: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({
  column,
}) => {
  const {
    handleChangeMonthFirst,
    // timezones, currentDate, handleChangeTimezone
  } = useMapStepBLoC();
  return column.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID ? (
    <S.Container>
      <PCheckbox
        checked={!!column.extraInfo.monthFirst}
        onChange={() => handleChangeMonthFirst(column.id, !column.extraInfo.monthFirst)}
        label="Use US date format (MM/DD/YYYY)"
        disabled={column.ignoreColumn}
      />
      {/* <S.PDropdown
        value={column.extraInfo?.timezone || currentDate.timezone}
        placeholder="Timezone"
        options={timezones}
        optionValue="id"
        optionLabel="name"
        onChange={(e) => handleChangeTimezone(column.id, e.target.value)}
      /> */}
    </S.Container>
  ) : null;
};
