import React from 'react';
import { useImportModalBLoC } from '../../ImportModal.bloc';
import { ImportModalContent } from '../ImportModalContent/ImportModalContent';
import { ImportModalFooter } from '../ImportModalFooter/ImportModalFooter';
import { ImportModalHeader } from '../ImportModalHeader/ImportModalHeader';
import { ImportModalSelectFlowOptions } from './components/ImportModalSelectFlowOptions/ImportModalSelectFlowOptions';
import { ImportModalSelectFlowTitle } from './components/ImportModalSelectFlowTitle/ImportModalSelectFlowTitle';
import { ImportModalSelectFlowStyle as S } from './ImportModalSelectFlow.style';

export const ImportModalSelectFlow: React.FC = () => {
  const { onClose } = useImportModalBLoC();
  return (
    <S.Container>
      <ImportModalHeader title="New import" />
      <ImportModalContent>
        <ImportModalSelectFlowTitle />
        <ImportModalSelectFlowOptions />
      </ImportModalContent>
      <ImportModalFooter cancelFn={onClose} hideSuccessButton cancelLabel="Cancel" />
    </S.Container>
  );
};
