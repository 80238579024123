import React, { AnchorHTMLAttributes, useMemo } from 'react';
import { PMenuProps } from 'components/prime/PMenu/PMenu';
import { MenuItem } from 'primereact/menuitem';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { PButtonIconProps } from 'components/prime/PButton/PButtonIcon';

export const ThreeDotButton: React.FC<
  AnchorHTMLAttributes<HTMLDivElement> & {
    menuItems?: (MenuItem | null)[];
    buttonIconProps?: PButtonIconProps;
    menuProps?: PMenuProps;
  }
> = (props) => {
  const menuItems = useMemo(() => (props.menuItems?.filter((mi) => !!mi) || []) as any, [props.menuItems]);
  return menuItems?.length ? (
    <ButtonMenu
      {...props}
      menuItems={menuItems}
      buttonIconProps={{
        size: 'sm',
        icon: 'dotsHorizontalLine',
        ...(props.buttonIconProps || {}),
      }}
      tabIndex={0}
      data-testid="button-three-dot"
    />
  ) : null;
};
