import React from 'react';
import { DetailsPanelPersonasPersonaExpandedStyle as S } from './DetailsPanelPersonasPersonaExpanded.style';
import { PersonaLibraryModalTypes } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.types';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { Box } from 'components/layouts/primitives/Box/Box';
import { Divider } from 'components/Divider/Divider';
import { Text } from 'components/Text/Text';

export const DetailsPanelPersonasPersonaExpanded: React.FC<{
  persona: PersonaLibraryModalTypes.Persona;
  onCollapse?: () => void;
}> = ({ persona, onCollapse }) => {
  return (
    <S.Container radius="roundedXL" borderColor="neutral200" borderWidth="borderSM">
      <Box padding={['paddingXL', 'paddingXL', 'paddingLG', 'paddingXL']}>
        <HStack>
          <Text color="neutral600" size="fontSizeMD" weight="medium" lineHeight="lineHeightLG">
            {persona.name}
          </Text>
          <PButtonIcon
            severity="primary"
            size="md"
            shape="squircle"
            icon="minimize01Solid"
            onClick={onCollapse}
            className="ml-auto"
          />
        </HStack>
      </Box>
      <Divider color="neutral100" />
      <HStack>
        <Box padding={['paddingXL', 'paddingLG', 'paddingXL', 'paddingXL']}>
          <S.ImageContainer radius="roundedLG">
            <HStack align="center" justify="center">
              <img src={persona.avatarUrl} alt="persona-image" />
            </HStack>
          </S.ImageContainer>
        </Box>
        <Box padding={['paddingXL', 'paddingXL', 'paddingXL', 'paddingLG']}>
          <VStack gap="gap5">
            <HStack gap="gap4">
              <VStack>
                <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                  Age range
                </Text>
                <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                  {persona.ageRange}
                </Text>
              </VStack>
              <VStack>
                <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                  Gender
                </Text>
                <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                  {persona.gender}
                </Text>
              </VStack>
              <VStack>
                <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                  Location
                </Text>
                <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                  {persona.location}
                </Text>
              </VStack>
            </HStack>
            <VStack gap="gap2">
              <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                Personality
              </Text>
              <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                {persona.personalityTraits || '-'}
              </Text>
            </VStack>
            <VStack gap="gap2">
              <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                Pain points
              </Text>
              <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                {persona.painPoints || '-'}
              </Text>
            </VStack>
            <VStack gap="gap2">
              <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                Behaviours
              </Text>
              <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                {persona.behaviourAndTasks || '-'}
              </Text>
            </VStack>
            <VStack gap="gap2">
              <Text color="neutral400" size="fontSizeXS" weight="medium" lineHeight="lineHeightSM">
                Goals
              </Text>
              <Text color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                {persona.goals || '-'}
              </Text>
            </VStack>
          </VStack>
        </Box>
      </HStack>
    </S.Container>
  );
};
