import styled from 'styled-components';

export const PAutoCompleteEllipsisTemplateStyle = {
  Container: styled.div`
    display: flex;
    max-width: 100%;
    overflow: hidden;
  `,
  Left: styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `,
  Right: styled.span`
    width: fit-content;
  `,
};
