import { useClassNames } from 'hooks/useClassNames';
import { Tag, TagProps } from 'primereact/tag';
import React from 'react';
import { PTagStyle as S } from './PTag.style';

export const PTag = React.forwardRef<
  Tag,
  Omit<TagProps, 'severity'> & {
    iconPosition?: 'right' | 'left'; // default left
    size?: 'large' | 'default' | 'small';
    severity: 'success' | 'info' | 'warning' | 'danger' | 'white' | 'primary' | 'pink';
  }
>((props, ref) => {
  const className = useClassNames(
    {
      'p-tag-pink': props.severity === 'pink',
      'p-tag-small': props.size === 'small',
      'p-tag-large': props.size === 'large',
    },
    props.className
  );
  // const cleanProps = useMemo(() => (({ menuItems, label, mode, ...o }: any) => o)(props), [props]);
  return <S.Tag {...(props as any)} className={className} ref={ref} />;
});
