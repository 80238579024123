import styled from 'styled-components';
import { borderRadius, borders, colours, gap, padding } from 'styles/styles';

export const HeyYabbleCountStepBannerStyle = {
  Container: styled.div`
    padding: ${padding.paddingLG};
    border-radius: ${borderRadius.roundedMD};
    border: ${borders.borderSM} solid ${colours.neutralGrey03};
    margin-bottom: ${gap.gap6};
    display: flex;
    flex-direction: column;
    gap: ${gap.gap3};
    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin: 0;
      color: ${colours.primaryBlue};
    }
    ul {
      margin: 0;
      padding: 0;
      padding-left: 14px;
    }
    li {
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      color: ${colours.neutralBody};
    }
  `,
};
