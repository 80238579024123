import { useEffect, useState } from 'react';

export const useTimer = (seconds: number, enabled = true) => {
  const [count, setCount] = useState(seconds);
  useEffect(() => {
    const timer = enabled
      ? setInterval(() => {
          setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
        }, 1000)
      : null;
    return () => {
      enabled && clearInterval(timer!);
    };
  }, [enabled]);
  return count;
};
