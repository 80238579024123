import React from 'react';
import { FileUploadItemStyle as S } from './FileUploadItem.style';
import { LinkText } from 'components/LinkText/LinkText';

export const FileUploadItem: React.FC<{
  file: File;
  openFn: () => void;
  fileIsDuplicate?: boolean;
}> = ({ file, openFn, fileIsDuplicate }) => {
  return file ? (
    <S.Container>
      <S.Icon />
      <S.Text>
        {fileIsDuplicate && <S.WarnIcon />} {file.name}
      </S.Text>
      {fileIsDuplicate && (
        <S.Subtext>
          The content of this file has been detected in a previous upload.
          <br />
          Proceeding could result in inaccurate insights.
        </S.Subtext>
      )}
      <LinkText onClick={() => openFn()}>Change</LinkText>
    </S.Container>
  ) : null;
};
