import React, { useMemo } from 'react';
import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { CreditsModalSummaryStyle as S } from './CreditsModalSummary.style';
import { useCreditsModalBLoC } from '../../../../CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { map } from 'rxjs';
import { PButton } from 'components/prime/PButton/PButton';

export const CreditsModalSummary: React.FC = () => {
  const { project, $mode, $loading } = useCreditsModalBLoC();
  const availableCredits = useObservableState(
    getBillingPlanStore().$creditsBalance.pipe(map((bal) => bal || 0)),
    0
  );
  const mode = useObservableState($mode, '');
  const amount = availableCredits - (project?.amount || 0);
  const loading = useObservableState($loading);

  const creditsRequestMessage = useMemo(() => {
    return project?.type === 'au-data-project' || project?.type === 'summarize'
      ? "You don't have enough credits to proceed. Please contact Yabble support or your account administrator to purchase more credits. "
      : "Unfortunately you don't have enough credits to proceed. Your account administrator will need to top-up credits for the account. You can send them a request by clicking the button below, and your action will begin automatically once the request is approved. ";
  }, [project]);

  return project ? (
    <>
      <S.Title>Summary</S.Title>
      <S.Container>
        <S.Row>
          <S.Text>Credits required</S.Text>
          <S.Text data-testid="label-credits-modal-credits-required">
            {project && <FormattedNumber num={-project?.amount} />}
          </S.Text>
        </S.Row>
        <S.Row>
          <S.Text>Your credits</S.Text>
          <S.Text data-testid="label-credits-modal-available-credits">
            <FormattedNumber num={availableCredits} />
          </S.Text>
        </S.Row>
        <S.Divider />
        <S.RowFooter>
          <S.TextFooter>Remaining balance</S.TextFooter>
          <S.TextFooter data-testid="label-credits-modal-remaining-balance">
            {amount < 0 && <S.WarnIcon />}
            <FormattedNumber num={availableCredits - project.amount} />
          </S.TextFooter>
        </S.RowFooter>
      </S.Container>
      {(mode === 'credit-request-approval-purchase' || mode === 'credit-buy-and-spend') && (
        <S.Message severity="warn" text="You don't have enough credits to proceed" />
      )}
      {mode === 'credit-request' && (
        <S.Message
          severity="warn"
          text={
            <S.WarnBox>
              <span>{creditsRequestMessage}</span>
              {!['au-data-project', 'summarize'].includes(project.type) && (
                <PButton type="submit" label="Send request to admin" loading={loading} />
              )}
            </S.WarnBox>
          }
        />
      )}
    </>
  ) : null;
};
