import React from 'react';
import { SavePersonaGroupModalBLoC as BLoC } from './SavePersonaGroupModal.bloc';
import { SavePersonaGroupModalTypes } from './SavePersonaGroupModal.types';
import { PDialogV2 } from 'components/prime/PDialogV2/PDialogV2';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { SavePersonaGroupModalName } from './components/SavePersonaGroupModalName/SavePersonaGroupModalName';
import { SavePersonaGroupModalFooter } from './components/SavePersonaGroupModalFooter/SavePersonaGroupModalFooter';

export const SavePersonaGroupModal: React.FC<SavePersonaGroupModalTypes.SavePersonaGroupModalProps> = (
  props
) => {
  return (
    <BLoC {...props}>
      <PDialogV2
        visible
        title="Save persona group"
        onHide={props.onClose}
        footer={<SavePersonaGroupModalFooter />}
        description="Saving allows you to quickly retrieve this group of personas across different projects, allowing you to reference them in future research. Choose a name that helps you easily identify this group later."
      >
        <VStack gap="gap6">
          <SavePersonaGroupModalName />
        </VStack>
      </PDialogV2>
    </BLoC>
  );
};
