import { Box } from 'components/layouts/primitives/Box/Box';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import React from 'react';

export const ListPanelEmptyState: React.FC = () => {
  return (
    <VStack stretch align="center" justify="center">
      <Box padding={['paddingNone', 'paddingNone', 'padding3XL', 'paddingNone']}>
        <Text color="neutral400" size="fontSizeSM" weight="medium" lineHeight="lineHeightMD">
          No groups available
        </Text>
      </Box>
    </VStack>
  );
};
