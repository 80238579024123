import styled from 'styled-components';
import { ReactComponent as ScaleIcon } from 'assets/svg/scale.svg';
import { colours } from 'styles/styles';

export const MapStepStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  ScaleIcon: styled(ScaleIcon)`
    path {
      fill: ${colours.secondaryMagenta};
    }
  `,
};
