import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import { PFormField } from 'components/prime/PFormField/PFormField';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useUploadStepBLoC } from '../../../../UploadStep.bloc';
import { TypeOfDataIndustryDropdownStyle as S } from './TypeOfDataIndustryDropdown.style';

export const TypeOfDataIndustryDropdown: React.FC = () => {
  const { uploadForm, $industries } = useUploadStepBLoC();
  const industries = useObservableState($industries, []);

  return (
    <S.Container>
      <PFormField
        form={uploadForm}
        controler={{
          name: 'industryId',
          rules: {
            required: 'Industry is required',
          },
        }}
        label="Industry"
      >
        {({ field, fieldState }) => (
          <>
            <PDropdown
              {...field}
              options={industries}
              optionValue="id"
              optionLabel="name"
              placeholder="Select an option"
              invalid={fieldState.invalid}
            />
          </>
        )}
      </PFormField>
    </S.Container>
  );
};
