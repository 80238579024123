import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const SpinnerStyle = {
  Spinner: styled.svg<{ $props: any }>`
    background: none;
    display: block;
    shape-rendering: auto;
    width: ${({ $props }) => `${$props.size || 24}px`};
    height: ${({ $props }) => `${$props.size || 24}px`};

    rect {
      fill: ${({ $props }) =>
        $props.color ? (colourPalette as any)[$props.color] : colourPalette.neutral900};
    }
  `,
};
