import styled, { createGlobalStyle } from 'styled-components';
import { cssScreens } from 'styles/styles';

export const TopBarCompanyListStyle = {
  Container: styled.div`
    margin-left: 12px;
    max-width: 170px;
    @media screen and (max-width: ${cssScreens.mobileLarge}) {
      display: none;
    }
  `,
  CompanyDropdownGlobal: createGlobalStyle`
    .yabble-top-bar-company-list-dropdown-panel {
      max-width: 500px !important;
      @media screen and (max-width: ${cssScreens.tablet}) {
        max-width: 100% !important;
      }
      .p-autocomplete-item > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  `,
};
