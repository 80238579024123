import styled from 'styled-components';
import { colours, cssScreens, fontSize, lineHeight } from 'styles/styles';

export const ImportModalInvalidDeviceStyle = {
  Container: styled.div`
    position: absolute;
    inset: 0;
    top: 72px;
    bottom: 72px;
    background-color: ${colours.neutralWhite};
    z-index: 1;
    display: none;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    padding: 24px;
    text-align: center;
    color: ${colours.neutralLightCharcoal};
    @media screen and (max-width: ${cssScreens.mobileLarge}) {
      display: block;
    }
  `,
  Text: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
