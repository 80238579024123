(function () {
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;
  const originalSetLocalStorageItem = localStorage.setItem;
  const originalRemoveLocalStorageItem = localStorage.removeItem;
  const originalClearLocalStorage = localStorage.clear;

  history.pushState = function (...args) {
    originalPushState.apply(this, args);
    window.dispatchEvent(new Event('historychange'));
    return;
  };

  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    window.dispatchEvent(new Event('historychange'));
    return;
  };

  window.addEventListener('popstate', function () {
    window.dispatchEvent(new Event('historychange'));
  });

  localStorage.setItem = function (...args) {
    originalSetLocalStorageItem.apply(this, args);
    window.dispatchEvent(new StorageEvent('storage'));
    return;
  };

  localStorage.removeItem = function (...args) {
    originalRemoveLocalStorageItem.apply(this, args);
    window.dispatchEvent(new StorageEvent('storage'));
    return;
  };

  localStorage.clear = function (...args) {
    originalClearLocalStorage.apply(this, args);
    window.dispatchEvent(new StorageEvent('storage'));
    return;
  };
})();
