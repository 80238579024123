import { PToast } from 'components/prime/PToast/PToast';
import { ToastMessage, ToastProps } from 'primereact/toast';
// import { ToastPositionType, ToastSeverityType } from 'primereact/toast';
import React, { ReactNode, useEffect, useRef } from 'react';

let toastRef: React.RefObject<any> | null = null;

export const ToastService: React.FC = () => {
  const ref = useRef<typeof PToast>(null);
  useEffect(() => {
    toastRef = ref;
  }, []);
  return <PToast ref={ref as any} />;
};

export const toast = (props: {
  severity: ToastMessage['severity'];
  position?: ToastProps['position'];
  summary: string;
  detail?: string | ReactNode;
  life?: number;
  sticky?: boolean;
}) => {
  toastRef?.current?.show(
    Object.assign(
      {
        position: 'top-right',
        className: !props.detail ? 'summary-only' : '',
      },
      props
    )
  );
};

export const toastClear = () => {
  toastRef?.current.clear();
};
