import React, { useRef } from 'react';
import { TopBarDropdownSupportStyle as S } from './TopBarDropdownSupport.style';
import { PMenu } from 'components/prime/PMenu/PMenu';
import { Menu } from 'primereact/menu';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';

export const TopBarDropdownSupport: React.FC = () => {
  const ref = useRef<Menu>(null);

  return (
    <S.Container>
      <PButtonIcon
        size="sm"
        icon="annotationQuestionSolid"
        onClick={(event) => ref?.current?.toggle(event)}
      />
      <PMenu
        model={[
          {
            label: 'Help center',
            command: () => window.open('https://intercom.help/yabblezone/en/', '_blank'),
          },
          {
            label: 'Contact support',
            command: () => (window.location.href = `${window.location.origin}/contact`),
          },
        ]}
        popup
        ref={ref as any}
      />
    </S.Container>
  );
};
