import styled from 'styled-components';

export const ColumnTypeDropdownItemDataStyle = {
  Container: styled.div``,
  Row: styled.div`
    display: flex;
    align-items: center;
  `,
  IconContainer: styled.div`
    margin-left: 1px;
    margin-right: 12px;
    display: flex;
    align-items: center;
  `,
};
