import styled, { createGlobalStyle } from 'styled-components';
import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';

export const ListPanelSearchStyle = {
  Container: styled.div``,
  Dropdown: styled(PDropdown)`
    margin-bottom: 16px;
  `,
  ProjectDropdownSkeleton: styled(PSkeleton)`
    margin-bottom: 16px;
  `,
  ProjectDropdownGlobal: createGlobalStyle`
    .hy-filters-modal-projects-dropdown-panel {
      max-width: 500px;
      .p-dropdown-item > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  `,
};
