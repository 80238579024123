import React from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { DetailsPanelContentTitle } from './components/DetailsPanelContentTitle/DetailsPanelContentTitle';
import { DetailsPanelContentStyle as S } from './DetailsPanelContent.style';
import { DetailsPanelContentCopyToActivity } from './components/DetailsPanelContentCopyToActivity/DetailsPanelContentCopyToActivity';
import { DetailsPanelContentMeta } from './components/DetailsPanelContentMeta/DetailsPanelContentMeta';
import { DetailsPanelContentConditionsList } from './components/DetailsPanelContentConditionsList/DetailsPanelContentConditionsList';
import { useObservableState } from 'observable-hooks';
import { DetailsPanelContentLoading } from './components/DetailsPanelContentLoading/DetailsPanelContentLoading';

export const DetailsPanelContent: React.FC = () => {
  const { selectFilter, activityId, $loadingCategories, $loading } = useSavedFiltersModalBLoC();
  const loadingFilters = useObservableState($loading);
  const loadingCategories = useObservableState($loadingCategories);
  const loading = loadingCategories || loadingFilters === 'filters';

  return loading ? (
    <DetailsPanelContentLoading />
  ) : (
    <S.Container>
      <div className="details-panel-content-info">
        <S.BackBtn size="xs" icon="chevronLeftLine" onClick={() => selectFilter(null)} />
        <DetailsPanelContentTitle />
        <DetailsPanelContentMeta />
        <DetailsPanelContentConditionsList />
      </div>
      {activityId && (
        <div className="details-panel-bottom">
          <DetailsPanelContentCopyToActivity />
        </div>
      )}
    </S.Container>
  );
};
