import React from 'react';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import {
  TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID,
  TC_IMPORT_NPS_RESPONSE_TYPE_ID,
  TC_IMPORT_TEXT_RESPONSE_TYPE_ID,
} from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { CustomVariablesDropdown } from './components/CustomVariablesDropdown/CustomVariablesDropdown';
import { QuestionsDropdown } from './components/QuestionsDropdown/QuestionsDropdown';
import { useMapStepBLoC } from '../../../../../../MapStep.bloc';

export const MapStepColumnMapTo: React.FC<{
  column: ImportModalCsvTypes.IngestionColumn;
}> = ({ column }) => {
  const { hasCustomVars, hasQuestions, hasNpsQuestions } = useMapStepBLoC();
  if (column.responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID && hasCustomVars)
    return <CustomVariablesDropdown {...{ column }} />;

  if (column.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID && hasQuestions)
    return <QuestionsDropdown {...{ column }} />;

  if (column.responseDataTypeId === TC_IMPORT_NPS_RESPONSE_TYPE_ID && hasNpsQuestions)
    return <QuestionsDropdown {...{ column }} />;

  return null;
};
