import { filter, first, from, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { tokens } from './tokens';

type Options = {
  raw?: boolean;
  root?: '/api/' | '/admin-api/' | '/login/';
  headers?: { [key in string]: string };
  searchParams?: { [key in string]: string | number | null | undefined };
  hasMeta?: boolean;
  responseType?: 'json' | 'blob' | 'text' | 'empty';
  requestType?: 'file' | 'json' | 'form';
};

const makeRequest = (url: string, method: string, body: object | null, options: Options) => {
  const headers = new Headers();
  if (options.headers) {
    Object.keys(options.headers).map((k) => headers.set(k, (options as any).headers[k]));
  }
  if (tokens.csrfToken) {
    headers.set('X-CSRF-TOKEN', tokens.csrfToken);
  }

  (!options.headers || !options.headers['Content-Type']) &&
    options.requestType !== 'form' &&
    headers.set('Content-Type', 'application/json');

  headers.set('Accept', 'application/json');
  const urlObj = new URL(options.raw ? url : window.location.origin + (options.root || '/api/') + url);
  if (options.searchParams) {
    urlObj.search = new URLSearchParams(
      Object.fromEntries(Object.entries(options.searchParams).filter(([, v]) => !!v)) as any
    ).toString();
  }

  const selectResponseType = (res: Response, type: Options['responseType']) => {
    switch (type) {
      case 'blob':
        return res.blob();
      case 'text':
      case 'empty':
        return res.text();
      default:
        return res.json().catch(() => {
          return {};
        });
    }
  };
  return tokens.$accessToken.pipe(
    filter((ac) => ac !== 'loading'),
    first(),
    tap((ac) => ac && !options.raw && headers.set('authorization', 'Bearer ' + ac)),
    switchMap(() =>
      fromFetch(
        new Request(urlObj.toString(), {
          method,
          headers,
          body: body
            ? options.requestType === 'file' || options.requestType === 'form'
              ? (body as any)
              : JSON.stringify(body)
            : undefined,
          cache: 'no-cache',
          // mode: 'no-cors',
          referrer: 'http://yabblezone.net.test',
          redirect: 'error',
        })
      ).pipe(
        switchMap((res) => {
          if (res.status === 302 && res.url && document.location.href.indexOf('redirected=true') < 0) {
            const content = res.headers.get('Content');
            if (content) {
              document.location.href =
                window.location.origin +
                '/' +
                content +
                '?redirectTo=' +
                window.location.pathname +
                (window.location.search ? window.location.search + '&' : '?') +
                'redirected=true';
            }
            return throwError(() => res);
          } else if (res.status < 400) {
            const fetched = from(selectResponseType(res, options.responseType));
            return options.hasMeta
              ? fetched
              : fetched.pipe(map((r) => (r?.data !== null && r?.data !== undefined ? r.data : r)));
          } else if (res.status === 401) {
            setTimeout(() => {
              document.location.reload();
            }, 2000);
            return throwError(() => ({
              message: 'Your authentication has expired.',
              status: res.status,
            }));
          } else if (res.status === 403) {
            return throwError(() => ({
              message: 'You do not have permission to do this',
              status: res.status,
            }));
          } else if (res.status < 500) {
            return from(
              res.json().catch(() => {
                return {};
              })
            ).pipe(
              switchMap((errRes) => {
                const errors: string[] = errRes.errors && Object.values(errRes.errors);
                return throwError(() =>
                  Object.assign(errRes, {
                    status: res.status,
                    firstError: errors?.length ? errors[0] : errRes.error,
                  })
                );
              })
            );
          } else {
            return throwError(() => ({
              message: 'Server error has occured',
              status: res.status,
            }));
          }
        })
      )
    )
  );
};

export const $get = <T extends {}>(
  url: string,
  params: { [key in string]: string | number | null | undefined } = {},
  options: Options = {}
) => {
  options.searchParams = params;
  return makeRequest(url, 'GET', null, options).pipe(
    map((r) => (r['@context'] && r.value && Array.isArray(r.value) ? r.value : r))
  ) as Observable<T>;
};
export const $getWithMeta = <
  T extends {},
  M = { first: number; total: number; currentPage: number; from: number; perPage: number },
>(
  url: string,
  params: { [key in string]: string | number | null | undefined } = {},
  options: Options = {}
) => {
  options.hasMeta = true;
  options.searchParams = params;
  return makeRequest(url, 'GET', null, options).pipe(
    map((r: any) =>
      r['@context'] && r.value ? [r.value, (({ value, ...m }) => m)(r)] : [r.data, r.meta || r.metadata]
    )
  ) as Observable<[T, M]>;
};

export const $post = <T extends {}>(url: string, body: object = {}, options: Options = {}) => {
  return makeRequest(url, 'POST', body, options) as Observable<T>;
};
export const $put = <T extends {}>(url: string, body: object | File, options: Options = {}) => {
  return makeRequest(url, 'PUT', body, options) as Observable<T>;
};
export const $patch = <T extends {}>(url: string, body: object, options: Options = {}) => {
  return makeRequest(url, 'PATCH', body, options) as Observable<T>;
};
export const $delete = <T extends {}>(url: string, body: object = {}, options: Options = {}) => {
  return makeRequest(url, 'DELETE', body, options) as Observable<T>;
};
export const $putFileWithProgress = (
  url: string,
  file: File
): Observable<{ status: 'completed' | 'uploading'; percentage: number }> => {
  return new Observable((observer) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentage = parseFloat(((event.loaded / event.total) * 100).toFixed(2));
        observer.next({ status: 'uploading', percentage });
      }
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200 || xhr.status === 201) {
        observer.next({ status: 'completed', percentage: 100 });
        observer.complete();
      } else {
        observer.error('Upload failed!');
      }
    });

    xhr.addEventListener('error', () => {
      observer.error('An error occurred while uploading the file.');
    });

    xhr.addEventListener('abort', () => {
      observer.error('Upload has been canceled.');
    });

    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);

    return () => {
      xhr.abort();
    };
  });
};
