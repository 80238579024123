import styled from 'styled-components';
import { cssScreens } from 'styles/styles';

export const ImportModalContentStyle = {
  Container: styled.div`
    flex: 1;
    position: relative;
  `,
  Box: styled.div`
    position: absolute;
    inset: 0;
  `,
  Scroll: styled.div`
    padding: 56px 100px 56px 110px;
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
    @media screen and (max-width: ${cssScreens.tabletLarge}) {
      padding: 56px 42px 56px 56px;
    }
    @media screen and (max-width: ${cssScreens.tablet}) {
      padding: 56px 10px 56px 24px;
    }
  `,
};
