import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import styled from 'styled-components';

export const MapStepColumnMonthFirstStyle = {
  Container: styled.div`
    padding: 0 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  PDropdown: styled(PDropdown)`
    width: 200px !important;
  `,
};
