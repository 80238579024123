import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { PButton } from 'components/prime/PButton/PButton';
import React from 'react';
import { useSavePersonaGroupModalBLoC } from '../../SavePersonaGroupModal.bloc';
import { useObservableState } from 'observable-hooks';

export const SavePersonaGroupModalFooter: React.FC = () => {
  const { closeModal, savePersonaGroup, $loading } = useSavePersonaGroupModalBLoC();
  const loading = useObservableState($loading);

  return (
    <HStack gap="gap2">
      <Box fit="space" className="w-6">
        <PButton
          size="lg"
          severity="secondary"
          label="Cancel"
          className="w-full"
          onClick={closeModal}
          disabled={loading}
        />
      </Box>
      <Box fit="space" className="w-6">
        <PButton
          size="lg"
          severity="soft"
          label="Save"
          className="w-full"
          onClick={savePersonaGroup}
          loading={loading}
        />
      </Box>
    </HStack>
  );
};
