import { PMessage } from 'components/prime/PMessage/PMessage';
import { ReactComponent as WarnIcon } from 'assets/svg/warning-filled.svg';
import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const CreditsModalSummaryStyle = {
  Container: styled.div`
    padding: 24px;
    background-color: ${colours.neutralGrey01};
    border-radius: 8px;
    margin-bottom: 16px;
  `,
  Title: styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    color: ${colours.neutralCharcoal};
    margin: 0 0 16px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  `,
  Divider: styled.div`
    background-color: ${colours.neutralGrey03};
    height: 1px;
    width: 100%;
    margin: 4px 0 16px 0;
  `,
  RowFooter: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Message: styled(PMessage)`
    margin: 16px 0 32px 0;
    padding: 13px;
    border-radius: 8px;
    line-height: 160%;
  `,
  Text: styled.p`
    color: ${colours.neutralBody};
    margin: 0;
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
  `,
  TextFooter: styled.p`
    color: ${colours.neutralLightCharcoal};
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
    margin: 0;
    display: flex;
    align-items: center;
  `,
  WarnIcon: styled(WarnIcon)`
    margin-right: 8px;
    width: 16px;
    height: 16px;
    path {
      fill: ${colours.uiWarning};
    }
  `,
  WarnBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `,
};
