import styled from 'styled-components';
import { colours } from 'styles/styles';

export const ImportModalSelectFlowOptionsStyle = {
  Container: styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    gap: 24px;
  `,
  Option: styled.div`
    min-height: 180px;
    border: 1px solid ${colours.neutralGrey03};
    border-radius: 5px;
    background: ${colours.neutralWhite};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    padding: 24px 48px 24px 24px;
    text-align: center;
    transition-duration: 0.7s;
    &:hover {
      background: rgba(44, 108, 237, 0.04);
      border: 1px solid rgba(44, 108, 237, 0.5);
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      color: ${colours.neutralCharcoal};
      margin: 20px 0 0;
    }
    p {
      font-size: 12px;
      line-height: 20px;
      color: ${colours.neutralBody};
      margin: 8px 0 0;
      text-align: left;
    }
  `,
};
