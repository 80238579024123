import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';
import { Link } from 'react-router-dom';

const linkStyles = `
  width: 220px;
  padding: 0;
  padding-left: 20px;
  display: inline-flex;
  height: 40px;
  align-items: center;
  border-left: 3px solid transparent;
  position: relative;
  text-decoration: none !important;
  color: white !important;
  border-radius: 0 20px 20px 0;
  font-size: ${fontSize.fontSizeSM};
  line-height: ${lineHeight.lineHeightMD};
  transition-property: padding;
  transition-duration: 0.2s;
  &.active,
  &.active:hover {
    background: ${colours.secondaryDarkBlue};
    border-left: 3px solid white;
    color: white;
  }
  &:not(.active):hover {
    background: rgba(13, 71, 187, 0.56);
    padding-left: 24px;
    color: white;
    text-decoration: none;
  }
  &.disabled {
    opacity: 0.5;
  }
`;

export const LeftMenuWidgetStyle = {
  Container: styled.div`
    background-color: ${colours.primaryBlue};
    width: 230px;
    height: 100vh;
    position: fixed;
    z-index: 10;
    transition: 0.3s;
    overflow: hidden;
    max-width: 100%;
    &.left-menu-light-theme {
      background-color: ${colours.neutralGrey02};
    }
    body.side-menu-hidden & {
      width: 0px;
      min-width: 0px;
    }
  `,
  LogoWrapper: styled.div`
    height: 56px;
    width: 230px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    padding: 0px 12px 0 24px;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
    .left-menu-light-theme & {
      border-bottom-color: ${colours.neutralWhite};
    }
  `,
  Menu: styled.div``,
  ALink: styled.a`
    ${linkStyles}
  `,
  ReactLink: styled(Link)`
    ${linkStyles}
  `,
  Beta: styled.span`
    width: 36px;
    height: 16px;
    font-size: 10px;
    font-weight: 500;
    margin-left: 9px;
    position: relative;
    top: 1px;
    background-color: ${colours.primaryPink};
    color: ${colours.neutralCharcoal};
    border-radius: 4px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: 'BETA';
    }
  `,
};
