import React, { useMemo } from 'react';
import { ImportModalSyntheticFormErrorStyle as S } from './ImportModalSyntheticFormError.style';
import { useImportModalSyntheticBLoC } from '../../../../ImportModalSynthetic.bloc';
import { useObservableState } from 'observable-hooks';
import { LinkText } from 'components/LinkText/LinkText';

export const ImportModalSyntheticFormError: React.FC = () => {
  const { $survey } = useImportModalSyntheticBLoC();
  const survey = useObservableState($survey);
  const errorMessage = useMemo(() => {
    if (survey === 'not-found') {
      return (
        <S.MessageText>
          We could not find a survey matching that code. Please check it and try again.
        </S.MessageText>
      );
    } else if (survey?.status === 'imported' || survey?.status === 'importing') {
      return (
        <S.MessageText>
          This survey has already been imported. View it via the ‘Projects’ page in the main menu, or open
          Yabble’s ChatGPT Plugin to{' '}
          <LinkText href="https://chat.openai.com/?plugin=yabble" target="_blank" rel="noreferrer">
            create a new survey.
          </LinkText>
        </S.MessageText>
      );
    } else if (survey?.status && survey?.status !== 'done') {
      return (
        <S.MessageText>
          This survey has not been completed. Open the survey in Yabble’s ChatGPT Plugin to complete it, then
          try importing again.{' '}
          <LinkText href="https://chat.openai.com/?plugin=yabble" target="_blank" rel="noreferrer">
            Open Survey.
          </LinkText>
        </S.MessageText>
      );
    }
  }, [survey]);
  return errorMessage ? <S.Message severity="error" content={errorMessage} /> : null;
};
