import React from 'react';
import { SavedFiltersModalStyle as S } from './SavedFiltersModal.style';
import { SavedFiltersModalBLoC as BLoC, useSavedFiltersModalBLoC } from './SavedFiltersModal.bloc';
import { SavedFiltersModalTypes } from './SavedFiltersModal.types';
import { SavedFiltersModalListPanel } from './components/SavedFiltersModalListPanel/SavedFiltersModalListPanel';
import { SavedFiltersModalDetailsPanel } from './components/SavedFiltersModalDetailsPanel/SavedFiltersModalDetailsPanel';
import { useObservableState } from 'observable-hooks';
import { useClassNames } from 'hooks/useClassNames';

const SavedFiltersModalInner: React.FC = () => {
  const { $selectedFilterId } = useSavedFiltersModalBLoC();
  const selectedFilterId = useObservableState($selectedFilterId);
  const detailsPanelClassName = useClassNames(
    {
      collapsed: !selectedFilterId,
    },
    'details-panel'
  );

  return (
    <S.Container>
      <div className="list-panel">
        <SavedFiltersModalListPanel />
      </div>
      <div className={detailsPanelClassName}>
        <SavedFiltersModalDetailsPanel />
      </div>
    </S.Container>
  );
};

export const SavedFiltersModal: React.FC<SavedFiltersModalTypes.SavedFiltersModalProps> = (props) => {
  return (
    <BLoC {...props}>
      <S.Dialog onHide={props.onClose} visible={true} header="Saved filters">
        <SavedFiltersModalInner />
      </S.Dialog>
    </BLoC>
  );
};
