import React, { useMemo } from 'react';
import { HeyYabbleCountStepTableRowPipelinesStyle as S } from './HeyYabbleCountStepTableRowPipelines.style';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { useObservableState } from 'observable-hooks';

export const HeyYabbleCountStepTableRowPipelines: React.FC<{
  idCol: number;
  recommendedPipelineId?: number | null;
  isChecked: boolean;
}> = ({ idCol, recommendedPipelineId, isChecked }) => {
  const { $pipelines, handleChangePipelineId } = useHeyYabbleCountStepBLoC();
  const pipelines = useObservableState($pipelines, []);
  const isDisabled = useMemo(() => {
    return !isChecked;
  }, [isChecked]);

  return (
    <S.Container>
      <S.Dropdown
        value={recommendedPipelineId}
        optionValue="id"
        optionLabel="name"
        options={pipelines}
        placeholder="Select a pipeline"
        onChange={(e) => handleChangePipelineId(idCol, e.target.value)}
        disabled={isDisabled}
      />
    </S.Container>
  );
};
