import React, { useMemo } from 'react';
import { format as formatFns, add as addFns, Duration } from 'date-fns';

const supportsDateStringFormat = (date: string) => !Number.isNaN(new Date(date).getTime());

const replaceHyphensWithSlashes = (input?: Date | string | number | null) =>
  typeof input === 'string' ? input.replace(/-/g, '/') : input;

/**
 * Check if date string is supported by the browser. For example:
 * Browser Safari version < 16 does not support string dates with hyphens like 'dd-MM-yyyy'.
 * If input not a string then return original value.
 * LINK: https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 */
const getValidDateString = (input?: Date | string | number | null) => {
  return typeof input === 'string' && !supportsDateStringFormat(input)
    ? replaceHyphensWithSlashes(input)
    : input;
};

export const FormattedDate: React.FC<{
  date?: Date | string | number | null;
  format?: string;
  add?: Duration;
}> = ({ date, format, add }) => {
  const _date = useMemo(() => getValidDateString(date), [date]);
  const formatted = useMemo(
    () =>
      _date
        ? formatFns(add ? addFns(new Date(_date), add || {}) : new Date(_date), format || 'MMM d, yyyy')
        : null,
    [_date, format, add]
  );
  return <>{formatted}</>;
};

export const FormattedDateTime: React.FC<{ date?: Date | string | number | null; format?: string }> = ({
  date,
  format,
}) => {
  const _date = useMemo(() => getValidDateString(date), [date]);
  const formatted = useMemo(
    () => (_date ? formatFns(new Date(_date), format || 'MMM d, yyyy hh:mmaa') : null),
    [_date, format]
  );
  return <>{formatted}</>;
};
