import React from 'react';
import { ErrorBoundaryPageStyle as S } from './ErrorBoundaryPage.style';
import { LinkText } from 'components/LinkText/LinkText';

export const ErrorBoundaryPage: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <div onClick={() => (window.location.href = '/')}>
          <S.Logo />
        </div>
      </S.Header>
      <S.Card>
        <S.Box>
          <S.Section1 />
          <S.Section2 />
          <S.CloudBox>
            <S.CloudSecondaryBox>
              <S.CloudIcon />
            </S.CloudSecondaryBox>
          </S.CloudBox>
        </S.Box>
        <S.Title>Something went wrong &#128542;</S.Title>
        <S.Description>There was a problem processing your request.</S.Description>
        <S.Description>Please either refresh the page or return to home to try again.</S.Description>
        <S.Button label="Go to Yabble Home" onClick={() => (window.location.href = '/')} />
        <S.FooterText>
          <LinkText href={`${window.location.origin}/contact`} target="_blank">
            Contact us
          </LinkText>{' '}
          for support
        </S.FooterText>
      </S.Card>
    </S.Container>
  );
};
