import React, { PropsWithChildren, useMemo } from 'react';
import { PFileUpload } from 'components/prime/PFileUpload/PFileUpload';
import { FileUpload, FileUploadProps } from 'primereact/fileupload';

export const FileUploadMain = React.forwardRef<FileUpload, PropsWithChildren<FileUploadProps>>(
  (props, ref) => {
    const cleanProps = useMemo(
      () =>
        (({
          confirmComponent,
          fullHeight,
          addFile,
          emptyStateDescription,
          hideEmptyStateIcon,
          fileIsDuplicate,
          files,
          onTop,
          maxFileNumber,
          customItemTemplate,
          ...o
        }: any) => o)(props),
      [props]
    );
    return <PFileUpload {...cleanProps} ref={ref} />;
  }
);
