import React from 'react';
import { PersonaLibraryModalStyle as S } from './PersonaLibraryModal.style';
import { PersonaLibraryModalBLoC as BLoC } from './PersonaLibraryModal.bloc';
import { PDialogV2 } from 'components/prime/PDialogV2/PDialogV2';
import { PersonaLibraryModalTypes } from './PersonaLibraryModal.types';
import { Box } from 'components/layouts/primitives/Box/Box';
import { ListPanel } from './components/ListPanel/ListPanel';
import { DetailsPanel } from './components/DetailsPanel/DetailsPanel';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Scroller } from 'components/layouts/primitives/Scroller/Scroller';

export const PersonaLibraryModal: React.FC<PersonaLibraryModalTypes.PersonaLibraryModalProps> = (props) => {
  return (
    <BLoC {...props}>
      <PDialogV2
        title="Persona Library"
        onHide={() => props.onClose()}
        maxWidth={1240}
        visible
        noBodyScroller
        withHeaderDivider
        minHeight={685}
      >
        <HStack fit="space" align="stretch" stretch>
          <S.ListPanel
            borderColor="neutral200"
            borderWidth={['borderNone', 'borderSM', 'borderNone', 'borderNone']}
          >
            <Scroller>
              <ListPanel />
            </Scroller>
          </S.ListPanel>
          <Box fit="space">
            <Scroller>
              <DetailsPanel />
            </Scroller>
          </Box>
        </HStack>
      </PDialogV2>
    </BLoC>
  );
};
