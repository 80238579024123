import styled from 'styled-components';
import { PInputText } from 'components/prime/PInputText/PInputText';

export const SearchStyle = {
  InputText: styled(PInputText)`
    .input-text-icon-wrapper.right {
      width: fit-content;
      height: fit-content;
      pointer-events: all;
    }
  `,
};
