import React, { useMemo } from 'react';
import { PAutoCompleteEllipsisTemplateStyle as S } from './PAutoCompleteEllipsisTemplate.style';

export const PAutoCompleteEllipsisTemplate: React.FC<{ text: string }> = ({ text }) => {
  const displayText: any = useMemo<any>(
    () =>
      typeof text === 'string' && text.length > 50 ? (
        <>
          <S.Left>{text.slice(0, text.length - 25)}</S.Left>
          <S.Right>{text.slice(text.length - 25)}</S.Right>
        </>
      ) : (
        text
      ),
    [text]
  );
  return <S.Container title={text}>{displayText}</S.Container>;
};
