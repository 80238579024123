import { MutableRefObject, useEffect } from "react";

export const useClickOutside = (
  ref: MutableRefObject<any>,
  onClick: () => void,
  disabled?: boolean
) => {
  useEffect(() => {
    const clickOutsideHandler = (e: MouseEvent) => {
      !disabled && ref.current && !ref.current.contains(e.target) && onClick();
    };
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [ref, onClick, disabled]);
};
