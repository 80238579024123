import { useIsReactPage } from 'hooks/useIsReactPage';
import React from 'react';
import { TopBarBurgerStyle as S } from './TopBarBurger.style';

export const TopBarBurger: React.FC = () => {
  const inReact = useIsReactPage();

  return (
    <S.Icon
      onClick={() => {
        localStorage.setItem('side-menu-hidden', 'false');
        document.body.classList.remove('side-menu-hidden');
      }}
      $inReact={inReact}
    />
  );
};
