import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const UploadStepErrorStyle = {
  Container: styled.div`
    position: absolute;
    inset: 0;
    background-color: white;
    z-index: 2;
  `,
  Content: styled.div`
    max-width: 480px;
  `,
  Description: styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${colourPalette.neutral600};
  `,
  Text: styled.span`
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral500};
  `,
};
