import { combineLatest, distinctUntilChanged, filter, map, of, shareReplay, switchMap } from 'rxjs';
import { $get } from 'services/api';
import { BLoCBase } from 'types/BLoCBase';
import { Currency } from 'types/Currency';
import { Customer } from 'types/Customer';
import { jwtDecode } from 'jwt-decode';
import { tokens } from 'services/tokens';

type Store = {
  customerSlug: string | null;
  customer: Customer | null;
  // statuses: CustomerStatus | null;
};

class CustomerStore extends BLoCBase<Store> {
  public readonly $customerSlug = this.$getState('customerSlug');
  public $customer = this.$getState('customer').pipe(shareReplay(), distinctUntilChanged());
  public $customerId = this.$customer.pipe(map((c) => c?.id));
  public $billingStatus = this.$getState('customer').pipe(
    map((c) =>
      ['incomplete', 'incomplete_expired'].includes(c?.billingStatus || '') ? 'unpaid' : c?.billingStatus
    )
  );
  public $customers = $get<{ name: string; id: string; slug: string; billingStatus: any }[]>(
    `customers`
  ).pipe(
    map(
      (
        results: {
          name: string;
          id: string;
          slug: string;
          billingStatus: any;
        }[]
      ) => results.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    ),
    shareReplay()
  );
  /**
   *
   */
  constructor() {
    super({
      customerSlug:
        import.meta.env.DEV && !!import.meta.env.REACT_APP_ACCESS_TOKEN
          ? (jwtDecode(import.meta.env.REACT_APP_ACCESS_TOKEN) as any)?.current_customer?.slug || null
          : null,
      customer: null,
      // statuses: null,
    });
    setTimeout(() => {
      // on slug change re-fetch data
      combineLatest([this.$customerSlug, tokens.$accessToken])
        .pipe(
          filter(([_, ac]) => !!ac),
          switchMap(([slug]) => (slug ? $get<Customer>(`customers/${slug}`) : of(null)))
        )
        .subscribe((c: Customer | null) => this.setState('customer', c));

      tokens.$accessToken
        .pipe(
          distinctUntilChanged(),
          map((token: any) =>
            token && token !== 'loading' ? (jwtDecode(token) as any)?.current_customer?.slug : null
          )
        )
        .subscribe(
          (slug) => this.currentState('customerSlug') !== slug && this.setState('customerSlug', slug)
        );
    });
  }

  public setCustomerSlug = (customer: Object & { slug: string; id?: string }) =>
    this.setState('customerSlug', customer.slug);

  public fetchCustomer = () => {
    const slug = this.currentState('customerSlug');
    slug &&
      tokens.accessToken &&
      tokens.accessToken !== 'loading' &&
      $get<Customer>(`customers/${slug}`).subscribe((c: Customer | null) => this.setState('customer', c));
  };

  public $currency = this.$customer.pipe(
    map((customer) => {
      if (customer?.currency) {
        return Currency[customer?.currency];
      }
      switch (customer?.countryName) {
        case 'Australia':
          return Currency['AUD'];
        case 'New Zealand':
          return Currency['NZD'];
        default:
          return Currency['USD'];
      }
    })
  );
}

let store: Readonly<CustomerStore> | null = null;
export const getCustomerStore = () => {
  if (!store) {
    store = Object.freeze(new CustomerStore());
  }
  return store;
};
