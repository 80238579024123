import React from 'react';
import { DetailsPanelEmptyStateStyle as S } from './DetailsPanelEmptyState.style';

export const DetailsPanelEmptyState: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Select a filter to view details</S.Title>
    </S.Container>
  );
};
