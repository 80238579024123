import { PButton } from 'components/prime/PButton/PButton';
import { ReactComponent as CloudIcon } from 'assets/svg/alert-cloud.svg';
import { ReactComponent as LogoSimpleSvg } from 'assets/svg/logo-simple.svg';
import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const ErrorBoundaryPageStyle = {
  Container: styled.div`
    background: #fafafa;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  Logo: styled(LogoSimpleSvg)`
    display: block;
    fill: white;
    height: 32px;
    width: 102px;
    fill: ${colours.neutralCharcoal};
  `,
  CloudBox: styled.div`
    position: absolute;
    z-index: 2;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    height: 58px;
    width: 58px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  `,
  CloudSecondaryBox: styled.div`
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f43f5e;
    margin-top: 2px;
  `,
  CloudIcon: styled(CloudIcon)`
    height: 32px;
    width: 32px;
    & > path {
      stroke: ${colours.neutralWhite};
    }
  `,
  Header: styled.div`
    position: absolute;
    top: 24px;
    left: 24px;
  `,
  Card: styled.div`
    border-radius: 18px;
    border: 1px solid rgba(24, 30, 38, 0.08);
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(24, 30, 38, 0.05);
    backdrop-filter: blur(24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    max-width: 640px;
    min-width: 520px;
    padding: 84px 48px 64px 48px;
  `,
  Box: styled.div`
    position: relative;
    width: 86%;
    height: 140px;
    margin-bottom: 86px;
  `,
  Section1: styled.div`
    border-radius: 8px;
    border: 0.5px solid rgba(24, 30, 38, 0.08);
    background: rgba(252, 252, 252, 0.8);
    box-shadow: 0px 1px 4px 0px rgba(24, 30, 38, 0.07);
    backdrop-filter: blur(16px);
    width: 230px;
    height: 150px;
    position: absolute;
    right: 0;
    top: -20px;
    transform: rotate(4deg);
    z-index: 1;
  `,
  Section2: styled.div`
    border-radius: 8px;
    border: 0.5px solid rgba(24, 30, 38, 0.08);
    background: rgba(252, 252, 252, 0.8);
    box-shadow: 0px 1px 4px 0px rgba(24, 30, 38, 0.07);
    width: 280px;
    height: 160px;
    transform: rotate(-3deg);
    position: absolute;
    left: 0;
    bottom: -40px;
  `,
  Title: styled.h2`
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 100%;
    margin: 0;
    margin-bottom: 24px;
  `,
  Description: styled.span`
    color: #6e7c9a;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  Button: styled(PButton)`
    margin-top: 32px;
    margin-bottom: 16px;
    width: 100%;
  `,
  FooterText: styled.span`
    color: #6e7c9a;
    text-align: center;
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
  `,
};
