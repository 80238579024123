import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild, IBLoCInitialisable } from 'types/BLoCBase';
import { SavedFiltersModalTypes } from './SavedFiltersModal.types';
import { $delete, $get, $getWithMeta, $post, $put } from 'services/api';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { useForm, UseFormReturn } from 'react-hook-form';
import { toast } from 'services/toast';
import { snackbar } from 'services/snackbar';
import { SetQuery, StringParam, useQueryParams } from 'hooks/useQueryParams';
import { FiltersTypes } from 'components/Filters/Filters.types';

type State = {
  projectId?: SavedFiltersModalTypes.Project['id'] | null;
  search: string;
  page: number;
  refetchDateFiltersList: Date;
  selectedFilterId?: SavedFiltersModalTypes.SavedFilter['id'] | null;
  selectedFilter?: SavedFiltersModalTypes.SavedFilter | null;
  renameMode?: boolean;
  loading?: 'loaded' | 'renaming' | 'deleting' | 'copying' | 'filters';
  loadingCategories?: boolean;
  availableCategories: FiltersTypes.AvailableFilterCategory[];
};

class RenameFormValues {
  public name: string = '';
}

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $page = this.$getState('page');
  public $search = this.$getState('search');
  public $projectId = this.$getState('projectId');
  public $selectedFilterId = this.$getState('selectedFilterId');
  public $selectedFilter = this.$getState('selectedFilter');
  public $availableCategories = this.$getState('availableCategories');
  public $loading = this.$getState('loading');
  public $loadingCategories = this.$getState('loadingCategories');

  public $projects = $get<SavedFiltersModalTypes.Project[]>(`projects`, {
    withFilters: 1,
  }).pipe(shareReplay());

  public $projectHasNoFilters = combineLatest([this.$projects, this.$projectId]).pipe(
    map(([projects, projectId]) => this.modalMode !== 'global' && !projects.find((p) => p.id === projectId)),
    distinctUntilChanged(),
    shareReplay()
  );

  public $selectedFilterProjectId = merge(
    this.$selectedFilter.pipe(map((f) => f?.projectId)),
    this.$getState('projectId')
  ).pipe(
    filter((r) => !!r),
    distinctUntilChanged(),
    startWith(null),
    shareReplay()
  );

  private $filtersWithMeta = combineLatest([
    this.$projectId,
    this.$page,
    this.$search.pipe(debounceTime(300)),
    this.$getState('refetchDateFiltersList'),
  ]).pipe(
    distinctUntilChanged(),
    switchMap(([projectId, page, search]) =>
      $getWithMeta<SavedFiltersModalTypes.SavedFilter[]>('filters', {
        projectId: projectId,
        page,
        search,
      }).pipe(
        tap(([data, _meta]) => {
          if (data?.length && !this.currentState('search')) this.selectFilter(data[0].id);
        }),
        startWith(null)
      )
    ),
    shareReplay()
  );
  public $filters = this.$filtersWithMeta.pipe(map((f) => (f?.length ? f[0] : null)));

  public $filtersMeta = this.$filtersWithMeta.pipe(map((f) => (f?.length ? f[1] : null)));

  public $renameMode = combineLatest([this.$getState('renameMode'), this.$selectedFilter]).pipe(
    tap(
      ([renameMode, selectedFilter]) =>
        renameMode && this.renameForm.setValue('name', selectedFilter?.name || '', { shouldValidate: true })
    ),
    map(([renameMode]) => renameMode)
  );

  public modalMode: 'fromActivity' | 'fromProject' | 'global' = (() => {
    if (this.activityId) return 'fromActivity';
    if (!this.activityId && this.projectId) return 'fromProject';
    return 'global';
  })();

  constructor(
    private readonly setQuery: SetQuery<object>,
    public readonly closeModal: SavedFiltersModalTypes.SavedFiltersModalProps['onClose'],
    private readonly projectId: SavedFiltersModalTypes.SavedFiltersModalProps['projectId'],
    public readonly activityId: SavedFiltersModalTypes.SavedFiltersModalProps['activityId'],
    public readonly activityType: SavedFiltersModalTypes.SavedFiltersModalProps['activityType'],
    public readonly renameForm: UseFormReturn<RenameFormValues>
  ) {
    super({
      search: '',
      refetchDateFiltersList: new Date(),
      page: 1,
      projectId,
      availableCategories: [],
    });
  }

  public onInit = () => {
    if (this.projectId) this.setState('projectId', this.projectId);

    this.addSub(
      this.$selectedFilter.pipe(map((f) => f?.projectId)).subscribe((f) => f && this.fetchCategories(f))
    );
  };

  public fetchFilterData = (id: string) => {
    this.setState('loading', 'filters');
    this.addSub(
      $get<SavedFiltersModalTypes.SavedFilter>(`filters/${id}`)
        .pipe(finalize(() => this.setState('loading', 'loaded')))
        .subscribe((data) => this.setState('selectedFilter', data)),
      'fetchFilterData'
    );
  };

  public fetchCategories = (projectId: string) => {
    this.setState('loadingCategories', true);
    this.addSub(
      $get<FiltersTypes.AvailableFilterCategory[]>('filters/available-categories', {
        projectId: projectId,
      })
        .pipe(
          catchError(() => []),
          finalize(() => this.setState('loadingCategories', false))
        )
        .subscribe((data) => this.setState('availableCategories', data)),
      'fetchCategories'
    );
  };

  public paginate = (page: number) => this.setState('page', page);

  public setProject = (projectId: SavedFiltersModalTypes.Project['id']) => {
    this.setState('projectId', projectId);
    this.setState('search', '');
  };

  public setSearch = (search: string) => this.setStates({ search, page: 1 });

  public setRenameMode = (active?: boolean) => this.setState('renameMode', active);

  public selectFilter = (filterId: SavedFiltersModalTypes.SavedFilter['id'] | null) => {
    this.setState('selectedFilterId', filterId);
    if (filterId) this.fetchFilterData(filterId);
  };

  public renameFilter = () => {
    const id = this.currentState('selectedFilterId');
    this.setState('loading', 'renaming');
    this.addSub(
      $put(`filters/${this.currentState('selectedFilterId')}`, { name: this.renameForm.getValues().name })
        .pipe(finalize(() => this.setState('loading', 'loaded')))
        .subscribe({
          next: () => {
            this.setRenameMode(false);
            this.setStates({
              refetchDateFiltersList: new Date(),
            });
            toast({
              severity: 'success',
              summary: 'Filter renamed successfully',
            });
            if (id) this.fetchFilterData(id);
          },
          error: () => {
            this.renameForm.setError('name', { message: 'Name already exists. Try a different name.' });
          },
        }),
      'renameFilter'
    );
  };

  public deleteFilter = () => {
    this.setState('loading', 'deleting');
    this.addSub(
      this.$filtersMeta
        .pipe(
          first(),
          switchMap((filtersMeta) =>
            $delete(`filters/${this.currentState('selectedFilterId')}`).pipe(map(() => filtersMeta))
          ),
          finalize(() => this.setState('loading', 'loaded'))
        )
        .subscribe({
          next: (filtersMeta) => {
            this.selectFilter(null);
            this.setStates({
              // If deleted filter was the only one from current page, reset to page 1
              ...((filtersMeta?.currentPage || 2) > Math.floor((filtersMeta?.total || 0) / 10) + 1
                ? {
                    page: Math.floor((filtersMeta?.total || 0) / 10) + 1 || 1,
                    refetchDateFiltersList: new Date(),
                  }
                : { refetchDateFiltersList: new Date() }),
            });
            toast({
              severity: 'success',
              summary: 'Filter was deleted successfully',
            });
          },
          error: () => {
            toast({
              severity: 'error',
              summary: 'Failed to delete filter',
            });
          },
        }),
      'deleteFilter'
    );
  };

  public copyToActivity = () => {
    this.setState('loading', 'copying');
    if (this.activityType === 'cache') {
      this.addSub(
        this.$selectedFilter
          .pipe(
            filter((filterRaw) => !!filterRaw),
            first(),
            switchMap((filterRaw) =>
              $post<{ cachedFilterId: string }>(`filters/cache`, {
                criteria: filterRaw?.criteria,
              }).pipe(finalize(() => this.setState('loading', 'loaded')))
            )
          )
          .subscribe({
            next: (values) => {
              this.setQuery({
                filterId: values?.cachedFilterId,
              });
              snackbar({
                severity: 'info',
                label: 'Saved filter applied',
                life: 5000,
                closable: true,
              });
              this.closeModal({ reset: true, filterId: values?.cachedFilterId });
            },
            error: () => {
              snackbar({
                severity: 'error',
                label: 'Failed to apply saved filter',
                life: 5000,
                closable: true,
              });
            },
          })
      );
    } else {
      this.addSub(
        combineLatest([this.$selectedFilter, this.$projectId])
          .pipe(
            filter(([filterRaw, projectId]) => !!filterRaw && !!projectId),
            first(),
            switchMap(([filterRaw, projectId]) =>
              $post(`filters/${this.currentState('selectedFilterId')}/copy-to-activity`, {
                projectId,
                criteria: filterRaw?.criteria,
                [this.activityType === 'explore'
                  ? 'exploreId'
                  : this.activityType === 'chart'
                  ? 'widgetChartId'
                  : 'hyTaskId']: this.activityId,
              })
            ),
            finalize(() => this.setState('loading', 'loaded'))
          )
          .subscribe({
            next: () => {
              snackbar({
                severity: 'info',
                label: 'Saved filter applied',
                life: 5000,
                closable: true,
              });
              this.closeModal({ reset: true });
            },
            error: () => {
              snackbar({
                severity: 'error',
                label: 'Failed to apply saved filter',
                life: 5000,
                closable: true,
              });
            },
          }),
        'copyToActivity'
      );
    }
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useSavedFiltersModalBLoC = () => useBLoC<BLoC>(Context);

export const SavedFiltersModalBLoC: React.FC<
  BLoCParams<BLoC, State> & SavedFiltersModalTypes.SavedFiltersModalProps
> = ({ onClose, projectId, activityId, activityType, children }) => {
  const queryParams = useQueryParams({ filterId: StringParam });
  const renameForm = useForm({
    defaultValues: new RenameFormValues(),
    mode: 'all',
  });
  const bloc = useInitBloc(
    () => new BLoC(queryParams[1], onClose, projectId, activityId, activityType, renameForm)
  );
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
