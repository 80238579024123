import React from 'react';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { MapStepColumnDataStyle as S } from './MapStepColumnData.style';

export const MapStepColumnData: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({ column }) => {
  return (
    <S.Container>
      <S.FirstSection>
        <S.RowText>SAMPLE PREVIEW</S.RowText>
        <S.RowText data-testid="label-map-step-rows">{column.rows} ROWS</S.RowText>
      </S.FirstSection>
      {(column.preview || []).slice(0, 3).map((text, i) => (
        <S.TextContainer key={i}>
          <S.Text>{text}</S.Text>
        </S.TextContainer>
      ))}
    </S.Container>
  );
};
