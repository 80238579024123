import React, {
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  FileUpload as FileUploadPrime,
  FileUploadHandlerEvent,
  FileUploadProps,
  ItemTemplateOptions,
} from 'primereact/fileupload';
import { FileUploadEmptyState } from './components/FileUploadEmptyState/FileUploadEmptyState';
import { FileUploadItem } from './components/FileUploadItem/FileUploadItem';
import { FileUploadItemMultiple } from './components/FileUploadItemMultiple/FileUploadItemMultiple';
import { FileUploadStyle as S } from './FileUpload.style';
import { useClassNames } from 'hooks/useClassNames';

/**
 * @deprecated Use FileUploadV2 instead
 */
export type FileUploadRefAttributes = {
  open: () => void;
};

/**
 * @deprecated Use FileUploadV2 instead
 */
export const FileUpload = forwardRef<
  FileUploadRefAttributes,
  PropsWithChildren<FileUploadProps> & {
    confirmComponent?: React.ReactNode;
    fullHeight?: boolean;
    addFile: (e: FileUploadHandlerEvent, clearFn: (files?: File[]) => void) => void;
    emptyStateDescription: ReactNode;
    fileIsDuplicate?: boolean;
    hideEmptyStateIcon?: boolean;
    files?: File[];
    maxFileNumber?: number;
    customItemTemplate?: (file: object, options: ItemTemplateOptions) => React.ReactNode;
  }
>((props, ref) => {
  const fileComponent = useRef<FileUploadPrime | null>(null);
  const openFn = useCallback(() => fileComponent?.current?.getInput().click(), [fileComponent]);
  const containerClassNames = useClassNames(
    {
      'file-upload-with-files': !!props.files?.length,
    },
    'file-upload-container'
  );
  const maxNumberOfFilesReached =
    (props.files?.length || 0) >= (props.maxFileNumber || Number.MAX_SAFE_INTEGER);

  useImperativeHandle(
    ref,
    () => ({
      open: () => openFn(),
    }),
    [openFn]
  );

  return (
    <S.Container
      fullHeight={props.fullHeight}
      $onTop={!!(props.multiple && (props.files?.length || 0) > 10)}
      className={containerClassNames}
    >
      <S.FileComponent>
        <S.FileUpload
          {...props}
          ref={fileComponent}
          customUpload
          auto
          name="update-step-file"
          emptyTemplate={
            !props.hideEmptyStateIcon && (
              <FileUploadEmptyState
                description={props.emptyStateDescription}
                hideIcon={props.hideEmptyStateIcon}
                multiple={props.multiple}
                {...{ openFn }}
              />
            )
          }
          itemTemplate={
            props.customItemTemplate
              ? props.customItemTemplate
              : (file, o) =>
                  !props.multiple ? (
                    <FileUploadItem
                      {...{ openFn }}
                      fileIsDuplicate={props.fileIsDuplicate}
                      file={file as File}
                    />
                  ) : (
                    <FileUploadItemMultiple
                      file={file as File}
                      options={o}
                      maxFileSize={props.maxFileSize}
                      accept={props.accept}
                    />
                  )
          }
          uploadHandler={(e) => {
            let finalFiles =
              typeof props.maxFileNumber === 'number' ? e.files.slice(0, props.maxFileNumber) : e.files;
            props.addFile({ files: finalFiles, options: e.options }, (files) => {
              setTimeout(() => {
                if (files) {
                  fileComponent.current?.setFiles(
                    finalFiles.filter((file) => !files.map((f) => f.name).includes(file.name))
                  );
                } else fileComponent.current?.clear();
              }, 10);
            });
            setTimeout(
              () => typeof props.maxFileNumber === 'number' && fileComponent.current?.setFiles(finalFiles)
            );
          }}
        />
      </S.FileComponent>
      {!!(props.multiple && props.files?.length && !props.hideEmptyStateIcon) && (
        <S.Link className="file-upload-browse-more-link" onClick={openFn} disabled={maxNumberOfFilesReached}>
          Browse more
        </S.Link>
      )}
      {props.confirmComponent && <S.ConfirmContainer>{props.confirmComponent}</S.ConfirmContainer>}
    </S.Container>
  );
});
