import React from 'react';
import { LeftMenuBurgerStyle as S } from './LeftMenuBurger.style';

export const LeftMenuBurger: React.FC = () => {
  return (
    <S.Icon
      onClick={() => {
        localStorage.setItem('side-menu-hidden', 'true');
        document.body.classList.add('side-menu-hidden');
      }}
    />
  );
};
