import styled from 'styled-components';
import { InputNumber } from 'primereact/inputnumber';
import {
  padding,
  borderRadius,
  colourPalette,
  borders,
  hexToRGBA,
  fontSize,
  lineHeight,
} from 'styles/styles';

export const PInputNumberStyle = {
  InputNumber: styled(InputNumber)`
    &.p-inputnumber {
      width: 100%;
      & > .p-inputnumber-input {
        width: 100%;
        border-radius: ${borderRadius.roundedSM};
        padding: 0px ${padding.paddingMD};
        background-color: ${colourPalette.neutral0};
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        display: flex;
        align-items: center;
        min-height: 40px;
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
        color: ${colourPalette.neutral600};
        &::placeholder {
          color: ${colourPalette.neutral400};
        }
        &:hover {
          border-color: ${colourPalette.gray300};
          background-color: ${colourPalette.neutral100};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
          border-color: ${colourPalette.blue500};
          background-color: ${colourPalette.neutral0};
          color: ${colourPalette.neutral600};
          &::placeholder {
            color: ${colourPalette.neutral400};
          }
        }
      }
      &.p-invalid > .p-inputnumber-input {
        border-color: ${colourPalette.rose500};
        color: ${colourPalette.rose600};
        &::placeholder {
          color: ${colourPalette.rose600};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
          border-color: ${colourPalette.blue500};
          background-color: ${colourPalette.neutral0};
          color: ${colourPalette.neutral600};
          &::placeholder {
            color: ${colourPalette.neutral400};
          }
        }
      }
      &.p-inputwrapper-focus > .p-inputnumber-input {
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        border-color: ${colourPalette.blue500};
        background-color: ${colourPalette.neutral0};
        color: ${colourPalette.neutral600};
        &::placeholder {
          color: ${colourPalette.neutral400};
        }
      }
      & > .p-inputnumber-input.p-disabled {
        opacity: 1;
        background-color: ${colourPalette.neutral50};
        border-color: ${colourPalette.gray200};
        color: ${colourPalette.neutral300};
        &::placeholder {
          color: ${colourPalette.neutral300};
        }
      }
      &.p-inputnumber-sm > .p-inputnumber-input {
        min-height: 32px;
      }
      &.p-inputnumber-lg > .p-inputnumber-input {
        min-height: 56px;
        font-size: 18px;
      }
    }
  `,
};
