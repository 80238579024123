import React from 'react';
import { SnackbarService } from 'services/snackbar';
import { SnackbarV2Service } from 'services/snackbarV2';
import { ToastService } from 'services/toast';
import { NotificationService } from 'services/NotificationService/NotificationService';
import { UserInactivityTimer } from 'services/tokens';
import { ConfirmDialogV2Service } from 'services/confirmDialogV2';
import { ModalService } from 'services/ModalService/ModalService';
import { NavigateGlobal } from 'services/navigate';

// global loader widget.
// Will be loaded once for react and laravel
const GlobalLoader: React.FC = () => {
  return (
    <>
      <NavigateGlobal />
      <ModalService />
      <ToastService />
      <SnackbarService />
      <SnackbarV2Service />
      <NotificationService />
      <ConfirmDialogV2Service />
      <UserInactivityTimer />
    </>
  );
};
export default GlobalLoader;
