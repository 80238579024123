import React, { useMemo } from 'react';
import { HeyYabbleCountStepTableRowCodeFramesStyle as S } from './HeyYabbleCountStepTableRowCodeFrames.style';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { useObservableState } from 'observable-hooks';

export const HeyYabbleCountStepTableRowCodeFrames: React.FC<{
  idCol: number;
  recommendedCodeframeId?: number | null;
  recommendedPipelineId?: number | null;
  isChecked: boolean;
}> = ({ idCol, recommendedCodeframeId, isChecked, recommendedPipelineId }) => {
  const { $codeFrames, $pipelines, handleChangeCodeFrameId } = useHeyYabbleCountStepBLoC();
  const codeFrames = useObservableState($codeFrames, []);
  const pipelines = useObservableState($pipelines, []);

  const selectedPipeline = useMemo(() => {
    return pipelines.find((p) => p.id === recommendedPipelineId);
  }, [pipelines, recommendedPipelineId]);

  const isDisabled = useMemo(() => {
    return !isChecked || !selectedPipeline?.allowsCodeframe;
  }, [isChecked, selectedPipeline]);

  return (
    <S.Container>
      <S.Dropdown
        value={recommendedCodeframeId}
        optionValue="id"
        optionLabel="name"
        options={codeFrames}
        placeholder="Select a code frame"
        onChange={(e) => handleChangeCodeFrameId(idCol, e.target.value)}
        disabled={isDisabled}
      />
    </S.Container>
  );
};
