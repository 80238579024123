import styled from 'styled-components';

import { colours } from 'styles/styles';

export const TopBarBannerStyle = {
  Container: styled.div`
    position: fixed;
    width: 100vw;
    height: 56px;
    z-index: 1001;
    display: flex;
    align-items: center;
    background-color: ${colours.uiDangerDisabled};
    padding: 0px 28px;
    left: 0px;
    top: 0px;
    & > svg {
      path {
        fill: ${colours.uiDanger};
      }
    }
  `,
  Message: styled.div`
    color: ${colours.neutralCharcoal};
    & > a {
      text-decoration: underline;
      color: ${colours.neutralCharcoal};
    }
  `,
};
