import { UserIconStyle as S } from './UserIcon.style';
import React from 'react';
import { colours } from 'styles/styles';
// const djb2 = (str: string) => {
//   let hash = 5381;
//   for (var i = 0; i < str.length; i++) {
//     hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
//   }
//   return hash;
// };
export const UserIcon: React.FC<{
  user?: { firstName: string; lastName: string } | null;
  disabled?: boolean;
}> = ({ user, disabled }) => {
  // const colour = useMemo(() => {
  //   const hash = djb2(user?.firstName || '___' + user?.lastName || '___');
  //   let r = (hash & 0xff0000) >> 16;
  //   let g = (hash & 0x00ff00) >> 8;
  //   let b = hash & 0x0000ff;
  //   return (
  //     '#' +
  //     ('0' + r.toString(16)).substr(-2) +
  //     ('0' + g.toString(16)).substr(-2) +
  //     ('0' + b.toString(16)).substr(-2)
  //   ).replace('f', '1');
  // }, [user]);
  return (
    <S.Container $colour={disabled ? colours.neutralGrey04 : colours.primaryGreen}>
      <S.Text>
        {user?.firstName?.length && user.firstName[0]}
        {user?.lastName?.length && user.lastName[0]}
      </S.Text>
    </S.Container>
  );
};
