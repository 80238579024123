import React from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { PColumn } from 'components/prime/PDataTable/components/PColumn/PColumn';
import { PColumnBody } from 'components/prime/PDataTable/components/PColumnBody/PColumnBody';
import { useObservableState } from 'observable-hooks';
import { ListPanelFiltersTableStyle as S } from './ListPanelFiltersTable.style';
import { SavedFiltersModalTypes } from 'components/modals/SavedFiltersModal/SavedFiltersModal.types';
import { FiltersTableFilterItem } from './components/FiltersTableFilterItem/FiltersTableFilterItem';

export const ListPanelFiltersTable: React.FC = () => {
  const { $filters, $filtersMeta, paginate } = useSavedFiltersModalBLoC();
  const filters = useObservableState($filters);
  const filtersMeta = useObservableState($filtersMeta);

  return (
    <S.Container>
      <S.DataTable
        value={filters || (Array.from({ length: 5 }) as [])}
        paginator
        rows={filtersMeta?.perPage || 10}
        lazy
        onPage={(e: any) => paginate((e.page || 0) + 1)}
        totalRecords={filtersMeta?.total || 0}
        first={(filtersMeta?.from || 1) - 1}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
        emptyMessage="No filters available"
      >
        <PColumn
          header="Filter"
          body={(r: SavedFiltersModalTypes.SavedFilter) => (
            <PColumnBody loading={!filters}>
              {r && <FiltersTableFilterItem id={r.id} name={r.name} />}
            </PColumnBody>
          )}
        />
      </S.DataTable>
    </S.Container>
  );
};
