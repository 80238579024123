import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild } from 'types/BLoCBase';
import { distinctUntilChanged, map } from 'rxjs';
import { ModalServiceTypes, modals } from './ModalService.types';

type State = ModalServiceTypes.ModalServiceBLoCState;

export let openGlobalModal: ModalServiceTypes.ModalOpenFunc;

class BLoC extends BLoCBase<State> {
  private $active = this.$getState('active');

  public $modals = this.$active.pipe(
    map((active) =>
      Object.entries(active).map(([type, props]) => {
        if (!props) return null;
        const Component = modals[type as keyof typeof modals];
        return {
          type,
          modal: (
            <Component
              {...(props as any)}
              onClose={(...args) => {
                (props.onClose as any)?.(...args);
                this.closeGlobalModal(type as keyof typeof modals);
              }}
              key={type}
            />
          ),
        };
      })
    ),
    distinctUntilChanged()
  );

  constructor() {
    super({ active: {} });
    openGlobalModal = this.openGlobalModal;
  }

  private prev: ModalServiceTypes.ModalPrevFunc = (typeCurrent) => {
    const prevModal = this.currentState('prev');
    const [typePrev, propsPrev] = Object.entries(prevModal || {})[0];
    this.closeGlobalModal(typeCurrent);
    !!typePrev && this.openGlobalModal(typePrev as any, propsPrev as any);
  };

  private closeGlobalModal: ModalServiceTypes.ModalCloseFunc = (type) => {
    const currentProps = this.currentState('active')[type];
    this.mutateState('active', (prev) => ({ ...prev, [type]: null }));
    this.setState('prev', { [type]: currentProps });
  };

  public openGlobalModal: ModalServiceTypes.ModalOpenFunc = (type, payload) => {
    const p = typeof payload === 'function' ? payload(() => this.prev(type)) : payload;
    this.mutateState('active', (prev) => ({ ...prev, [type]: p || {} }));
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useModalServiceBLoC = () => useBLoC<BLoC>(Context);

export const ModalServiceBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const bloc = useInitBloc(() => new BLoC());
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
