import styled from 'styled-components';
import { colours } from 'styles/styles';

export const ImportModalSelectFlowTitleStyle = {
  Container: styled.div`
    h3 {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: ${colours.neutralCharcoal};
      margin: 0;
    }
    p {
      line-height: 24px;
      color: ${colours.neutralLightCharcoal};
      margin: 14px 0 0;
    }
  `,
};
