import React from 'react';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { useClassNames } from 'hooks/useClassNames';
import { MenuItem } from 'primereact/menuitem';
import { ImportModalHeaderStyle as S } from './ImportModalHeader.style';
import { PBreadCrumb } from 'components/prime/PBreadCrumb/PBreadCrumb';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';

export const ImportModalHeader: React.FC<{ steps?: MenuItem[]; title: string; subtitle?: string }> = ({
  steps,
  title,
  subtitle,
}) => {
  const nameBoxClassNames = useClassNames({
    'has-subtitle': !!subtitle,
  });
  return (
    <S.Container>
      <S.NameBox className={nameBoxClassNames}>
        <S.Title>{title}</S.Title>
        {subtitle && (
          <S.Subtitle>
            <span>{subtitle}</span>
          </S.Subtitle>
        )}
      </S.NameBox>
      <S.Box>
        {steps && (
          <>
            <S.BreadCrumbContainer>
              <PBreadCrumb model={steps} homeHidden={true} />
            </S.BreadCrumbContainer>
            <S.Divider />
          </>
        )}
        <a
          href="https://intercom.help/yabblezone/en/"
          target="_blank"
          rel="noopener noreferrer"
          className="block question-icon"
        >
          <PButtonIcon icon="annotationQuestionSolid" />
        </a>
        <PTooltip target=".question-icon" position="left" content="Help center" />
      </S.Box>
    </S.Container>
  );
};
