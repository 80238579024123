import React from 'react';
import { useObservableState } from 'observable-hooks';
import { useImportModalCsvBLoC } from '../../ImportModalCsv.bloc';
import { ImportModalHeader } from '../../../ImportModalHeader/ImportModalHeader';

export const ImportModalCsvHeader: React.FC = () => {
  const { $breadcrumbOptions, isEmptyProject, projectName } = useImportModalCsvBLoC();
  const breadcrumbOptions = useObservableState($breadcrumbOptions);

  return (
    <ImportModalHeader
      title={isEmptyProject ? 'New import' : 'Add file'}
      subtitle={!isEmptyProject ? projectName : ''}
      steps={breadcrumbOptions}
    />
  );
};
