import { catchError, combineLatest, filter, map, of, shareReplay, switchMap } from 'rxjs';
import { BLoCBase } from 'types/BLoCBase';
import { $get } from 'services/api';
import type { BillingPlan, SubscriptionInfo, BillinPlanPriceInfo } from 'types/BillingPlan';
import { getCustomerStore } from './customer.store';

class BillingPlanStore extends BLoCBase<{ subscriptionInfo?: SubscriptionInfo }> {
  public readonly $rawProducts = $get<Omit<BillingPlan, 'price'>[]>('billing/products').pipe(
    catchError(() => of([])),
    shareReplay()
  );
  public readonly $products = getCustomerStore().$currency.pipe(
    switchMap((currency) =>
      this.$rawProducts.pipe(
        map(
          (products) =>
            products
              .map((p) => {
                const price =
                  p.stripePrices.length === 1
                    ? { ...p.stripePrices[0] }
                    : { ...this.getPriceByCustomerCurrency(p.stripePrices, currency.short) };
                price.unitAmount = (price?.unitAmount || 0) / 100;
                return { ...p, price };
              })
              .sort((a, b) => (a.price?.unitAmount || 0) - (b.price?.unitAmount || 0)) || []
        )
      )
    )
  );
  public readonly $plans = this.$products.pipe(
    map((products) =>
      products.filter((p) => p.metadata.isAddon !== 'true' && p.metadata.productType === 'subscription')
    )
  );
  public readonly $addons = this.$products.pipe(
    map((products) =>
      products.filter((p) => p.metadata.isAddon === 'true' && p.metadata.productType === 'my_panel')
    )
  );
  public readonly $licenses = this.$products.pipe(
    map((products) => products.filter((p) => p.metadata.productType === 'license'))
  );
  public readonly $subscriptionInfo = this.$getState('subscriptionInfo');
  public readonly $myPanelEnabled = this.$getState('subscriptionInfo').pipe(
    map((s) => s?.currentSubscription.isMypanelEnabled || false)
  );
  public readonly $currentPlan = combineLatest([this.$plans, this.$subscriptionInfo]).pipe(
    map(([plans, sub]) => plans.find((p) => p.id === sub?.currentSubscription.productId))
  );
  public readonly $creditsBalance = this.$subscriptionInfo.pipe(
    filter((si) => !!si),
    map((si) => si?.creditsBalance)
  );
  public readonly $pricePerCredit = this.$subscriptionInfo.pipe(map((si) => si?.pricePerCredit || 0));

  constructor() {
    super({});
    this.fetchSubscriptionInfo();
  }

  public fetchSubscriptionInfo = () => {
    this.addSub(
      $get<SubscriptionInfo>('billing/subscription').subscribe(
        (si) => si && this.setState('subscriptionInfo', si)
      ),
      'fetchSubscriptionInfo'
    );
  };

  private getPriceByCustomerCurrency = (prices: BillinPlanPriceInfo[], currency: string) =>
    prices.find((p) => p.currency === currency.toLowerCase()) || prices[0] || null;
}

let store: Readonly<BillingPlanStore> | null = null;
export const getBillingPlanStore = () => {
  if (!store) {
    store = Object.freeze(new BillingPlanStore());
  }
  return store;
};
