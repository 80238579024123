import React from 'react';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { PButton } from 'components/prime/PButton/PButton';
import { Box } from 'components/layouts/primitives/Box/Box';
import { usePersonaLibraryModalBLoC } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.bloc';

export const DetailsPanelFooter: React.FC = () => {
  const { setPersonaGroup } = usePersonaLibraryModalBLoC();
  return (
    <Box
      backgroundColor="neutral0"
      className="sticky bottom-0 z-1"
      padding={['paddingLG', 'padding2XL', 'padding2XL', 'padding2XL']}
    >
      <HStack justify="flex-end">
        <PButton label="Load this persona group" onClick={setPersonaGroup} />
      </HStack>
    </Box>
  );
};
