import React from 'react';
import { ImportModalContent } from 'components/modals/ImportModal/components/ImportModalContent/ImportModalContent';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalLoading } from 'components/modals/ImportModal/components/ImportModalLoading/ImportModalLoading';
import { ImportModalMainSection } from 'components/modals/ImportModal/components/ImportModalMainSection/ImportModalMainSection';
import { useObservableState } from 'observable-hooks';
import { useUploadStepBLoC } from '../../UploadStep.bloc';
import { UploadStepSelecFileBox } from '../UploadStepSelecFileBox/UploadStepSelecFileBox';
import { TypeOfDataImportNameInput } from '../UploadStepTypeOfData/components/TypeOfDataImportNameInput/TypeOfDataImportNameInput';
import { UploadStepTypeOfData } from '../UploadStepTypeOfData/UploadStepTypeOfData';
import { UploadStepContentStyle as S } from './UploadStepContent.style';
import { FullWidthTip } from './components/FullWidthTip/FullWidthTip';
import { UploadStepError } from '../UploadStepError/UploadStepError';

export const UploadStepContent: React.FC = () => {
  const { projectId } = useImportModalCsvBLoC();
  const { $loading } = useUploadStepBLoC();
  const loading = useObservableState($loading, false);

  return (
    <ImportModalContent
      isLoading={loading}
      loadingComponent={
        <ImportModalLoading
          text="Checking your file ..."
          longTimeThresholdText="Larger dataset may take a little longer..."
        />
      }
    >
      <ImportModalMainSection title="Upload file" />
      <UploadStepError />
      <FullWidthTip />
      {!!projectId && (
        <S.ImportNameWrapper>
          <TypeOfDataImportNameInput />
        </S.ImportNameWrapper>
      )}
      <S.Content>
        <UploadStepSelecFileBox />
        {!projectId && <UploadStepTypeOfData />}
      </S.Content>
    </ImportModalContent>
  );
};
