import styled from 'styled-components';
import { cssScreens } from 'styles/styles';

export const UploadStepContentStyle = {
  Container: styled.div``,
  Content: styled.div`
    display: flex;
    @media screen and (max-width: ${cssScreens.tablet}) {
      flex-direction: column;
    }
  `,
  Text: styled.p`
    margin: 0;
  `,
  ImportNameWrapper: styled.div`
    max-width: 400px;
    padding-bottom: 4px;
  `,
};
