import React from 'react';
import { TopBarChatButtonStyle as S } from './TopBarChatButton.style';
import { useChatModalBLoC } from 'components/modals/ChatModal/ChatModal.bloc';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { useHasFF } from 'stores/ff.store';

export const TopBarChatButton: React.FC = () => {
  const hasChatFF = useHasFF('ff-chat');
  const context = useChatModalBLoC();
  return hasChatFF && context?.openModal ? (
    <S.Container className="top-bar-chat-button" data-testid="icon-top-bar-gen-chat">
      <S.YabbleGenAvatar onClick={() => context.openModal()} />
      <PTooltip target={`.top-bar-chat-button`} position="bottom" my="center top+4" content="Chat with Gen" />
    </S.Container>
  ) : null;
};
