import { Loader } from 'components/Loader/Loader';
import React from 'react';
import { ImportModalLoadingStyle as S } from './ImportModalLoading.style';
import { useTimer } from 'hooks/useTimer';

export const ImportModalLoading: React.FC<{ text?: string; longTimeThresholdText?: string }> = ({
  text,
  longTimeThresholdText,
}) => {
  const seconds = useTimer(10, !!longTimeThresholdText);
  return (
    <S.Container>
      <Loader />
      <S.Text data-testid="label-import-modal-loading">
        {seconds === 0 ? longTimeThresholdText : text || ''}
      </S.Text>
    </S.Container>
  );
};
