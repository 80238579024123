import React from 'react';
import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import { PFormField } from 'components/prime/PFormField/PFormField';
import { useUploadStepBLoC } from '../../../../UploadStep.bloc';
import { UploadStepSelectFileBoxConfirmStyle as S } from './UploadStepSelectFileBoxConfirm.style';
import { LinkText } from 'components/LinkText/LinkText';

export const UploadStepSelectFileBoxConfirm: React.FC = () => {
  const { uploadForm } = useUploadStepBLoC();
  return (
    <S.Container>
      <PFormField
        form={uploadForm}
        showError="never"
        controler={{
          name: 'confirm',
          rules: {
            validate: (r) => !!r,
          },
        }}
      >
        {({ field }) => (
          <PCheckbox
            labelClassName="upload-step-checkbox"
            onChange={(e) => field.onChange(e.checked)}
            checked={field.value}
            label={
              <S.CheckboxText>
                I confirm my file does not contain legal, medical, political or personal data and I have full
                permission to use it. For more details, view our{' '}
                <LinkText href="https://content.yabble.com/acceptable.use" target="_blank">
                  Data Policy.
                </LinkText>
              </S.CheckboxText>
            }
            data-testid="checkbox-upload-step-data-policy"
          />
        )}
      </PFormField>
    </S.Container>
  );
};
