import React from 'react';
import { useUploadStepBLoC } from '../../UploadStep.bloc';
import { UploadStepSelectFileBoxConfirm } from './components/UploadStepSelectFileBoxConfirm/UploadStepSelectFileBoxConfirm';
import { UploadStepSelecFileBoxStyle as S } from './UploadStepSelecFileBox.style';
import { useObservableState } from 'observable-hooks';
import { FileUpload } from 'components/FileUpload/FileUpload';

export const UploadStepSelecFileBox: React.FC = () => {
  const { fileChangeEvent, $fileIsDuplicate, projectId } = useUploadStepBLoC();
  const fileIsDuplicate = useObservableState($fileIsDuplicate);

  return (
    <S.Container hasProjectId={!!projectId}>
      <FileUpload
        accept=".csv"
        confirmComponent={<UploadStepSelectFileBoxConfirm />}
        fullHeight={!projectId}
        emptyStateDescription="Supports CSV files only"
        fileIsDuplicate={fileIsDuplicate?.exists}
        addFile={(event, clearFn) =>
          event.files.length && fileChangeEvent(event.files[event.files.length - 1], () => clearFn())
        }
      />
    </S.Container>
  );
};
