import React from 'react';
import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { PSelectButtonV2 } from 'components/prime/PSelectButtonV2/PSelectButtonV2';
import { Text } from 'components/Text/Text';
import { usePersonaLibraryModalBLoC } from 'components/modals/PersonaLibraryModal/PersonaLibraryModal.bloc';
import { useObservableState } from 'observable-hooks';
import { FormattedDate } from 'components/FormattedDate/FormattedDate';

const options = [
  { value: 'personas', label: 'Personas' },
  { value: 'projects', label: 'Projects' },
];

export const DetailsPanelHeader: React.FC = () => {
  const { $selectedSection, $selectedGroup, setSelectedSection } = usePersonaLibraryModalBLoC();
  const selectedGroup = useObservableState($selectedGroup);
  const selectedSection = useObservableState($selectedSection);

  return (
    <Box backgroundColor="neutral0" className="sticky top-0 z-1" padding={['paddingXL', 'padding2XL']}>
      <HStack gap="gap2" align="center" justify="space-between">
        <VStack>
          <Text as="h4" size="fontSizeLG" weight="medium" lineHeight="lineHeightXL" color="neutral600">
            {selectedGroup?.name}
          </Text>
          <Text size="fontSizeXS" lineHeight="lineHeightSM" color="neutral500">
            Created <FormattedDate date={selectedGroup?.createdAt} format="MMMM dd, yyyy" /> |{' '}
            {selectedGroup?.createdBy.name}
          </Text>
        </VStack>
        <Box fit="content">
          <PSelectButtonV2
            options={options}
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.value)}
          />
        </Box>
      </HStack>
    </Box>
  );
};
