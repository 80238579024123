export type ParseFunc<T = string> = (val: string | null) => T | null;

export const getQueryParams = (
  params: { [key in string]: ParseFunc<any> },
  query: string = window.location.search
) => {
  const qp = new URLSearchParams(query);
  return Object.keys(params).reduce((prev, cur: string) => {
    prev[cur] = params[cur](qp.get(cur));
    return prev;
  }, {} as any);
};
