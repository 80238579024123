import styled from 'styled-components';

export const SavedFiltersModalDetailsPanelStyle = {
  Container: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    &.panel-blocked {
      pointer-events: none;
      opacity: 0.5;
    }
  `,
};
