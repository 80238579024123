import { LeftMenuWidget } from 'modules/widgets/LeftMenuWidget/LeftMenuWidget'; // not lazy to speed up initial loading
import { TopBarWidget } from 'modules/widgets/TopBarWidget/TopBarWidget';
import { lazyRefresh } from 'utils/lazyRefresh';

const AdminWidgetModule = lazyRefresh(() => import('modules/admin-widgets/AdminWidgetModule'));
const GlobalLoader = lazyRefresh(() => import('modules/widgets/GlobalLoader/GlobalLoader'));
const TwoFAWidget = lazyRefresh(() => import('modules/widgets/TwoFAWidget/TwoFAWidget'));
const ProjectDetailsTabWidget = lazyRefresh(
  () => import('modules/widgets/ProjectDetailsTabWidget/ProjectDetailsTabWidget')
);
const AdminTabWidget = lazyRefresh(() => import('modules/admin-widgets/AdminTabWidget/AdminTabWidget'));

export const widgetList = {
  topBar: TopBarWidget,
  leftMenu: LeftMenuWidget,
  adminSectionTab: AdminWidgetModule,
  projectDetailsTab: ProjectDetailsTabWidget,
  adminTab: AdminTabWidget,
  globalLoader: GlobalLoader,
  react2faAuth: TwoFAWidget,
};

export type Widget = keyof typeof widgetList;
