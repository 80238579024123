import { MutableRefObject, useEffect, useState } from 'react';

export const useContainerWidth = (
  containerRef: MutableRefObject<any>,
  options: { disable?: boolean } = {}
) => {
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const element = !options.disable ? containerRef.current : null;
    const resizeObserver = !options.disable
      ? new ResizeObserver((event) => {
          const width =
            event?.length && event[0].contentBoxSize?.length
              ? event[0].contentBoxSize[0]?.inlineSize || 0
              : 0;
          setContainerWidth(width);
        })
      : null;

    if (element) resizeObserver?.observe(element);

    return () => {
      if (element) resizeObserver?.unobserve(element);
    };
  }, [containerRef, options.disable]);

  return !options.disable ? containerWidth : null;
};

export const useContainerHeight = (
  containerRef: MutableRefObject<any>,
  options: { disable?: boolean; valueGetter?: (element: any) => number } = {}
) => {
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const element = !options.disable ? containerRef.current : null;
    const resizeObserver = !options.disable
      ? new ResizeObserver((event) => {
          const height = options.valueGetter
            ? options.valueGetter(event[0].target)
            : event[0].contentBoxSize[0]?.blockSize || 0;
          setContainerHeight(height);
        })
      : null;

    if (element) resizeObserver?.observe(element);

    return () => {
      if (element) resizeObserver?.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options.disable, options.valueGetter]);

  return !options.disable ? containerHeight : null;
};
