import React from 'react';
import { ScrollerStyle as S } from './Scroller.style';

type ScrollerProps = {
  axis?: 'y' | 'x';
  testId?: string;
  className?: string;
  children?: React.ReactNode;
};

export const Scroller: React.FC<ScrollerProps> = ({ className, children, testId, ...props }) => {
  return (
    <S.Scroller $props={props} className={className} data-testid={testId}>
      {children}
    </S.Scroller>
  );
};
