import { User } from 'types/User';
import { combineLatest, filter, first, map, Observable } from 'rxjs';
import { BLoCBase } from 'types/BLoCBase';
import { $get } from 'services/api';
import { tokens } from 'services/tokens';
import { getCustomerStore } from './customer.store';
import { getFFStore } from './ff.store';
import * as Sentry from '@sentry/react';

type Store = {
  user: User | 'loading' | 'unauthorized';
};

class UserStore extends BLoCBase<Store> {
  public $user = this.$getState('user').pipe(map((user) => (typeof user === 'string' ? null : user)));

  public $userState = this.$getState('user').pipe(
    map((user) => (typeof user === 'string' ? user : 'authorized'))
  );

  public $userRole = this.$user.pipe(
    map((user) => (['developer', 'owner'].includes(user?.role || '') ? 'administrator' : user?.role))
  ) as Observable<'administrator' | 'user' | undefined>;

  public $onboardingStatus = combineLatest([
    this.$getState('user'),
    getCustomerStore().$billingStatus,
    this.$userRole,
  ]).pipe(
    map(([user, billingStatus, userRole]) => {
      if (!user || user === 'loading' || !billingStatus) {
        return null;
      }

      if (user === 'unauthorized') {
        return 'new';
      } else if (billingStatus === 'unpaid') {
        return userRole === 'administrator' ? 'unpaid-admin' : 'unpaid-user';
      } else if (!user.customer || billingStatus === 'disabled') {
        return 'onboarded';
      } else if (!user.statuses.emailValidated) {
        return 'new';
      } else if (!user.statuses.profileComplete) {
        return 'email-verified';
      } else if (!user.statuses.billing || billingStatus === 'empty') {
        return 'profile-complete';
      } else if (getFFStore().hasFF('ff-mandatory-2fa') && !user?.statuses?.twoFaEnabled) {
        return '2fa-setup';
      } else {
        return 'onboarded';
      }
    })
  );

  public $userOrigin = this.$user.pipe(map((user) => user?.origin));

  constructor() {
    super({
      user: 'loading',
    });
    this.addSub(
      tokens.$accessToken.subscribe(() => this.fetchUser()),
      'at-fetch'
    );
    this.addSub(
      this.$user
        .pipe(
          filter((u) => !!u),
          first()
        )
        .subscribe(() => getCustomerStore().fetchCustomer()),
      'fetch-customer'
    );
  }

  public fetchUser = (next?: (user: User) => void) => {
    if (tokens.accessToken && tokens.accessToken !== 'loading') {
      this.addSub(
        this.$getState('user')
          .pipe(first())
          .subscribe((user) => {
            if (user === 'unauthorized') {
              this.setState('user', 'loading');
            }
            $get<User>('user').subscribe((user) => {
              !!user?.email && Sentry.setUser({ email: user.email });
              this.setState('user', user);
              next && next(user);
            });
          }),
        'fetch-user'
      );
    } else if (tokens.accessToken !== 'loading') {
      this.setState('user', 'unauthorized');
    }
  };

  public logout = () => {
    Sentry.setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        ?.replace(/^ +/, '')
        ?.replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    if (window.location.href !== window.location.origin && import.meta.env.PROD) {
      // not running this in dev as that causes infinte redirect loop due to access_token being
      // hard coded.
      window.location.href = window.location.origin + '/logout';
    } else {
      tokens.setTokens({});
      this.setState('user', 'unauthorized');
    }
  };
}

let store: Readonly<UserStore> | null = null;
export const getUserStore = () => {
  if (!store) {
    store = Object.freeze(new UserStore());
  }
  return store;
};
