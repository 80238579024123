import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

let triggerNavigateGlobal: NavigateFunction | null = null;

export const NavigateGlobal: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    triggerNavigateGlobal = navigate;
  }, [navigate]);
  return null;
};

export const navigate: NavigateFunction = (...args) => (triggerNavigateGlobal as any)?.(...args);
