import styled from 'styled-components';
import { cssScreens } from 'styles/styles';

export const HeyYabbleCountStepContentStyle = {
  Container: styled.div``,
  Row: styled.div`
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: ${cssScreens.tablet}) {
      flex-direction: column;
    }
  `,
};
