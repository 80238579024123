import styled from 'styled-components';
import { PDialog } from 'components/prime/PDialog/PDialog';

export const CreditsModalStyle = {
  Container: styled.div``,
  Dialog: styled(PDialog)`
    &.p-dialog.p-component {
      width: 480px !important;
    }
    .p-dialog-header-icons {
      display: none !important;
    }
    .p-dialog-content {
      overflow: overlay;
      padding: 24px;
    }
  `,
  Header: styled.div`
    & > h4 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 100%;
    }
  `,
};
