import { Tag } from 'primereact/tag';
import styled from 'styled-components';
import { colours } from 'styles/styles';

export const PTagStyle = {
  Tag: styled(Tag)`
    &.p-tag.p-component {
      height: 20px;
      border-radius: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: fit-content;

      &.p-tag-icon-reversed {
        display: inline-flex;
        flex-direction: row-reverse;
      }

      & > span {
        font-family: 'SuisseIntl', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        font-family: 'SuisseIntl', sans-serif;
        font-weight: 400;
      }

      .p-tag-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &.p-tag-info {
        background-color: ${colours.neutralGrey02};
        color: ${colours.neutralLightCharcoal};
      }
      &.p-tag-success {
        background-color: ${colours.uiSuccessDisabled};
        color: ${colours.neutralCharcoal};
      }

      &.p-tag-white {
        background-color: white;
        border: 1px solid ${colours.neutralGrey03};
        border-radius: 4px;
      }
      /* &.p-tag-danger {
        color: white;
      } */

      &.p-tag-primary {
        color: ${colours.primaryBlue};
        background-color: ${colours.primaryBlueAlpha16};
        border: 1px solid transparent;
        border-radius: 4px;
      }

      &.p-tag-pink {
        color: ${colours.neutralCharcoal};
        background-color: ${colours.primaryPink};
        border: 0;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.p-tag-small {
        height: 16px;
        padding: 0px 6px;
        & > span {
          font-size: 10px !important;
          font-weight: 500;
        }
      }
      &.p-tag-large {
        height: 36px;
        font-weight: 500;
      }
    }
  `,
};
