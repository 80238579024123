import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild, IBLoCInitialisable } from 'types/BLoCBase';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  catchError,
  combineLatest,
  map,
  shareReplay,
  from,
  switchMap,
  of,
  first,
  distinctUntilChanged,
  delay,
  finalize,
} from 'rxjs';
import { $get, $post, $put } from 'services/api';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { snackbar } from 'services/snackbar';
import { pusher } from 'services/pusher';
import { toast } from 'services/toast';
import { SHA256 } from 'crypto-js';
import { navigate } from 'services/navigate';

type State = {
  showError?: boolean;
  selectedFile: File | null;
  loading?: boolean;
  createdIngestion?: ImportModalCsvTypes.Ingestion | null;
};

class UploadFormValues {
  public confirm: boolean = false;
  public projectType: number | null = null;
  public industryId: number | null = null;
  public name: string = '';
  public projectName: string = '';
  public ingestionDataSubTypeId: number | null = null;
}

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $industries = $get<{ id: number; name: string }[]>('industries').pipe(
    catchError(() => []),
    shareReplay()
  );
  public $ingestionDataTypes = $get<{ id: number; name: string }[]>('ingestion-data-types').pipe(
    catchError(() => []),
    shareReplay()
  );
  public $ingestiondataSubtype = $get<{ id: number; name: string }[]>('ingestion-data-sub-types').pipe(
    catchError(() => []),
    shareReplay()
  );
  public $file = this.$getState('selectedFile');
  public $loading = this.$getState('loading');
  public $showError = this.$getState('showError');

  public get showSubtypes() {
    return this.preSelectedIngestionDatatype === 1;
  }

  public closeError = () => {
    this.$getState('createdIngestion')
      .pipe(first())
      .subscribe((ingestion) => {
        this.setState('showError', false);
        this.closeModal();
        navigate(`/projects/${ingestion?.projectId}/ingestions?showErrorsFor=${ingestion?.id}`);
      });
  };

  public $disableNextButton = combineLatest([this.$file, this.$loading]).pipe(
    map(([file, loading]) => !file || loading)
  );

  public $fileHash = this.$file.pipe(
    distinctUntilChanged(),
    switchMap((file) =>
      file
        ? from(
            new Promise(async (resolve, reject) => {
              try {
                const fileText = await file.text();
                const hash = SHA256(fileText).toString();
                resolve(hash);
              } catch (error) {
                reject('hash_error');
              }
            })
          ).pipe(catchError(() => of(null)))
        : of(null)
    )
  );
  public $fileIsDuplicate = this.$fileHash.pipe(
    switchMap((hash) =>
      this.projectId && hash
        ? $put<{ exists: boolean }>(`projects/${this.projectId}/ingestions/check-for-duplicates`, {
            'content-hash': hash,
          })
        : of({ exists: false })
    ),
    shareReplay()
  );

  constructor(
    public readonly uploadForm: UseFormReturn<UploadFormValues>,
    public readonly closeModal: (complete?: boolean | undefined) => void,
    public readonly setIngestion: (ingestion: ImportModalCsvTypes.Ingestion) => void,
    public readonly getIngestionColumns: (id: number) => void,
    public readonly changeStep: (step: ImportModalCsvTypes.IngestionSteps) => void,
    public readonly projectId: string | null,
    public readonly preSelectedIngestionDatatype?: number
  ) {
    super({
      selectedFile: null,
    });
  }
  public onInit = () => {
    this.subToImportChannel();
  };

  private subToImportChannel() {
    this.addSub(
      pusher
        .$listen<{
          customerId: string;
          ingestionId: number;
          result: 'ready' | 'failed';
        }>('.ImportFileAnalysisComplete')
        .pipe(delay(2000))
        .subscribe((event) => {
          const ingestion = this.currentState('createdIngestion');
          if (ingestion?.id === event.ingestionId) {
            this.setState('loading', false);
            if (event.result === 'failed') {
              this.setState('selectedFile', null);
              this.showFailureReasonNotification(event.ingestionId);
            } else {
              this.setIngestion(ingestion);
              this.changeStep('csv_received');
              this.getIngestionColumns(ingestion.id);
            }
          }
        }),
      'importFileAnalysisComplete'
    );
  }

  public fileChangeEvent = (file: File, clearFn: () => void) => {
    const split = file.name.split('.');
    const ext = split[split.length - 1];
    if (ext !== 'csv') {
      clearFn();
      snackbar({
        severity: 'error',
        label: (
          <>
            <b>{file.name}</b> is a format not supported for import
          </>
        ),
        closable: true,
      });
      return this.setStates({ selectedFile: null });
    }
    this.setStates({ selectedFile: file });
  };

  public getIngestion = (id: number) => $get<ImportModalCsvTypes.Ingestion>(`ingestions/${id}`);

  public submitIngestionOrGetCurrent = (
    { name, industryId, ingestionDataSubTypeId, projectName }: UploadFormValues,
    file: File
  ) => {
    const ingestion = this.currentState('createdIngestion');
    return this.$fileHash.pipe(
      first(),
      switchMap((contentHash) => {
        let formdata = new FormData();
        formdata.append('name', name);
        formdata.append('fileName', file.name);
        formdata.append('contentHash', String(contentHash));
        formdata.append('csvFile', file, file.name);
        if (this.projectId) {
          formdata.append('projectId', this.projectId);
        } else {
          formdata.append('industryId', String(industryId));
          if (this.preSelectedIngestionDatatype)
            formdata.append('ingestionDataTypeId', String(this.preSelectedIngestionDatatype));
          if (ingestionDataSubTypeId)
            formdata.append('ingestionDataSubTypeId', String(ingestionDataSubTypeId));
          formdata.append('projectName', projectName);
        }
        return ingestion
          ? of(ingestion)
          : $post<ImportModalCsvTypes.Ingestion>('ingestions', formdata, {
              requestType: 'form',
            });
      })
    );
  };

  public next = () =>
    this.uploadForm.handleSubmit((data) => {
      const file = this.currentState('selectedFile');
      if (file) {
        this.setState('loading', true);
        this.submitIngestionOrGetCurrent(data, file).subscribe({
          next: (resp) => this.setState('createdIngestion', resp),
          error: (err) => {
            this.setStates({ selectedFile: null, loading: false });
            toast({
              severity: 'error',
              summary: err?.message || 'An error has ocurred. Try again later.',
            });
            this.setState('createdIngestion', null);
          },
        });
      }
    });

  private showFailureReasonNotification = (ingestionId: number) => {
    this.setState('loading', true);
    this.addSub(
      $get<ImportModalCsvTypes.Ingestion>(`ingestions/${ingestionId}`)
        .pipe(finalize(() => this.setState('loading', false)))
        .subscribe({
          next: (ingestion) => {
            this.setState('createdIngestion', ingestion);
            this.setState('showError', true);
          },
          error: () => {
            snackbar({
              severity: 'error',
              label: 'An error has ocurred with your file. Try again later.',
            });
          },
        }),
      'showFailureReasonNotification'
    );
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useUploadStepBLoC = () => useBLoC<BLoC>(Context);

export const UploadStepBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const uploadForm = useForm({
    defaultValues: new UploadFormValues(),
    mode: 'all',
  });

  const {
    setIngestion,
    changeStep,
    getIngestionColumns,
    projectId,
    preSelectedIngestionDatatype,
    closeModal,
  } = useImportModalCsvBLoC();

  const bloc = useInitBloc(
    () => new BLoC(uploadForm, closeModal, setIngestion, getIngestionColumns, changeStep, projectId, 1),
    [preSelectedIngestionDatatype]
  );

  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
