import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalFooter } from 'components/modals/ImportModal/components/ImportModalFooter/ImportModalFooter';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../MapStep.bloc';

export const MapStepFooter: React.FC = () => {
  const { closeModal } = useImportModalCsvBLoC();
  const { commitChanges, $disableNextButton } = useMapStepBLoC();
  const disableNextButton = useObservableState($disableNextButton, false);

  return (
    <ImportModalFooter
      saveAndCloseFn={() => commitChanges(false, closeModal)}
      nextFn={() => commitChanges(true)}
      disabled={disableNextButton}
    />
  );
};
