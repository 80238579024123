import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { useCreditsModalBLoC } from 'components/modals/CreditsModal/CreditsModal.bloc';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { CreditsModalTopupFormBalanceAfterStyle as S } from './CreditsModalTopupFormBalanceAfter.style';

export const CreditsModalTopupFormBalanceAfter: React.FC = () => {
  const { $formBalanceAndTotal, project, $mode } = useCreditsModalBLoC();
  const formBalanceAndTotal = useObservableState($formBalanceAndTotal);
  const mode = useObservableState($mode, '');

  return (
    <S.Container>
      {mode !== 'credit-purchase' && (
        <S.Row>
          <div>
            <S.InformationIcon />
            <S.Text>Minimum required to complete</S.Text>
          </div>
          <S.Text>{project && <FormattedNumber num={project?.amount} />}</S.Text>
        </S.Row>
      )}
      <S.Row>
        <div>
          <S.InformationIcon />
          <S.Text>Balance after top-up</S.Text>
        </div>
        <S.Text data-testid="label-complete-transaction-balance-after">
          <FormattedNumber num={formBalanceAndTotal?.balanceAfterTopup || 0} />
        </S.Text>
      </S.Row>
    </S.Container>
  );
};
