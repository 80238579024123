import styled from 'styled-components';
import { colours, cssScreens, fontSize, lineHeight } from 'styles/styles';
import { ReactComponent as CheckActiveIcon } from 'assets/svg/checkmark-filled.svg';

export const ImportModalSyntheticCTAStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    max-height: calc(100vh);
    h2:first-of-type {
      margin-top: 32px;
      margin-bottom: 12px;
    }
    ul {
      padding: 0;
      margin: 12px 0 0 16px;
    }
    li {
      font-weight: 400;
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      color: ${colours.neutralBody};
    }
    svg {
      height: 18px;
      width: 18px;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 1220px;
    margin: 0 auto;
    @media screen and (max-width: ${cssScreens.mobileLarge}) {
      justify-content: flex-start;
    }
  `,
  Title: styled.h1`
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: ${colours.neutralCharcoal};
  `,
  Subtitle: styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.neutralCharcoal};
  `,
  Paragraph: styled.p`
    margin: 0;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.neutralBody};
  `,
  Items: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 40px;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 12px;
    background: ${colours.neutralGrey01};
    border-radius: 4px;
  `,
  ItemText: styled.p`
    margin: 0;
    font-weight: 400;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colours.neutralBody};
  `,
  IconBox: styled.div`
    height: 18px;
    width: 18px;
  `,
  CheckIcon: styled(CheckActiveIcon)<{ inActive?: boolean }>`
    path {
      fill: ${colours.primaryGreen};
    }
    ${({ inActive }) =>
      inActive &&
      ` path {
          stroke: ${colours.neutralCharcoal};
          fill: ${colours.neutralGrey01};
        }`}
  `,
};
