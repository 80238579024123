import React, { useMemo } from 'react';
import { useIsReactPage } from 'hooks/useIsReactPage';
import { Link } from 'react-router-dom';
import { LeftMenuLogoStyle as S } from './LeftMenuLogo.style';
import { getFFStore } from 'stores/ff.store';

export const LeftMenuLogo: React.FC = () => {
  const isReactPage = useIsReactPage();
  const sagoTrialFlagEnabled = useMemo(() => getFFStore().hasFF('ff-sago-trial'), []);
  const logo = useMemo(() => (sagoTrialFlagEnabled ? <S.SagoLogo /> : <S.Logo />), [sagoTrialFlagEnabled]);

  return isReactPage ? (
    <Link to="/" className="inline-block">
      {logo}
    </Link>
  ) : (
    <a href="/" className="block">
      {logo}
    </a>
  );
};
