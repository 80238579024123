import { FileUpload, FileUploadProps } from 'primereact/fileupload';
import React from 'react';
import { PFileUploadStyle as S } from './PFileUpload.style';

/**
 * @deprecated Use FileUploadV2 instead
 */
export const PFileUpload = React.forwardRef<FileUpload, FileUploadProps>((props, ref) => {
  return <S.FileUpload {...props} ref={ref} />;
});
