import React from 'react';
import { ImportModalSelectFlowOptionsStyle as S } from './ImportModalSelectFlowOptions.style';
import { ReactComponent as SurveyIcon } from 'assets/svg/survey.svg';
import { ReactComponent as ReviewsIcon } from 'assets/svg/reviews.svg';
import { ReactComponent as OtherIcon } from 'assets/svg/other.svg';
import { ReactComponent as SynthIcon } from 'assets/svg/synth.svg';
import { useImportModalBLoC } from 'components/modals/ImportModal/ImportModal.bloc';
import { ImportModalTypes } from 'components/modals/ImportModal/ImportModal.types';

const options: {
  icon: React.ReactNode;
  title: string;
  description: string;
  flow: ImportModalTypes.ImportModalFlow;
  dataTypeId?: number;
}[] = [
  {
    icon: <SurveyIcon />,
    title: 'Survey',
    description: 'Use for any comments or text captured from survey data.',
    flow: 'csv',
    dataTypeId: 1,
  },
  {
    icon: <ReviewsIcon />,
    title: 'Review',
    description: 'Consumer or user feedback such as product, movie or experience reviews.',
    flow: 'csv',
    dataTypeId: 2,
  },
  {
    icon: <SynthIcon />,
    title: 'Augmented',
    description: "Data created using Yabble's ChatGPT Plugin (requires plugin access).",
    flow: 'augmented',
  },
  {
    icon: <OtherIcon />,
    title: 'Other',
    description: 'Use for any other text data type.',
    flow: 'csv',
    dataTypeId: 4,
  },
];

export const ImportModalSelectFlowOptions: React.FC = () => {
  const { selectImportFlow } = useImportModalBLoC();

  return (
    <S.Container>
      {options.map((opt) => (
        <S.Option onClick={() => selectImportFlow(opt.flow, opt.dataTypeId)} key={opt.title}>
          {opt.icon}
          <h2>{opt.title}</h2>
          <p>{opt.description}</p>
        </S.Option>
      ))}
    </S.Container>
  );
};
