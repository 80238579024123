import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, IBLoCInitialisable, renderBlocChild } from 'types/BLoCBase';
import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { $get, $post } from 'services/api';
import {
  Observable,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { $fromHookForm } from 'utils/$fromHookForm';
import { ImportModalSyntheticTypes } from './ImportModalSynthetic.types';
import { useImportModalBLoC } from '../../ImportModal.bloc';

type State = {
  survey?: ImportModalSyntheticTypes.Survey | 'not-found' | null;
};

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $survey = this.$getState('survey');

  public $getCode = () =>
    this.initSurveyCode
      ? of(this.initSurveyCode)
      : this.$survey.pipe(
          map((s) => (s !== 'not-found' ? s?.surveyCode : null)),
          first()
        );

  public initPolling = () => {
    this.addSub(
      timer(2500, 3000)
        .pipe(
          switchMap(() => this.$getCode()),
          switchMap((code) => $get<ImportModalSyntheticTypes.Survey>(`chat-plugin-api/surveys/${code}`, {})),
          filter((r) => r.status !== 'done' && r.status !== 'importing'),
          first()
        )
        .subscribe((r) => this.setState('survey', r)),
      'pollForResults'
    );
  };

  constructor(
    public form: UseFormReturn<
      {
        surveyCode: string;
        projectTitle: string;
      },
      any
    >,
    public initSurveyCode?: string
  ) {
    super({});
  }

  public onInit = () => {
    this.addSub(
      (this.initSurveyCode ? of(this.initSurveyCode) : $fromHookForm(this.form, 'surveyCode'))
        .pipe(
          filter((f) => !!f && f?.length === 9),
          distinctUntilChanged(),
          switchMap(
            (code) =>
              $get<ImportModalSyntheticTypes.Survey | 'not-found'>(
                `chat-plugin-api/surveys/${code}`,
                {}
              ).pipe(catchError(() => of('not-found'))) as Observable<
                ImportModalSyntheticTypes.Survey | 'not-found'
              >
          ),
          tap(
            (survey) =>
              survey !== 'not-found' &&
              survey.surveyTopic &&
              this.form.resetField('projectTitle', { defaultValue: survey.surveyTopic })
          )
        )
        .subscribe((r) => this.setState('survey', r))
    );
  };

  public handleSubmit = this.form.handleSubmit(({ surveyCode, projectTitle }) =>
    $post<ImportModalSyntheticTypes.Survey>(`synthetic-surveys/${surveyCode}/import`, {
      projectTitle,
    }).subscribe((survey) => this.setState('survey', survey))
  );
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useImportModalSyntheticBLoC = () => useBLoC<BLoC>(Context);

export const ImportModalSyntheticBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const { surveyCode } = useImportModalBLoC();
  const form = useForm({
    defaultValues: {
      surveyCode: surveyCode || '',
      projectTitle: '',
    },
  });
  const bloc = useInitBloc(() => new BLoC(form, surveyCode));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
