import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import React from 'react';
import { MapStepColumnMapToItemStyle as S } from './MapStepColumnMapToItem.style';

export const MapStepColumnMapToItem: React.FC<{
  item: any;
  selectedItems: string[];
  currentValue: string | null;
}> = ({ item, selectedItems, currentValue }) => {
  if (item.id === 'divider') {
    return (
      <S.Divider onClick={(e: any) => e.stopPropagation()}>
        <span>{item.name}</span>
      </S.Divider>
    );
  }
  return (
    <S.Container>
      {currentValue !== item.id && selectedItems.includes(item.id) ? (
        <S.Disabled
          className={`map-step-column-dropdown-${item.id}`}
          onClick={(e: any) => e.stopPropagation()}
        >
          <span>{item.name}</span>
          <PTooltip
            target={`.map-step-column-dropdown-${item.id}`}
            position="bottom"
            content="This column has been mapped. Remove its current match to use it here"
          />
        </S.Disabled>
      ) : (
        <S.Item>
          <span>{item.name}</span>
        </S.Item>
      )}
    </S.Container>
  );
};
