import styled from 'styled-components';
import { Box } from 'components/layouts/primitives/Box/Box';

export const PersonaLibraryModalStyle = {
  ListPanel: styled(Box)`
    & > div {
      width: 279px;
    }
  `,
};
