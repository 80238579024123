import styled from 'styled-components';
import { colours } from 'styles/styles';

export const UploadStepTypeOfDataStyle = {
  Container: styled.div`
    flex: 1;
    border: 1px solid ${colours.neutralGrey03};
    border-radius: 8px;
  `,
  Content: styled.div`
    padding: 32px;
  `,
  Title: styled.h4`
    margin: 0 0 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    span {
      height: 20px;
      margin-left: 7px;
      svg {
        cursor: pointer;
        path {
          fill: ${colours.neutralGrey05};
        }
      }
    }
  `,
  Fields: styled.div``,
};
