import React, { PropsWithChildren } from 'react';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';
import { PColumnBodyStyle as S } from './PColumnBody.style';

/**
 * @deprecated Use PDataTableV2 instead
 */
export const PColumnBody: React.FC<PropsWithChildren<{ loading?: boolean }>> = ({ children, loading }) => {
  return loading ? (
    <S.SkeletonWrapper>
      <PSkeleton />
    </S.SkeletonWrapper>
  ) : (
    <>{children !== undefined ? children : null}</>
  );
};
