import styled, { css } from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { borderRadius, colourPalette, gap, hexToRGBA, shadow } from 'styles/styles';

export const PDialogV2Style = {
  Dialog: styled(Dialog)<{ $maxWidth?: number; $minHeight?: number }>`
    box-shadow: ${shadow.shadowXL};
    overflow: hidden;
    background-color: ${colourPalette.neutral0};
    width: calc(100% - ${gap.gap8});
    max-width: ${({ $maxWidth }) => (typeof $maxWidth === 'number' && $maxWidth > 280 ? $maxWidth : 480)}px;
    max-height: calc(100% - ${gap.gap9});
    border-radius: ${borderRadius.roundedXL};
    .p-dialog-content {
      all: unset;
      color: ${colourPalette.neutral600};
      min-height: 0;
      display: flex;
      flex-direction: column;
    }
    .p-dialog-content-blocker {
      position: absolute;
      inset: 0;
      z-index: 3;
    }
    .p-dialog-body-scroller {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: sticky;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: ${gap.gap5};
        background: linear-gradient(
          180deg,
          ${hexToRGBA(colourPalette.neutral0, 1)} 0%,
          ${hexToRGBA(colourPalette.neutral0, 1)} 70%,
          ${hexToRGBA(colourPalette.neutral0, 0)} 100%
        );
      }
      &::after {
        content: '';
        display: block;
        position: sticky;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: ${gap.gap6};
        background: linear-gradient(
          0deg,
          ${hexToRGBA(colourPalette.neutral0, 1)} 0%,
          ${hexToRGBA(colourPalette.neutral0, 1)} 70%,
          ${hexToRGBA(colourPalette.neutral0, 0)} 100%
        );
      }
    }
    &.p-dialog-fullscreen {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: ${borderRadius.roundedNone};
      .p-dialog-content {
        height: 100%;
      }
    }
    ${({ $minHeight }) =>
      typeof $minHeight === 'number'
        ? css`
            min-height: ${$minHeight}px;
            .p-dialog-content,
            .p-dialog-content-wrapper {
              min-height: inherit;
            }
            &.p-dialog-fullscreen {
              min-height: auto;
            }
          `
        : css``}
  `,
};
