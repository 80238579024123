import React from 'react';
import { useSavePersonaGroupModalBLoC } from '../../SavePersonaGroupModal.bloc';
import { PInputText } from 'components/prime/PInputText/PInputText';
import { FormField } from 'components/FormField/FormField';

export const SavePersonaGroupModalName: React.FC = () => {
  const { form } = useSavePersonaGroupModalBLoC();

  return (
    <FormField
      form={form}
      label="Group name"
      controller={{
        name: 'name',
        rules: {
          required: 'Group name is required',
          maxLength: 255,
        },
      }}
    >
      {({ field, fieldState }) => (
        <PInputText
          {...field}
          invalid={fieldState.invalid}
          placeholder={`e.g. "US Tech Personas"`}
          maxLength={255}
          onBlur={() => field.onChange(field.value.trim())}
        />
      )}
    </FormField>
  );
};
