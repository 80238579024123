import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';
import React from 'react';

export const DetailsPanelLoadingState: React.FC = () => {
  return (
    <Box padding={['paddingXL', 'padding2XL']}>
      <VStack gap="gap5">
        <PSkeleton height="18px" width="400px" borderRadius="12px" />
        <VStack gap="gap4">
          {Array.from({ length: 8 }).map((_g, idx) => (
            <HStack key={idx} gap="gap4" align="center">
              <PSkeleton height="40px" width="40px" borderRadius="12px" />
              <PSkeleton height="18px" width="240px" borderRadius="12px" />
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};
