import { Box } from 'components/layouts/primitives/Box/Box';
import styled, { css } from 'styled-components';
import { colourPalette, hexToRGBA } from 'styles/styles';

const fancyBackground = css`
  --s: 30px;
  --c1: ${hexToRGBA(colourPalette.slate50, 0.3)};
  --c2: ${hexToRGBA(colourPalette.slate100, 0.35)};
  --_g: var(--c1) 6.2%, var(--c2) 6.3% 12.7%, var(--c1) 12.8% 67.7%, var(--c2) 67.3% 73.7%, #0000 73.8%;
  background:
    radial-gradient(var(--s) at 100% 0, var(--_g)),
    radial-gradient(var(--s) at 0 0, var(--_g)),
    radial-gradient(var(--s) at 0 100%, var(--_g)),
    radial-gradient(var(--s) at 100% 100%, var(--_g)) var(--c1);
  background-size: var(--s) var(--s);
`;

export const DetailsPanelPersonasPersonaExpandedStyle = {
  Container: styled(Box)`
    ${fancyBackground};
    overflow: hidden;
  `,
  ImageContainer: styled(Box)`
    overflow: hidden;
    height: 240px;
    width: 200px;
    img {
      height: 240px;
      object-fit: cover;
    }
  `,
};
