import styled from 'styled-components';
import { colours } from 'styles/styles';

export const MapStepBarDataTypesButtonStyle = {
  Container: styled.div`
    position: relative;
  `,
  Button: styled.div<{ open?: boolean }>`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    background: ${({ open }) => (open ? colours.neutralGrey02 : colours.neutralWhite)};
    ${({ open }) =>
      !open &&
      `&:hover {
      background: ${colours.neutralGrey02};
    }`}
    &:active {
      background: ${colours.neutralGrey02};
    }
  `,
  ButtonTex: styled.span`
    font-weight: 400;
    font-size: 13px;
    color: ${colours.neutralBody};
    margin-left: 4px;
  `,
  Box: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 16px;
    width: 480px;
    right: 0;
    background: ${colours.neutralWhite};
    border: 1px solid ${colours.neutralGrey03};
    box-shadow: 0px 0px 11px 2px rgba(63, 73, 90, 0.12);
    border-radius: 4px;
    z-index: 10;
  `,
  Title: styled.h4`
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    color: ${colours.neutralBody};
  `,
  Item: styled.div`
    display: flex;
    gap: 8px;
  `,
  ItemContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Subtitle: styled.h6`
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin: 0;
    color: ${colours.neutralCharcoal};
  `,
  Text: styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${colours.neutralBody};
  `,
  SecondBox: styled.div`
    background: ${colours.neutralGrey01};
    border-radius: 4px;
    padding: 8px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: ${colours.neutralBody};
    }
  `,
  Icon: styled.div`
    width: 40px;
    height: 40px;
  `,
};
