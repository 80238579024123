import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCParams, BLoCBase, IBLoCInitialisable, renderBlocChild } from 'types/BLoCBase';
import { finalize, map } from 'rxjs';
import { $get } from 'services/api';
import { ImportModalCsvTypes } from './ImportModalCsv.types';
import { useImportModalBLoC } from '../../ImportModal.bloc';
import { ImportModalTypes } from '../../ImportModal.types';
import { useObservableState } from 'observable-hooks';
import { openGlobalModal } from 'services/ModalService/ModalService.bloc';

export const TC_IMPORT_NPS_RESPONSE_TYPE_ID = 4;
export const TC_IMPORT_DATE_RESPONSE_TYPE_ID = 3;
export const TC_IMPORT_TEXT_RESPONSE_TYPE_ID = 2;
export const TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID = 1;
export const TC_IMPORT_COUNT_LIMIT = 10;

type State = {
  ingestion?: ImportModalCsvTypes.Ingestion;
  step?: ImportModalCsvTypes.IngestionSteps;
  loading?: boolean;
  ingestionColumns: ImportModalCsvTypes.IngestionColumn[];
  selectedColumnIds?: number[];
};

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $status = this.$getState('ingestion').pipe(map((ingestion) => ingestion?.status || null));
  public $step = this.$getState('step');
  public $loading = this.$getState('loading');
  public $currentIngestion = this.$getState('ingestion');
  public $ingestionColumns = this.$getState('ingestionColumns');
  public $currentSelectedColumnIds = this.$getState('selectedColumnIds');
  public $currentIngestionColumns = this.$getState('ingestionColumns');
  public $breadcrumbOptions = this.$step.pipe(
    map((step) => {
      let breadcrumbOptions = [
        {
          label: 'Upload',
          className: !step ? 'active' : '',
        },
        {
          label: 'Map',
          className: step === 'csv_received' ? 'active' : '',
        },
        {
          label: 'Count',
          className: step === 'columns_mapped' || step === 'themes_enabled' ? 'active' : '',
        },
      ];
      return breadcrumbOptions;
    })
  );

  constructor(
    private readonly _ingestionId: number | null,
    public readonly closeModal: (complete?: boolean) => void,
    public readonly selectImportFlow: (flow: ImportModalTypes.ImportModalFlow) => void,
    public readonly project?: ImportModalTypes.Project,
    public readonly preSelectedIngestionDatatype?: number
  ) {
    super({ ingestionColumns: [] });
  }

  public onInit = () => {
    if (this.ingestionId) this.getIngestion();
  };

  public get ingestionId() {
    return this._ingestionId || this.currentState('ingestion')?.id || null;
  }
  public get projectId() {
    return this.project?.id || null;
  }

  public get projectName() {
    return this.project?.name || '';
  }

  public get isEmptyProject() {
    return !this.project?.hasData;
  }

  public setIngestion = (ingestion: ImportModalCsvTypes.Ingestion) => this.setState('ingestion', ingestion);
  public setSelectedColumnIds = (columns: number[]) => this.setState('selectedColumnIds', columns);

  public setIngestionColumns = (columns: ImportModalCsvTypes.IngestionColumn[]) =>
    this.setState('ingestionColumns', columns);

  public getIngestion = () => {
    this.setState('loading', true);
    this.addSub(
      $get<ImportModalCsvTypes.Ingestion>(`ingestions/${this.ingestionId}`).subscribe((ingestion) => {
        this.setState('ingestion', ingestion);
        if (['columns_mapped', 'themes_enabled', 'csv_received'].includes(ingestion.status))
          this.setState('step', ingestion.status as ImportModalCsvTypes.IngestionSteps);
        else if (ingestion.status === 'imported') this.setState('step', 'themes_enabled');
        this.getIngestionColumns(ingestion.id);
      })
    );
  };

  public getIngestionColumns = (id: number) => {
    this.setState('loading', true);
    this.addSub(
      $get<ImportModalCsvTypes.IngestionColumn[]>(`ingestions/${id}/ingestion-columns`)
        .pipe(finalize(() => this.setState('loading', false)))
        .subscribe((cols) =>
          this.setState(
            'ingestionColumns',
            cols.map((col) => ({
              ...col,
              ignoreColumn: !!col.ignoreColumn,
            }))
          )
        )
    );
  };

  public openCreditsModal = (project: ImportModalCsvTypes.ProjectProp) =>
    openGlobalModal('credits-modal', {
      project,
      onClose: (complete) => {
        if (complete) this.closeModal();
      },
    });

  public changeStep = (step: ImportModalCsvTypes.IngestionSteps) => this.setState('step', step);
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useImportModalCsvBLoC = () => useBLoC<BLoC>(Context);

export const ImportModalCsvBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const {
    ingestionId,
    project,
    onClose: closeModal,
    selectImportFlow,
    $preSelectedIngestionDatatype,
  } = useImportModalBLoC();
  const preSelectedIngestionDatatype = useObservableState($preSelectedIngestionDatatype);
  const bloc = useInitBloc(
    () => new BLoC(ingestionId || null, closeModal, selectImportFlow, project, preSelectedIngestionDatatype),
    [project, preSelectedIngestionDatatype]
  );

  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
