import { PFormField } from 'components/prime/PFormField/PFormField';
import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const CreditsModalTopupFormAmountInputStyle = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > p {
      margin: 0 16px 0 0;
      color: ${colours.neutralBody};
      flex: 1 1 auto;
      font-size: ${fontSize.fontSizeSM};
      font-weight: 400;
      line-height: ${lineHeight.lineHeightMD};
    }
  `,
  FormField: styled(PFormField)`
    position: relative;
    & > small {
      position: absolute;
      bottom: -22px;
      right: 0px;
      width: 300px;
      text-align: end;
    }
  `,
};
