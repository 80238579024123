import styled from 'styled-components';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const MapStepColumnDataStyle = {
  Container: styled.div`
    padding: 16px 16px 8px 16px;
    background-color: ${colours.neutralGreyBg};
    border-radius: 0 0 8px 8px;
  `,
  TextContainer: styled.div`
    display: block;
    margin-bottom: 8px;
  `,
  Text: styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    color: ${colours.neutralLightCharcoal};
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    transition: all 0.2s;
    .column-ignored & {
      color: ${colours.neutralGrey04};
    }
  `,
  FirstSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  RowText: styled.span`
    color: ${colours.neutralGrey06};
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    transition: all 0.2s;
    .column-ignored & {
      color: ${colours.neutralGrey04};
    }
  `,
};
