import React from 'react';
import { FormFieldStyle as S } from './FormField.style';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  FormState,
  RegisterOptions,
  UseControllerProps,
  UseFormReturn,
  UseFormStateReturn,
} from 'react-hook-form';
import { Icon, IconProps } from 'components/Icon/Icon';
import { useClassNames } from 'hooks/useClassNames';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { useCleanId } from 'hooks/useCleanId';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { Text } from 'components/Text/Text';

type FormFieldProps = {
  label?: string;
  helpText?: string;
  charLimit?: number;
  tooltipIcon?: IconProps['icon'];
  tooltipContent?: React.ReactNode;
  disabled?: boolean;
  form: UseFormReturn<any, object>;
  controller: UseControllerProps<any>;
  className?: string;
  children: (props: {
    field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FormState<FieldValues>>;
    fieldId: string;
  }) => React.ReactNode;
};

export const FormField: React.FC<FormFieldProps> = (props) => {
  const uniqueId = useCleanId();
  const classNames = useClassNames(
    {
      'form-field-disabled': props.disabled,
    },
    props.className
  );

  return (
    <S.Container gap="gap2" className={classNames}>
      {(!!props.label || !!props.tooltipContent) && (
        <HStack gap="gap4" align="center" splitAfterIdx={0}>
          {!!props.label && (
            <label htmlFor={uniqueId} className="form-field-label">
              {props.label}
            </label>
          )}
          {!!props.tooltipContent && (
            <>
              <Icon
                icon={props.tooltipIcon || 'infoCircleLine'}
                size={16}
                color="neutral400"
                className={`form-field-tooltip-icon-${uniqueId}`}
              />
              <PTooltip
                target={`.form-field-tooltip-icon-${uniqueId}`}
                position="top"
                my="center bottom-8"
                content={props.tooltipContent}
              />
            </>
          )}
        </HStack>
      )}
      <Controller
        control={props.form.control}
        {...props.controller}
        render={(innerProps) => (
          <>
            {props.children({ ...innerProps, fieldId: uniqueId })}
            {!innerProps.fieldState?.error && (props.helpText || typeof props.charLimit === 'number') && (
              <HStack gap="gap4" align="flex-start" justify="space-between">
                <Text
                  as="p"
                  size="fontSizeXS"
                  lineHeight="lineHeightSM"
                  color="neutral400"
                  className="form-field-help-text"
                >
                  {props.helpText || ''}
                </Text>
                {typeof props.charLimit === 'number' && (
                  <Text
                    as="p"
                    size="fontSizeXS"
                    lineHeight="lineHeightSM"
                    color="neutral400"
                    className="form-field-char-limit"
                  >
                    {innerProps.field.value?.length || 0}/{props.charLimit}
                  </Text>
                )}
              </HStack>
            )}
            <FormFieldErrorMessage
              label={props.label}
              rules={props.controller.rules}
              errorType={innerProps.fieldState?.error?.type}
              message={innerProps.fieldState?.error?.message}
            />
          </>
        )}
      />
    </S.Container>
  );
};

const FormFieldErrorMessage: React.FC<{
  label: FormFieldProps['label'];
  rules?: Omit<RegisterOptions<any, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  errorType?: string;
  message?: string;
}> = ({ label = 'Field', rules = {}, errorType, message }) => {
  const formattedMessage = React.useMemo(() => {
    if (message) return message;
    if (errorType) {
      switch (errorType) {
        case 'required':
          return `${label} is required.`;
        case 'minLength':
          return `${label} must contain at least ${rules[errorType]} characters.`;
        case 'maxLength':
          return `${label} must contain at most ${rules[errorType]} characters.`;
        default:
          return `${label} is invalid`;
      }
    }
    return null;
  }, [message, label, rules, errorType]);

  return formattedMessage ? (
    <Text
      as="small"
      color="rose600"
      size="fontSizeXS"
      lineHeight="lineHeightSM"
      transform="upper-first-letter"
      className="form-field-error-message"
    >
      {formattedMessage}
    </Text>
  ) : null;
};
