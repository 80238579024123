import React from 'react';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ImportModalFooter } from 'components/modals/ImportModal/components/ImportModalFooter/ImportModalFooter';
import { useObservableState } from 'observable-hooks';
import { useHeyYabbleCountStepBLoC } from '../../HeyYabbleCountStep.bloc';

export const HeyYabbleCountStepFooter: React.FC = () => {
  const { closeModal } = useImportModalCsvBLoC();
  const { back, $loading, commitChanges, $baseColumns } = useHeyYabbleCountStepBLoC();
  const loading = useObservableState($loading, false);
  const baseColumns = useObservableState($baseColumns, []);

  return (
    <ImportModalFooter
      saveAndCloseFn={() => commitChanges(false, closeModal)}
      onBack={back}
      nextFn={() => commitChanges(true)}
      successLabel="Review and pay"
      disabled={loading || !baseColumns.length}
    />
  );
};
