import styled from 'styled-components';
import { Box } from 'components/layouts/primitives/Box/Box';
import { colourPalette } from 'styles/styles';

export const ListPanelGroupItemStyle = {
  GroupItem: styled(Box)`
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:not(.is-loading):hover,
    &:not(.is-loading).is-selected {
      background-color: ${colourPalette.neutral100};
    }
  `,
};
