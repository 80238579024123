import React, { useMemo } from 'react';

export const FormattedNumber: React.FC<{ num?: number | null; fractionDigits?: number }> = ({
  num,
  fractionDigits,
}) => {
  const formatted = useMemo(() => {
    if (num !== 0 && !num) {
      return num;
    }
    // const n = roundTo ? Math.round(num * roundTo) / roundTo : ;
    return num || fractionDigits
      ? (num || 0).toLocaleString('en-US', {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        })
      : num;
  }, [num, fractionDigits]);
  return <>{formatted}</>;
};
