import { FormattedNumber } from 'components/FormattedNumber/FormattedNumber';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { TC_IMPORT_COUNT_LIMIT } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import { PTag } from 'components/prime/PTag/PTag';
import { useClassNames } from 'hooks/useClassNames';
import React, { useMemo } from 'react';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepTableRowStyle as S } from './HeyYabbleCountStepTableRow.style';
import { HeyYabbleCountStepTableRowPipelines } from '../HeyYabbleCountStepTableRowPipelines/HeyYabbleCountStepTableRowPipelines';
import { HeyYabbleCountStepTableRowCodeFrames } from '../HeyYabbleCountStepTableRowCodeFrames/HeyYabbleCountStepTableRowCodeFrames';
import { useObservableState } from 'observable-hooks';

export const HeyYabbleCountStepTableRow: React.FC<{
  column: ImportModalCsvTypes.IngestionColumn & {
    costData: { colName: string; hasHistoricTc: boolean; realCost: number };
  };
  selectedColumns: number[];
}> = ({ column, selectedColumns }) => {
  const { handleChangeCheckbox, $hasTcPipelineSelection } = useHeyYabbleCountStepBLoC();
  const hasTcPipelineSelection = useObservableState($hasTcPipelineSelection);
  const isChecked = useMemo(() => selectedColumns.includes(column.id), [selectedColumns, column]);
  const rowClassNames = useClassNames({
    'is-checked': isChecked,
  });

  return (
    <S.Container>
      <S.CheckContainer>
        <PCheckbox
          disabled={column.costData.realCost < TC_IMPORT_COUNT_LIMIT}
          checked={isChecked}
          onChange={(e) => handleChangeCheckbox(e, column.id)}
          data-testid="checkbox-map-step-column-tc"
        />
      </S.CheckContainer>
      <S.ItemContainer className={rowClassNames} data-testid="label-map-step-column-name">
        {column.costData.colName}
        {column.costData.hasHistoricTc && (
          <S.TagBox>
            <PTag severity="primary" value="Previously counted" className="ml-2" />
          </S.TagBox>
        )}
      </S.ItemContainer>
      <S.WidthItem className={rowClassNames} data-testid="label-map-step-column-credits">
        <FormattedNumber num={column.costData.realCost} />
      </S.WidthItem>
      {hasTcPipelineSelection && (
        <S.DropdownsItem>
          <HeyYabbleCountStepTableRowPipelines
            idCol={column.id}
            recommendedPipelineId={column.extraInfo?.recommendedPipelineId}
            isChecked={isChecked}
          />
          <HeyYabbleCountStepTableRowCodeFrames
            idCol={column.id}
            recommendedPipelineId={column.extraInfo?.recommendedPipelineId}
            recommendedCodeframeId={column.extraInfo.recommendedCodeframeId}
            isChecked={isChecked}
          />
        </S.DropdownsItem>
      )}
    </S.Container>
  );
};
