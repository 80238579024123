import styled from 'styled-components';
import { colours, cssScreens } from 'styles/styles';
import { FileUploadMain } from './components/FileUploadMain/FileUploadMain';
import { LinkText } from 'components/LinkText/LinkText';

/**
 * @deprecated Use FileUploadV2 instead
 */
export const FileUploadStyle = {
  Container: styled.div<{ fullHeight?: boolean; $onTop?: boolean }>`
    position: relative;
    height: 100%;
    @media screen and (max-width: ${cssScreens.tablet}) {
      margin-right: 0;
      margin-bottom: 24px;
      min-height: 360px;
      height: 360px;
    }
    .p-fileupload-content {
      ${({ fullHeight }) => (fullHeight ? `height: 100%;` : 'height: calc(100vh - 500px);')}
      .p-fileupload-files {
        ${({ $onTop }) => $onTop && 'top: 24px;'}
      }
    }
  `,
  FileUpload: styled(FileUploadMain)`
    height: 100%;
    min-height: fit-content;
    .p-fileupload-buttonbar,
    .p-progressbar {
      display: none;
    }
    .p-fileupload-content {
      border: 1px solid ${colours.neutralGrey03};
      border-radius: 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 86px;
      min-height: 360px;
      @media screen and (max-width: ${cssScreens.tablet}) {
        height: 360px;
      }
      ${({ multiple }) =>
        multiple &&
        `
          & > div:last-child {
            width: 100%;
            overflow-y: scroll;
            max-height: calc(100% - 180px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 8px;
            position: absolute;
            .p-fileupload-row {
              & > div {
                padding: 0;
              }
            }
          }
      `}
    }

    .p-fileupload-highlight {
      background-color: rgba(197, 226, 255, 0.16);
      border: 1px solid ${colours.primaryBlue};
    }
  `,
  FileComponent: styled.div`
    flex: 1;
    height: 100%;
  `,
  ConfirmContainer: styled.div`
    height: 86px;
    background: rgba(197, 226, 255, 0.7);
    display: flex;
    align-items: center;
    padding: 32px;
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border-radius: 0 0 8px 8px;
    @media screen and (max-width: ${cssScreens.tabletLarge}) {
      padding: 20px;
    }
  `,
  Link: styled(LinkText)`
    position: absolute;
    display: flex;
    height: 40px;
    bottom: 87px;
    left: 50%;
    transform: translateX(-50%);
  `,
};
