import styled from 'styled-components';

import { colours } from 'styles/styles';

export const UserIconStyle = {
  Container: styled.div<{ $colour?: string }>`
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: ${({ $colour }) => $colour || colours.primaryGreen};
  `,
  Text: styled.span`
    color: white;
  `,
};
