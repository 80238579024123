import { PInputText } from 'components/prime/PInputText/PInputText';
import styled from 'styled-components';
import { colours } from 'styles/styles';

export const ColumnNameStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Title: styled.span`
    font-size: 12px;
    line-height: 12px;
    color: ${colours.neutralGrey06};
    margin-bottom: 8px;
    font-weight: 600;
    transition: all 0.2s;
    .column-ignored & {
      color: ${colours.neutralGrey04};
    }
  `,
  Input: styled(PInputText)<{ $inactive: boolean; $ignoreColumn: boolean }>`
    .p-inputtext {
      width: 101%;
      height: 36px;
      min-height: 36px;
      margin-left: -8px;
      padding: 8px !important;
      resize: none;
      overflow-y: hidden;
      font-size: 16px !important;
      line-height: 24px;
      font-weight: 600;
      ${({ $inactive, $ignoreColumn }) =>
        $inactive &&
        `
        &:disabled {
          color: ${$ignoreColumn ? colours.neutralGrey04 : colours.neutralLightCharcoal};
          opacity: 1;
        }
      `}
    }
  `,
};
