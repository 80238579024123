import React, { useCallback } from 'react';
import { MapStepBarStyle as S } from './MapStepBar.style';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { useObservableState } from 'observable-hooks';
import { MapStepBarDataTypesButton } from './components/MapStepBarDataTypesButton/MapStepBarDataTypesButton';
import { ReactComponent as WarnIcon } from 'assets/svg/triangle-warning.svg';
import { useClassNames } from 'hooks/useClassNames';

export const MapStepBar: React.FC = () => {
  const { $errors, $loading } = useMapStepBLoC();
  const loading = useObservableState($loading, false);
  const errors = useObservableState($errors);
  const issuesLabel = errors?.count === 1 ? 'issue' : 'issues';

  const messageClasses = useClassNames({
    'has-error': !!errors?.count,
  });

  const handleScroll = useCallback(() => {
    if (errors?.count && errors.ids.length) {
      const element = document.getElementById(`column-${errors.ids[0]}`);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  if (loading) return null;

  return (
    <S.Container>
      <S.Errors className={messageClasses} onClick={handleScroll}>
        <S.ErrorIcon data-testid="icon-map-step-error">
          {errors?.count ? (
            <WarnIcon data-testid="icon-map-step-warning" />
          ) : (
            <S.SuccessIcon data-testid="icon-map-step-success" />
          )}
        </S.ErrorIcon>
        <S.ErrorText data-testid="label-map-step-issues-message">
          {errors?.count} {issuesLabel} require attention {errors?.message}
        </S.ErrorText>
      </S.Errors>
      <MapStepBarDataTypesButton />
    </S.Container>
  );
};
