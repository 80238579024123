import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  FormState,
  UseControllerProps,
  UseFormReturn,
  UseFormStateReturn,
} from 'react-hook-form';
import { PFormFieldStyle as S } from './PFormField.style';
import { PFormFieldError } from './components/PFormFieldError/PFormFieldError';
import { useFormValue } from 'hooks/useFormValue';
import { useClassNames } from 'hooks/useClassNames';
import { IconProps, Icon } from 'components/Icon/Icon';
import { PTooltip } from '../PTooltip/PTooltip';
import { useCleanId } from 'hooks/useCleanId';

type LabelPos = 'top' | 'bottom' | 'left' | 'right';

type Props = {
  id?: string;
  showError?: 'always' | 'onFocus' | 'never';
  viewMode?: boolean;
  viewModeRenderer?: (val: any) => ReactNode;
  label?: string | ReactNode;
  subLabel?: string | ReactNode;
  direction?: LabelPos;
  form: UseFormReturn<any, object>;
  controler: UseControllerProps<any>;
  className?: string;
  plainLabel?: boolean; // no * or (optional)
  disabled?: boolean;
  helpText?: string | ReactNode;
  preventFieldContainerAutoFocus?: boolean;
  charLimit?: number;
  tooltipIcon?: IconProps['icon'];
  tooltipContent?: ReactNode;
  children:
    | ReactNode
    | ((props: {
        field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<FormState<FieldValues>>;
      }) => ReactNode);
};

/**
 * @deprecated Use FormField instead
 */
export const PFormField: React.FC<Props> = ({
  id,
  children,
  viewMode,
  label,
  direction,
  controler,
  form,
  showError,
  className,
  viewModeRenderer,
  subLabel,
  plainLabel,
  disabled,
  helpText,
  charLimit,
  tooltipIcon,
  tooltipContent,
  preventFieldContainerAutoFocus,
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [to, setTo] = useState<any>(null);
  const value = useFormValue(form, controler.name as any);
  const readViewValue = useMemo(
    () => (viewModeRenderer ? viewModeRenderer(value) : value),
    [value, viewModeRenderer]
  );
  const containerClassNames = useClassNames(
    {
      'is-disabled': disabled,
    },
    className
  );
  const uniqueId = useCleanId();
  useEffect(() => () => clearTimeout(to), [to]);
  if (viewMode) {
    return (
      <S.Container id={id} className={`p-field ${containerClassNames ?? ''}`} direction={direction ?? 'top'}>
        {label && (
          <S.Label className="mb-1" htmlFor={(children as any)?.props?.id} {...{ disabled }}>
            {label}
          </S.Label>
        )}
        <S.Value>{readViewValue}</S.Value>
      </S.Container>
    );
  }
  return (
    <S.Container
      id={id}
      onFocus={() => !preventFieldContainerAutoFocus && setHasFocus(true)}
      onBlur={() => !preventFieldContainerAutoFocus && setTo(setTimeout(() => setHasFocus(false), 300))}
      className={`p-field ${containerClassNames ?? ''}`}
      direction={direction ?? 'top'}
    >
      {label && (
        <S.Label htmlFor={(children as any)?.props?.id} {...{ disabled }}>
          {label}
          {!plainLabel && (
            <>
              {controler.rules?.required ? <S.Required> *</S.Required> : <S.Optional> (optional)</S.Optional>}
            </>
          )}
          {!!tooltipContent && (
            <>
              <Icon icon={tooltipIcon || 'infoCircleLine'} size={16} className={`tooltip-icon-${uniqueId}`} />
              <PTooltip
                target={`.tooltip-icon-${uniqueId}`}
                position="top"
                my="center bottom-8"
                content={tooltipContent}
              />
            </>
          )}
        </S.Label>
      )}
      {typeof children === 'function' ? (
        <Controller
          control={form.control}
          {...controler}
          render={(props) => (
            <>
              {subLabel && <S.SubLabel>{subLabel}</S.SubLabel>}
              {children(props)}
              {!props.fieldState?.error && (helpText || typeof charLimit === 'number') && (
                <S.HelpText>
                  <p className="help-text">{helpText}</p>
                  {typeof charLimit === 'number' && (
                    <p className="char-limit-counter">
                      {props.field.value?.length || 0}/{charLimit}
                    </p>
                  )}
                </S.HelpText>
              )}
              {showError !== 'never' && (
                <PFormFieldError
                  hidden={hasFocus && showError !== 'always'}
                  label={label}
                  rules={controler.rules}
                  type={props.fieldState?.error?.type}
                  message={props.fieldState?.error?.message || null}
                />
              )}
            </>
          )}
        />
      ) : (
        children
      )}
    </S.Container>
  );
};
