import React, { useMemo } from 'react';
import { Menu, MenuProps } from 'primereact/menu';
import { PMenuStyle as S } from './PMenu.style';

export type PMenuProps = MenuProps & {
  width?: number;
  maxHeight?: number;
};

export const PMenu = React.forwardRef<Menu, PMenuProps>((props, ref) => {
  const cleanProps = useMemo(() => (({ width, maxHeight, ...o }) => o)(props), [props]);
  return <S.Menu {...cleanProps} $width={props.width} $maxHeight={props.maxHeight} ref={ref} />;
});
