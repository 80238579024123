import React from 'react';
import { ImportModalSelectFlowTitleStyle as S } from './ImportModalSelectFlowTitle.style';

export const ImportModalSelectFlowTitle: React.FC = () => {
  return (
    <S.Container>
      <h3>What type of data are you importing?</h3>
    </S.Container>
  );
};
