import styled, { css } from 'styled-components';

export const ScrollerStyle = {
  Scroller: styled.div<{ $props: any }>`
    ${({ $props }) => css`
      overflow-${$props.axis || 'y'}: overlay;
      ${$props.axis === 'x' ? 'width' : 'height'}: 100%;
    `}
  `,
};
