import React, { useEffect, useState } from 'react';
import { PDialogV2, PDialogV2Props } from 'components/prime/PDialogV2/PDialogV2';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Box } from 'components/layouts/primitives/Box/Box';
import { PButton, PButtonProps } from 'components/prime/PButton/PButton';

type ConfirmDialogV2Props = {
  title: PDialogV2Props['title'];
  description?: PDialogV2Props['description'];
  body?: React.ReactNode;
  acceptLabel?: string;
  cancelLabel?: string;
  acceptButtonSeverity?: PButtonProps['severity'];
  cancelButtonSeverity?: PButtonProps['severity'];
  onAccept?: () => void;
  onReject?: () => void;
};

let triggerConfirmDialogV2: ((props: ConfirmDialogV2Props) => void) | null = null;

export const ConfirmDialogV2Service: React.FC = () => {
  const [props, setProps] = useState<ConfirmDialogV2Props | null>(null);
  useEffect(() => {
    triggerConfirmDialogV2 = setProps;
  }, []);
  return !!props ? (
    <PDialogV2
      title={props.title}
      onHide={() => {
        props.onReject?.();
        setProps(null);
      }}
      description={props.description}
      visible
      footer={
        <HStack gap="gap2" justify="flex-end">
          <Box fit="space" className="w-6">
            <PButton
              size="lg"
              severity={props.cancelButtonSeverity || 'secondary'}
              label={props.cancelLabel || 'Cancel'}
              className="w-full"
              onClick={() => {
                props.onReject?.();
                setProps(null);
              }}
            />
          </Box>
          <Box fit="space" className="w-6">
            <PButton
              size="lg"
              severity={props.acceptButtonSeverity || 'danger'}
              label={props.acceptLabel || 'Accept'}
              className="w-full"
              onClick={() => {
                props.onAccept?.();
                setProps(null);
              }}
            />
          </Box>
        </HStack>
      }
    >
      {props.body}
    </PDialogV2>
  ) : null;
};

export const confirmDialogV2 = (props: ConfirmDialogV2Props) => triggerConfirmDialogV2?.(props);
