import React, { useMemo, useState } from 'react';
import { TopBarBannerStyle as S } from './TopBarBanner.style';
import { ReactComponent as ErrorIcon } from 'assets/svg/error-filled.svg';
import { ReactComponent as CrossIcon } from 'assets/svg/close.svg';
import { useObservableState } from 'observable-hooks';
import { getCustomerStore } from 'stores/customer.store';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { addHours } from 'date-fns';
import { getUserStore } from 'stores/user.store';
import { LinkText } from 'components/LinkText/LinkText';

export const TopBarBanner: React.FC = () => {
  const [display, setDisplay] = useState(
    !sessionStorage.getItem('top-banner-hidden-until') ||
      new Date().getTime() > new Date(sessionStorage.getItem('top-banner-hidden-until') || '').getTime()
  );
  const billingStatus = useObservableState(getCustomerStore().$billingStatus);
  const subscriptionInfo = useObservableState(getBillingPlanStore().$subscriptionInfo);
  const userRole = useObservableState(getUserStore().$userRole);
  const message = useMemo(() => {
    switch (subscriptionInfo?.failedPaymentReason) {
      case 'expired_card':
        return (
          <>
            Your credit card has expired. To avoid being blocked out of your account
            {userRole === 'administrator' ? (
              <>
                , <LinkText href={`/profile/plan-and-billing/billing`}>update your billing</LinkText>.
              </>
            ) : (
              '.'
            )}
          </>
        );
      case 'insufficient_funds':
        return (
          <>
            Your billing could not be processed due to insufficient funds. To avoid being blocked out of your
            account
            {userRole === 'administrator' ? (
              <>
                , <LinkText href={`/profile/plan-and-billing/billing`}>update your billing</LinkText>.
              </>
            ) : (
              '.'
            )}
          </>
        );
      default:
        return null;
    }
  }, [subscriptionInfo?.failedPaymentReason, userRole]);
  return display && billingStatus === 'past_due' && message ? (
    <S.Container>
      <ErrorIcon className="mr-3" />
      <S.Message>{message}</S.Message>
      <CrossIcon
        onClick={() => {
          setDisplay(false);
          sessionStorage.setItem('top-banner-hidden-until', addHours(new Date(), 1)?.toISOString());
        }}
        className="ml-auto cursor-pointer"
      />
    </S.Container>
  ) : null;
};
