import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild } from 'types/BLoCBase';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { getUserStore } from 'stores/user.store';
import { useForm, UseFormReturn } from 'react-hook-form';
import { $fromHookForm } from 'utils/$fromHookForm';
import { $post, $put } from 'services/api';
import { CreditsModalTypes } from './CreditsModal.types';
import { HeyYabbleModuleTypes } from 'modules/HeyYabbleModule/HeyYabbleModule.types';
import { useSetFormDefaults } from 'hooks/useSetFormDefaults';
import { snackbar } from 'services/snackbar';
import { navigate } from 'services/navigate';

const getDefaultAmountByTierId = (tierId: string = '') =>
  ({
    '1': 1000,
    '2': 1000,
    '3': 1000,
  })[tierId || '3'] || 10000;

type State = {
  loading?: boolean;
};

class FormValues {
  public amount: number = 0;
  public agree: boolean = false;
}

class BLoC extends BLoCBase<State> {
  public readonly $loading = this.$getState('loading');

  constructor(
    public readonly closeModal: (complete?: boolean) => void,
    public readonly form: UseFormReturn<FormValues, object>,
    public readonly project?: CreditsModalTypes.ProjectProp
  ) {
    super({});
  }

  public readonly $formBalanceAndTotal = combineLatest([
    getBillingPlanStore().$creditsBalance,
    getBillingPlanStore().$pricePerCredit,
    $fromHookForm(this.form, 'amount') as Observable<number>,
  ]).pipe(
    map(([credits = 0, price = 0, amount = 0]) => ({
      balanceAfterTopup: !this.project ? credits + amount : credits - this.project?.amount + amount,
      totalToPay: amount * price,
    })),
    shareReplay()
  );

  public readonly $mode: Observable<CreditsModalTypes.Modes> = getUserStore().$userRole.pipe(
    map((role) => role === 'administrator'),
    switchMap((userIsAdmin) =>
      !this.project
        ? (of('credit-purchase') as Observable<CreditsModalTypes.Modes>)
        : getBillingPlanStore().$creditsBalance.pipe(
            map((creditsBalance) => {
              if ((creditsBalance || 0) >= this.project!.amount) {
                if (this.project?.creditRequestId && userIsAdmin) return 'credit-request-approval';
                return 'credit-spend';
              } else if (this.project?.creditRequestId) {
                return 'credit-request-approval-purchase';
              } else if (!userIsAdmin) {
                return 'credit-request';
              } else {
                return 'credit-buy-and-spend';
              }
            })
          )
    )
  );
  public readonly $formDefaultValues = combineLatest([
    getBillingPlanStore().$currentPlan,
    getBillingPlanStore().$creditsBalance,
  ]).pipe(
    map(([currentPlan, creditsBalance = 0]) => {
      return !this.project
        ? getDefaultAmountByTierId(currentPlan?.metadata.planTier)
        : this.project.amount > creditsBalance
          ? this.project.amount - creditsBalance
          : 0;
    }),
    map((amount) => ({
      amount,
      agree: false,
    })),
    shareReplay()
  );

  private $getProjectRunRequest = () => {
    switch (this.project?.type) {
      case 'ingestion':
        return $put(`ingestions/${this.project?.id}/confirm`, {});
      case 'task':
        return $post<HeyYabbleModuleTypes.Task>(`hy-tasks/${this.project?.id}/run`);
      case 'summarize':
        return of(null);
      case 'au-data-project':
        return of(null);
      default:
        return of(null);
    }
  };

  public handleSubmit = this.form.handleSubmit((values) => {
    this.setState('loading', true);
    this.addSub(
      this.$mode
        .pipe(
          switchMap((mode) => {
            switch (mode) {
              case 'credit-request':
                return this.handleCreditRequest();
              case 'credit-purchase':
                return this.handleCreditPurchase(values.amount);
              case 'credit-request-approval':
              case 'credit-request-approval-purchase':
                return this.handleCreditApproval(values.amount, mode === 'credit-request-approval-purchase');
              default:
                return this.handleRunProject(values.amount, mode === 'credit-buy-and-spend');
            }
          })
        )
        .subscribe(() => setTimeout(() => this.closeModal(true))),
      'handle-submit'
    );
  });

  private handleCreditApproval = (amount: number, purchaseCredits: boolean) =>
    (purchaseCredits
      ? $post('billing/subscription/credits', {
          amount,
        })
      : (of(null) as Observable<any>)
    ).pipe(
      switchMap(() => $put(`billing/credit-requests/${this.project?.creditRequestId}/approve`, {})),
      catchError(this.handleError('An error ocurred while trying to approve request')),
      tap(this.handleSuccess('Credit request approved'))
    );

  private handleCreditPurchase = (amount: number) =>
    $post('billing/subscription/credits', {
      amount,
    }).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.setState('loading', false);
          snackbar({
            label: (
              <>
                Daily credit limit exceeded. Please contact hello@yabble.com or via the intercom link on the
                left.
              </>
            ),
            severity: 'error',
          });
        } else this.handleError('An error ocurred while sending your request');
        return throwError(() => err);
      }),
      tap(this.handleSuccess('Top-up successful!'))
    );

  private handleRunProject = (amount: number, purchaseCredits: boolean) => {
    const successMessage = {
      task: 'Query started',
      ingestion: 'CSV import started',
      summarize: 'Payment was successful',
      'au-data-project': 'Payment was successful',
    };
    return (
      purchaseCredits
        ? $post('billing/subscription/credits', {
            amount,
          })
        : (of(null) as Observable<any>)
    ).pipe(
      switchMap(this.$getProjectRunRequest),
      catchError(this.handleError('An error ocurred while trying to start process')),
      tap(this.handleSuccess(successMessage[this.project!.type]))
    );
  };

  private handleCreditRequest = () =>
    $post(`billing/credit-requests`, {
      type: this.project!.type,
      id: this.project!.id,
    }).pipe(
      catchError(this.handleError('An error ocurred while sending your request')),
      tap(this.handleSuccess('Request Sent!'))
    );

  private handleError = (message: string) => (err: any) => {
    this.setState('loading', false);
    snackbar({
      label: err?.message || message || 'Error ocurred',
      severity: 'error',
    });
    return throwError(() => err);
  };

  private handleSuccess = (message: string, navigateTo?: string | null) => () => {
    this.setState('loading', false);
    snackbar({
      label: this.project?.creditRequestId ? 'Request approved!' : message || 'Successful action!',
      severity: 'success',
    });
    getBillingPlanStore().fetchSubscriptionInfo();
    setTimeout(() => navigateTo && navigate(navigateTo));
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useCreditsModalBLoC = () => useBLoC<BLoC>(Context);

export const CreditsModalBLoC: React.FC<
  BLoCParams<BLoC, State> & CreditsModalTypes.CreditsModalBLoCProps
> = ({ children, closeModal, project }) => {
  const form = useForm<FormValues>({
    defaultValues: new FormValues(),
    mode: 'all',
  });
  const bloc = useInitBloc(() => new BLoC(closeModal, form, project), [project]);
  useSetFormDefaults(bloc?.form, bloc?.$formDefaultValues);
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
