import styled from 'styled-components';

import { colours, fontSize, lineHeight } from 'styles/styles';

export const ListPanelFiltersEmptyStateStyle = {
  Container: styled.div`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Title: styled.p`
    max-width: 260px;
    margin: 0;
    padding: 24px 8px 48px;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 600;
    color: ${colours.neutralGrey05};
    text-align: center;
  `,
};
