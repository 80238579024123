import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepTableHeaderStyle as S } from './HeyYabbleCountStepTableHeader.style';

export const HeyYabbleCountStepTableHeader: React.FC = () => {
  const { handleChangeMainCheckbox, $isSelectedAll, $hasTcPipelineSelection, $filteredColumn } =
    useHeyYabbleCountStepBLoC();
  const isSelectedAll = useObservableState($isSelectedAll, false);
  const hasTcPipelineSelection = useObservableState($hasTcPipelineSelection);
  const filteredColumn = useObservableState($filteredColumn, []);

  return (
    <S.Container>
      <S.CheckContainer>
        <PCheckbox
          checked={isSelectedAll}
          onChange={handleChangeMainCheckbox}
          disabled={!filteredColumn.length}
        />
      </S.CheckContainer>
      <S.ItemContainer>COLUMN NAME</S.ItemContainer>
      <S.WidthItem>CREDITS</S.WidthItem>
      {hasTcPipelineSelection && <S.DropdownsItem>PIPELINE AND CODEFRAME</S.DropdownsItem>}
    </S.Container>
  );
};
