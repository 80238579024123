import styled from 'styled-components';

const loadingBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 56px);
  max-width: 100%;
  max-height: 100%;
`;

export const LoadingPageStyle = {
  Container: styled(loadingBackground)``,
  ContainerOverlay: styled(loadingBackground)`
    position: fixed;
    bottom: 0px;
    left: 0px;
    opacity: 0;
    z-index: -10;
    transition: 10s;
    &.visible {
      opacity: 1;
      z-index: 100000;
    }
  `,
};
