import { PDialog } from 'components/prime/PDialog/PDialog';
import styled from 'styled-components';

import { colours, cssScreens } from 'styles/styles';

export const SavedFiltersModalStyle = {
  Dialog: styled(PDialog)`
    &.p-dialog {
      width: 75vw !important;
      max-width: 990px;
      height: 70vh;
      .p-dialog-content {
        padding: 0;
      }
      @media screen and (max-width: 600px) {
        width: 100vw !important;
        height: 100vh;
        max-height: 100vh;
        .p-dialog-content,
        .p-dialog-header {
          border-radius: 0 !important;
        }
      }
    }
  `,
  Container: styled.div`
    height: 100%;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    & > [class*='-panel'] {
      height: 100%;
      overflow: hidden;
      &.list-panel {
        flex: 0 0 35%;
        width: 35%;
        border-right: 1px solid ${colours.neutralGrey03};
        @media screen and (max-width: ${cssScreens.tablet}) {
          flex: 0 0 50%;
          width: 50%;
        }
        @media screen and (max-width: 600px) {
          flex: 0 0 100%;
          width: 100%;
        }
      }
      &.details-panel {
        flex: 0 0 65%;
        width: 65%;
        @media screen and (max-width: ${cssScreens.tablet}) {
          flex: 0 0 50%;
          width: 50%;
        }
        @media screen and (max-width: 600px) {
          flex: 0 0 100%;
          width: 100%;
          position: absolute;
          z-index: 1;
          transform: translateX(0%);
          transition: 0.3s ease-in-out;
          background-color: ${colours.neutralWhite};
          &.collapsed {
            transform: translateX(100%);
          }
        }
      }
    }
  `,
};
