import { useEffect } from 'react';

export const useIntercomForce = () => {
  useEffect(() => {
    setTimeout(() => {
      if (!document.body.classList.contains('intercom-force-zindex')) {
        document.body.classList.add('intercom-force-zindex');
      }
    });
    return () => {
      if (document.body.classList.contains('intercom-force-zindex')) {
        document.body.classList.remove('intercom-force-zindex');
      }
      document.body.classList.remove('intercom-force-zindex');
    };
  }, []);
  return {};
};
