import styled from 'styled-components';
import { colours } from 'styles/styles';

export const DetailsPanelContentCopyToActivityStyle = {
  Container: styled.div``,
  Message: styled.div`
    border: 1px solid ${colours.neutralGrey03};
    background-color: ${colours.neutralGrey01};
    padding: 16px;
    margin-bottom: 12px;
    color: ${colours.neutralBody};
    line-height: 21px;
    font-size: 13px;
  `,
};
