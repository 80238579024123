import styled from 'styled-components';
import { colours } from 'styles/styles';

export const CreditsModalTopupFormTotalToPayStyle = {
  Container: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid ${colours.neutralGrey03};
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: ${colours.neutralLightCharcoal};
      margin: 0;
    }
  `,
  Message: styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${colours.neutralBody};
  `,
};
