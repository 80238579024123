import styled from 'styled-components';
import { gap } from 'styles/styles';

export const DetailsPanelProjectsStyle = {
  List: styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${gap.gap4};
    padding: 0;
    margin: 0;
    list-style-position: inside;
  `,
};
