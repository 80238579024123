import { useClassNames } from 'hooks/useClassNames';
import React from 'react';
import * as iconsLibrary from '../../Icon/icons.library';
import { PSelectButtonV2Style as S } from './PSelectButtonV2.style';
import { SelectButton, SelectButtonProps } from 'primereact/selectbutton';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { Icon } from 'components/Icon/Icon';

export type SelectButtonOptionType = {
  label?: string;
  value?: any;
  disabled?: boolean | undefined;
  iconLeft?: keyof typeof iconsLibrary;
};

const SelectButtonItem: React.FC<{ item: SelectButtonOptionType }> = ({ item }) => {
  return (
    <HStack stretch gap="gap2" align="center" justify="center" fit="space">
      {!!item.iconLeft && <Icon className="p-button-icon" icon={item.iconLeft} size={16} />}
      {!!item.label && (
        <Text
          className="p-button-label"
          fit="content"
          size="fontSizeXS"
          lineHeight="lineHeightSM"
          weight="medium"
        >
          {item.label}
        </Text>
      )}
    </HStack>
  );
};

export const PSelectButtonV2 = React.forwardRef<
  SelectButton,
  Omit<SelectButtonProps, 'size' | 'options'> & {
    options: SelectButtonOptionType[];
  }
>((props, ref) => {
  const classNames = useClassNames({}, props.className);

  return (
    <S.SelectButton
      ref={ref}
      {...props}
      unselectable={false}
      className={classNames}
      itemTemplate={(item) => <SelectButtonItem item={item} />}
    />
  );
});
