import { useEffect } from 'react';

export const useIntercomHidden = () => {
  useEffect(() => {
    if (!document.body.classList.contains('intercom-hidden')) {
      document.body.classList.add('intercom-hidden');
    }
    return () => {
      if (document.body.classList.contains('intercom-hidden')) {
        document.body.classList.remove('intercom-hidden');
      }
    };
  }, []);

  return {};
};
