import React, { AnchorHTMLAttributes, useMemo, useRef, useState } from 'react';
import { PButton, PButtonProps } from 'components/prime/PButton/PButton';
import { PMenu, PMenuProps } from 'components/prime/PMenu/PMenu';
import { useClassNames } from 'hooks/useClassNames';
import { MenuItem } from 'primereact/menuitem';
import { ButtonMenuStyle as S } from './ButtonMenu.style';
import { PButtonIcon, PButtonIconProps } from 'components/prime/PButton/PButtonIcon';

export const ButtonMenu: React.FC<
  AnchorHTMLAttributes<HTMLDivElement> & {
    menuItems?: MenuItem[];
    buttonProps?: PButtonProps;
    buttonIconProps?: PButtonIconProps;
    menuProps?: PMenuProps;
  }
> = (props) => {
  const cleanProps = useMemo(
    () => (({ menuItems, buttonProps, buttonIconProps, menuProps, ...o }: any) => o)(props),
    [props]
  );
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const className = useClassNames(
    {
      'menu-visible': menuVisible,
      'is-disabled': !!props.buttonProps?.disabled || !!props.buttonIconProps?.disabled,
    },
    props.className
  );
  return (
    <S.Container
      {...cleanProps}
      className={className}
      onClick={(event: any) => {
        (menuRef?.current as any)?.toggle(event);
        props.onClick && props.onClick(event);
      }}
    >
      {!!props.buttonProps ? (
        <PButton {...props.buttonProps} iconRight="chevronDownLine" />
      ) : !!props.buttonIconProps ? (
        <PButtonIcon {...props.buttonIconProps} />
      ) : null}
      {props.menuItems && !(props.buttonProps?.disabled || props.buttonIconProps?.disabled) && (
        <PMenu
          {...props.menuProps}
          onShow={(event) => {
            setMenuVisible(true);
            props.menuProps?.onShow?.(event);
          }}
          onHide={(event) => {
            setMenuVisible(false);
            props.menuProps?.onHide?.(event);
          }}
          ref={menuRef}
          popup
          model={props.menuItems}
        />
      )}
    </S.Container>
  );
};
