import React from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';
import { DetailsPanelContentConditionsListStyle as S } from './DetailsPanelContentConditionsList.style';
import { FiltersDetailsContent } from 'components/Filters/components/FiltersDetailsContent/FiltersDetailsContent';

export const DetailsPanelContentConditionsList: React.FC = () => {
  const { $availableCategories, $selectedFilter } = useSavedFiltersModalBLoC();
  const selectedFilter = useObservableState($selectedFilter);
  const availableCategories = useObservableState($availableCategories, []);

  return (
    <S.Container>
      <S.Title>Conditions</S.Title>
      <FiltersDetailsContent
        availableCategories={availableCategories}
        filters={selectedFilter?.criteria || []}
      />
    </S.Container>
  );
};
