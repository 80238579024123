import styled from 'styled-components';

export const ImportModalSyntheticLoadingStyle = {
  Container: styled.div`
    width: 100%;
    height: calc(100% - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
