import { useClassNames } from 'hooks/useClassNames';
import React, { ReactNode, useMemo } from 'react';
import { PFormFieldError as S } from './PFormFieldError.style';

const getDefaultMessages = (
  ruleName: string,
  label: string,
  rules: { [key in string]: { value: number | string } }
) => {
  switch (ruleName) {
    case 'required':
      return `${label} is required.`;
    case 'minLength':
      return `${label} must contain at least ${rules[ruleName]} characters.`;
    case 'maxLength':
      return `${label} must contain at most ${rules[ruleName]} characters.`;
    default:
      return `${label} is invalid`;
  }
};

export const PFormFieldError: React.FC<{
  message: string | ReactNode;
  type?: string;
  label: any;
  rules: any;
  hidden?: boolean;
}> = ({ message, type, label, rules, hidden }) => {
  const formattedMessage = useMemo(
    () =>
      message
        ? message
        : type && getDefaultMessages(type, typeof label == 'string' ? label : 'Field', rules as any),
    [message, label, rules, type]
  );
  const classes = useClassNames({ 'is-visible': !!formattedMessage && !hidden });
  return <S.Container className={classes}>{formattedMessage}</S.Container>;
};
