import styled from 'styled-components';

export const CreditsModalActionButtonsStyle = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    gap: 8px;
  `,
};
