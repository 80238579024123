import React, { useMemo } from 'react';
import { ImportModalBLoC as BLoC, useImportModalBLoC } from './ImportModal.bloc';
import { ImportModalTypes } from './ImportModal.types';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { useObservableEagerState } from 'observable-hooks';
import { ImportModalCsv } from './components/ImportModalCsv/ImportModalCsv';
import { ImportModalSelectFlow } from './components/ImportModalSelectFlow/ImportModalSelectFlow';
import { useIntercomForce } from 'hooks/useIntercomForce';
import { ImportModalSynthetic } from './components/ImportModalSynthetic/ImportModalSynthetic';

const importFlowComponents = {
  csv: ImportModalCsv,
  augmented: ImportModalSynthetic,
};

const ImportModalInner: React.FC = () => {
  const { $importFlowSelected } = useImportModalBLoC();
  const importFlowSelected = useObservableEagerState($importFlowSelected);
  const Component = useMemo(
    () => (importFlowSelected ? importFlowComponents[importFlowSelected] : ImportModalSelectFlow),
    [importFlowSelected]
  );

  return <Component />;
};

export const ImportModal: React.FC<ImportModalTypes.ImportModalProps> = ({
  onClose,
  project,
  ingestionId,
  surveyCode,
  preselectType,
}) => {
  useIntercomForce();
  return (
    <BLoC {...{ onClose, project, ingestionId, preselectType, surveyCode }}>
      <FullScreenModal>
        <ImportModalInner />
      </FullScreenModal>
    </BLoC>
  );
};
