import React, { useMemo } from 'react';
import { useIsReactPage } from 'hooks/useIsReactPage';
import { useObservableState } from 'observable-hooks';
import { from } from 'rxjs';
import { TopBarTitleStyle as S } from './TopBarTitle.style';
import { LeftMenuLogo } from 'modules/widgets/LeftMenuWidget/components/LeftMenuLogo/LeftMenuLogo';

export const TopBarTitle: React.FC = () => {
  const inReact = useIsReactPage();
  const isMyPanelPages = useMemo(() => !inReact && document.location.pathname.includes('/panels'), [inReact]);

  const title = useObservableState(
    from<Promise<string>>(
      new Promise<string>((resolve) => {
        new MutationObserver((mutations) => {
          resolve((mutations[0].target as any)?.innerText?.split('»')[0] || document.title?.split('»')[0]);
        }).observe(document.querySelector('title')!, {
          subtree: true,
          characterData: true,
          childList: true,
        });
      })
    ),
    document.title?.split('»')[0]
  );

  if (isMyPanelPages) {
    return (
      <div className="w-auto top-bar-title-logo-mypanel-pages">
        <LeftMenuLogo />
      </div>
    );
  }

  return <S.Title $inReact={inReact}>{title}</S.Title>;
};
