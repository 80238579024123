import React, { PropsWithChildren, ReactNode } from 'react';
import { ImportModalMainSectionStyle as S } from './ImportModalMainSection.style';

export const ImportModalMainSection: React.FC<
  PropsWithChildren<{ title: string; subtitle?: ReactNode; step?: string }>
> = ({ title, subtitle, children }) => {
  return (
    <S.Container>
      <S.Title data-testid="label-map-step-title">{title}</S.Title>
      <S.Subtitle data-testid="label-map-step-subtitle">{subtitle}</S.Subtitle>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};
