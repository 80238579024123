export namespace ChatModalTypes {
  export type ChatMessage = {
    id: number;
    animate?: boolean;
    replyToMessageId?: number | null;
    message: string;
    createdAt?: string;
    user?: {
      id: number;
      firstName: string;
      lastName: string;
    };
    responsesCount?: number;
    waitingOnReply?: boolean;
    feedbackPositive?: boolean | null;
    adPersona?: {
      id: number;
      name: string;
      avatarPath?: string;
    };
  };
  export type Weaviate = {
    status: 'created' | 'processing' | 'completed' | 'failed' | 'checked'; // checked is FE only status
  };
  export type Chat = {
    createdAt: string;
    creator: { firstName: string; lastName: string; id: number };
    id: number;
    project: { id: string; name: string };
    summarizationProject?: { id: string; name: string };
    question: { id: string; title: string };
    summary?: { id: string; title: string };
    syncStatus?: Weaviate;
    updatedAt: string;
  };
  export class FormFields {
    public projectId: string | number | null = null;
    public questionId: string | null = null;
    public summaryId: number | null = null;
  }
}
