import React from 'react';
import { FiltersDetailsContentStyle as S } from './FiltersDetailsContent.style';
import { FiltersTypes } from 'components/Filters/Filters.types';
import { FiltersDetailsContentSkeletons } from './components/FiltersDetailsContentSkeletons/FiltersDetailsContentSkeletons';
import { FiltersDetailsContentItem } from './components/FiltersDetailsContentItem/FiltersDetailsContentItem';

export const FiltersDetailsContent: React.FC<{
  availableCategories: FiltersTypes.AvailableFilterCategory[];
  filters: FiltersTypes.Filter[];
  loading?: boolean;
  emptyMessage?: string;
}> = ({ availableCategories, filters, loading, emptyMessage }) => {
  return (
    <S.Container>
      {loading || !availableCategories.length
        ? Array.from({ length: 4 }).map((_i, j) => <FiltersDetailsContentSkeletons key={j} />)
        : filters.length
        ? filters.map((filter, j) => (
            <FiltersDetailsContentItem
              key={filter.subjectTypeId + '_' + j}
              filter={filter}
              availableCategories={availableCategories}
            />
          ))
        : emptyMessage || ''}
    </S.Container>
  );
};
