import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import React, { useMemo } from 'react';
import { PInputSwitchStyle as S } from './PInputSwitch.style';

export const PInputSwitch = React.forwardRef<
  InputSwitch,
  Omit<InputSwitchProps, 'checked'> & {
    checked?: boolean | null;
    toggleAlignment?: 'right' | 'left';
    label?: string;
    description?: string;
    testId?: string;
  }
>((props, ref) => {
  const { toggleAlignment = 'left', label, description, checked, disabled, testId } = props;
  const cleanProps = useMemo(() => (({ toggleAlignment, label, description, ...o }) => o)(props), [props]);
  const hasTexts = useMemo(() => {
    return !!(label || description);
  }, [label, description]);

  return (
    <S.Container
      $toggleAlignment={toggleAlignment}
      $disabled={disabled}
      $hasTexts={hasTexts}
      data-testid={testId}
    >
      {hasTexts && (
        <div className="p-input-switch-main">
          {!!label && <p>{label}</p>}
          {!!description && <span className="p-input-switch-description">{description}</span>}
        </div>
      )}
      <div className="p-input-switch">
        <S.InputSwitch {...cleanProps} checked={checked || false} ref={ref} />
      </div>
    </S.Container>
  );
});
