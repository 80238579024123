import React, { useMemo, useRef } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { PDropdownStyle as S } from './PDropdown.style';
import { ReactComponent as ChevronDownLine } from 'assets/svg/chevron-down-line.svg';
import { ReactComponent as XSolid } from 'assets/svg/x-solid.svg';
import { ReactComponent as AlertCircleSolid } from 'assets/svg/alert-circle-solid.svg';

export const PDropdown = React.forwardRef<
  Dropdown,
  Omit<DropdownProps, 'size'> & {
    invalid?: boolean;
    type?: 'primary' | 'inline' | 'combo';
    headerItemsNoPadding?: boolean;
    size?: 'small' | 'large' | 'default';
  }
>((props, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const cleanProps = useMemo(() => (({ invalid, panelClassName, size, ...o }: any) => o)(props), [props]);
  const containerClassNames = useClassNames({
    'fit-content': props.type === 'inline',
  });
  const classNames = useClassNames(
    {
      'p-invalid': props.invalid,
      'p-dropdown-sm': props.size === 'small',
      'p-dropdown-lg': props.size === 'large',
      [`p-dropdown-type-${props.type || 'primary'}`]: true,
    },
    props.className
  );
  const panelClassName = useClassNames(
    { 'header-items-no-padding': props.headerItemsNoPadding },
    props.panelClassName
  );

  return (
    <S.Container className={containerClassNames}>
      <S.Dropdown
        editable={props.type === 'combo'}
        showClear={props.type === 'combo'}
        {...cleanProps}
        onHide={() => {
          (document?.activeElement as any)?.blur();
        }}
        inputRef={inputRef}
        panelClassName={panelClassName}
        className={classNames}
        dropdownIcon={<ChevronDownLine />}
        clearIcon={
          <XSolid
            className="p-dropdown-clear-icon"
            onClick={(e) => props.onChange?.({ ...e, value: null } as any)}
          />
        }
        ref={ref}
      />
      <AlertCircleSolid className="p-dropdown-invalid-icon" />
    </S.Container>
  );
});
