import styled from 'styled-components';
import { Toast } from 'primereact/toast';
import { Box } from 'components/layouts/primitives/Box/Box';
import { colourPalette, gap } from 'styles/styles';

export const SnackbarV2Style = {
  Snackbar: styled(Toast)`
    opacity: 1;
    bottom: ${gap.gap4};
    width: calc(100vw - ${gap.gap7});
    max-width: 540px;
    .p-toast-message {
      margin-bottom: ${gap.gap4};
      box-shadow: none;
      border-radius: unset;

      .p-toast-message-content {
        all: unset;
        & > div:nth-child(2) {
          display: none;
        }
      }

      /* Custom Toast Animation */
      &.snackbar-anim-enter {
        opacity: 0;
        transform: translateY(100%);
      }
      &.snackbar-anim-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition:
          opacity 300ms,
          transform 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.snackbar-anim-exit {
        opacity: 1;
        transform: translateY(0);
      }
      &.snackbar-anim-exit-active {
        opacity: 0;
        transform: translateY(100%);
        transition:
          opacity 300ms,
          transform 300ms;
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  `,
  SnackbarMessage: styled(Box)`
    .snackbar-message-left-item {
      flex: 0 0 24px;
      height: 24px;
      overflow: hidden;
      color: ${colourPalette.neutral0};
      & > * {
        max-height: 24px;
        max-width: 24px;
      }
    }
  `,
};
