import React from 'react';
import { useMapStepBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/components/ImportModalCsvContent/components/MapStep/MapStep.bloc';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { TC_IMPORT_TEXT_RESPONSE_TYPE_ID } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import { useObservableState } from 'observable-hooks';
import { MapStepColumnMapToItem } from '../MapStepColumnMapToItem/MapStepColumnMapToItem';
import { QuestionsDropdownStyle as S } from './QuestionsDropdown.style';

export const QuestionsDropdown: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({ column }) => {
  const { $questionsText, $questionsNps, handleChangeQuestionId, $selectedVars } = useMapStepBLoC();
  const questionsText = useObservableState($questionsText, []);
  const questionsNps = useObservableState($questionsNps, []);
  const selectedVars = useObservableState($selectedVars);

  return (
    <S.Container>
      <S.Text>MAP TO</S.Text>
      <PDropdown
        appendTo="self"
        value={column.questionId}
        options={[
          { id: 'new', name: 'Create new column', type: 'auto' },
          { id: 'divider', name: 'EXISTING COLUMNS', type: 'auto' },
          ...(column.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID ? questionsText : questionsNps),
        ]}
        optionValue="id"
        optionLabel="name"
        placeholder="Select a column"
        onChange={(e) =>
          handleChangeQuestionId(
            column.id,
            e.target.value,
            questionsText.find((q) => q.id === e.target.value)?.name ||
              questionsNps.find((q) => q.id === e.target.value)?.name ||
              ''
          )
        }
        disabled={column.ignoreColumn}
        itemTemplate={(item) => (
          <MapStepColumnMapToItem
            {...{ item }}
            selectedItems={selectedVars?.questions || []}
            currentValue={column.questionId}
          />
        )}
      />
    </S.Container>
  );
};
