import { useClassNames } from 'hooks/useClassNames';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { PDialogStyle as S } from './PDialog.style';
import { PButtonIcon } from '../PButton/PButtonIcon';

type DialogCustomProps = DialogProps & {
  noHeader?: boolean;
  type?: 'default' | 'centered';
  rightComponent?: React.ReactNode;
  size?: 'small' | 'default' | 'large' | 'full';
};

/**
 * @deprecated Use PDialogV2 instead
 */
export const PDialog = React.forwardRef<Dialog, PropsWithChildren<DialogCustomProps>>((props, ref) => {
  const className = useClassNames(
    {
      'no-header': props.noHeader,
      'no-footer': !props.footer,
    },
    props.className
  );
  const width = useMemo(() => {
    switch (props.size) {
      case 'full':
        return '100vw';
      case 'large':
        return '990px';
      case 'small':
        return '400px';
      default:
        return '600px';
    }
  }, [props.size]);
  const cleanProps = useMemo(() => (({ noHeader, ...o }: any) => o)(props), [props]);
  useEffect(() => {
    return () => {
      setTimeout(() => {
        const openedModals = document.getElementsByClassName('p-dialog-mask').length;
        if (openedModals) {
          document.body.classList.add('p-overflow-hidden');
        } else {
          document.body.classList.remove('p-overflow-hidden');
        }
      }, 10);
    };
  }, []);
  return (
    <S.Dialog
      dismissableMask
      blockScroll
      resizable={false}
      draggable={false}
      showHeader={!props.noHeader}
      baseZIndex={1000}
      breakpoints={{
        '960px': props.size === 'large' ? '98vw' : props.size === 'full' ? '100vw' : '85vw',
        '640px': props.size !== 'small' ? '100vw' : '85vw',
      }}
      {...cleanProps}
      style={Object.assign(
        {
          minHeight: 'fit-content',
          height: props.size === 'full' ? '100vh' : undefined,
          width,
        },
        props.style || {}
      )}
      contentStyle={{
        ...(props.size === 'full' && { padding: '0px' }),
      }}
      children={
        props.noHeader ? (
          <>
            <S.DialogHeader type={props.type}>
              {props.header && <S.DialogTitle>{props.header as any}</S.DialogTitle>}
              {props.type === 'centered' && (
                <div className="p-dialog-header-icons">
                  <PButtonIcon size="sm" severity="secondary" icon="xLine" onClick={() => props.onHide()} />
                </div>
              )}
              {!!props.rightComponent && props.rightComponent}
            </S.DialogHeader>
            {props.children}
          </>
        ) : (
          props.children
        )
      }
      className={className}
      ref={ref}
    />
  );
});
